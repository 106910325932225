import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { parseJSON } from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { styled } from '@material-ui/styles';
import { Input } from '@material-ui/core';
import isbot from 'isbot'
import DeviceDetector from "device-detector-js";

export default function HttpStatus() {


  const [slower, setslower] = useState(false);
  const tableRef = useRef();
  const json = useRef()
  const json2 = useRef()
  const wynik = useRef()
  const fileref = useRef();
  const linkref = useRef();
  const [datatableData,setdatatableData] = useState([]);
  const [datatableData2,setdatatableData2] = useState([]);
  const [datatableData3,setdatatableData3] = useState([]);
  const [datatableData4,setdatatableData4] = useState([]);
  const [indicator, setindicator] = useState(0);
  var text;
  var names = [];
  var names2 = [];
  var names3 = [];
  var domains = [];
  var countdomains = {};
  var publisher = [];
  var counttypes = {};
  var intermediary = [];
  var idcount = [];
  var countids = {};
  var both = [];
  var count = {};
  var duplicate = [{}];
  var duplicatedomains = [{}];
  var duplicateids = [{}];
  var jsonstrict;
  var fileJson = "";
var BidRequests = [{}];

function Check()
{
      var obj = "";
      var content = json.current.value;
      var content_split = content.split(/\n|,/g);
      var MainUrl = 'https://mobileappscrapper.herokuapp.com/statusx/'
      console.log(content_split);
      for(let elements in content_split)
      {
        fetch(MainUrl +  content_split[elements])
        .then(res => res.json())
        .then(data => obj = data )
        .then(() => setdatatableData3(datatableData3 => [...datatableData3,[content_split[elements], obj]]))
        .catch((error) => {
          setdatatableData3(datatableData3 => [...datatableData3,[content_split[elements], "No data"]])
        });
      }
}
  



return (
    <div >
      <br></br>
       <TextField variant="outlined" multiline inputRef={json} rows={10} placeholder="Insert domain without prefixes like http:// or https:// separated by line break" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"250px",resize:"vertical"}} className="inline-txtarea"></TextField>
     
<br></br>
    <br></br>
    <Button variant="contained" color="primary" onClick={Check}>Analyse</Button>
    <label htmlFor="upload-file">

</label><br></br>
    <p> </p>
    <div ref={wynik}>

    </div>

    <MUIDataTable
            
            title="Http Status Analysis"
            ref={tableRef}
            columns={["Domain", "Status"]}
            data={datatableData3}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'HttpStatus_' + new Date().toISOString().slice(0, 10) + '.csv',
                filterOptions: {
                  useDisplayedColumnsOnly:true
                }
              },
              sort: true,
              sortDirection: 'desc',
              textLabels: {
                body: {
                  noMatch: "Please analyse Bid Requests first",
                }},
            }}
            
          />
<div>
</div>
    </div>
    
);
}