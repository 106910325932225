
import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from "mui-datatables";
import mock from '../../pages/dashboard/mock';
import { Table } from '@material-ui/core';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import Widget from "../Widget/Widget";
import PageTitle from "../PageTitle/PageTitle";
import { Tooltip } from '@material-ui/core';
import HttpStatus from '../HttpStatus/HttpStatus';
// styles


import { makeStyles } from '@material-ui/styles';
// components


export default function KeywordChecker() {
  
  const id_tocheck = useRef();
  const id_additionalcontent = useRef();
  const result = useRef();

  const useStyles = makeStyles(theme => ({
    tableOverflow: {
      overflow: 'auto'
    }
  }))
  var classes = useStyles();
  var content;
  var content_split;
  var additionalcontent;
  var additionalcontent_split;
  var finalcontent;
var Keywords = [
  "bdsm",
  "erotic",
  "erotik",
  "fuck",
  "hentai",
  "lesbian",
  "naughty",
  "nude",
  "porn",
  "prostitution",
  "pussy",
  "rape",
  "snuff",
  "xtube",
  "locker",
  "launcher",
  "wallpaper",
  "flashlight",
  "bang",
  "bdsm",
  "erotic",
  "erotik",
  "fuck",
  "hentai",
  "lesbian",
  "naughty",
  "nude",
  "porn",
  "prostitution",
  "pussy",
  "rape",
  "xtube",
  "locker",
  "launcher",
  "wallpaper",
  "flashlight",
 "lockscreen"
];
 var Domains;

    function open()
    {
      result.current.innerHTML = "";
      content = id_tocheck.current.value;
      additionalcontent = id_additionalcontent.current.value;
      content_split = content.split(/\n|,/g);
      additionalcontent_split = additionalcontent.split(/\n|,/g);
      Keywords = Keywords.concat(additionalcontent_split);
      finalcontent = content_split;

 for(let elemento in finalcontent)
  {

      for(let element in Keywords)
      {
        
          if(finalcontent[elemento].toLowerCase().includes(Keywords[element].toLowerCase()))
          {
              if(Keywords[element] == "")
              {
                Keywords.splice(element, 1);
              }
              else
              {
                console.log(finalcontent[elemento]);
              result.current.innerHTML += "<b>" + finalcontent[elemento] + "</b><br>";
              break;
              }

          }
      }
  }

      
    }
    
    
    
    
  return (
    <div >
<br></br>
<Tooltip placement="top" title="Current keywords:
          bdsm
          erotic
          erotik
          fuck
          hentai
          lesbian
          naughty
          nude
          porn
          prostitution
          pussy
          rape
          snuff
          xtube
          locker
          launcher
          wallpaper
          flashlight
          calc
          bang
          bdsm
          erotic
          erotik
          fuck
          hentai
          lesbian
          naughty
          nude
          porn
          prostitution
          pussy
          rape
          xtube
          locker
          launcher
          wallpaper
          flashlight
          lockscreen" aria-label="keywords">
<TextField variant="outlined" inputRef={id_tocheck} rows={10} fullwidth multiline id="tocheck" placeholder="Insert content separated by line break" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
</Tooltip>
<p> </p>
<p> </p>
<br></br>
<TextField variant="outlined" inputRef={id_additionalcontent} placeholder="Insert additional keywords separated by line break" rows={3} fullwidth multiline style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
<ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  ></ToastContainer>
<p> </p>  
<Button variant="contained" color="primary" onClick={open}>Check</Button>
<div ref={result}></div>
<HttpStatus></HttpStatus>
    </div>
  );
 
  }
