import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
import punycode from 'punycode';
import MUIDataTable from "mui-datatables";


export default function ParameterReporter() {

  const textarea = useRef();
  const tableRef = useRef();
  const [datatableData,setdatatableData] = useState([]);
  var parameters = [{}];
  var data;
  const decode = () =>
  {
  console.log("Works!")
  let str = textarea.current.value;
  extractParameters(str);
  for(let elem in parameters)
  {
    setdatatableData(datatableData => [...datatableData,[parameters[elem].par,parameters[elem].value,parameters[elem].description]])
  }
  }
  function extractParameters(string)
  {
  
  let link = string;
  let firstsplit = string.split("&");
  for (let elements in firstsplit)
      {
      parameters[elements] = {par: firstsplit[elements].split("=")[0], value: decodeURIComponent(decodeURIComponent(firstsplit[elements].split("=")[1])), description: ''}
      if(parameters[elements].par === "tmstp" || parameters[elements].par === "timestamp")
      {
          parameters[elements].description = "Timestamp. If not present, it is computed using the same formula as used by the javascript. The most common formula used to compute this is a random bw 0 and 1 times 10^10.";
      }
      if(parameters[elements].par === "insid")
      {
          parameters[elements].description = "This is the insertion identifier to deliver.";
      }
      if(parameters[elements].par === "siteid")
      {
          parameters[elements].description = "Site id";
      }
      if(parameters[elements].par === "ckid")
      {
          parameters[elements].description = "Cookie id";
      }
      if(parameters[elements].par === "pubid")
      {
          parameters[elements].description = "Pub Domain ID";
      }
      if(parameters[elements].par === "sysgt")
      {
          parameters[elements].description = "System target";
      }
      if(parameters[elements].par === "tgt")
      {
          parameters[elements].description = "Targeting. Targeting string which is passed by the calling tag. It is the same as for legacy calls. This parameter is not mandatory.";
      }
      if(parameters[elements].par === "visit")
      {
          parameters[elements].description = "Visit";
      }
      if(parameters[elements].par === "statid")
      {
          parameters[elements].description = "Stat Domain ID";
      }
      if(parameters[elements].par === "pgDomain")
      {
          parameters[elements].description = "The page domain where the tag is integrated. This value is URL Encoded by the generic function.";
      }
      if(parameters[elements].par === "pgid")
      {
          parameters[elements].description = "Page Id";
      }
      if(parameters[elements].par === "siteid")
      {
          parameters[elements].description = "Site Id";
      }
      if(parameters[elements].par === "fmtid")
      {
          parameters[elements].description = "Format Id. Must contain one or multiple int values, separated by commas. If multiple ints are passed, we are in the onecall case. If multiple ints are passed, they must all be valid. if one of them does not parse well, the call returns error.";
      }
      if(parameters[elements].par === "cftgid")
      {
          parameters[elements].description = "Confiant tag id value";
      }
      if(parameters[elements].par === "gdpr_consent")
      {
          console.log("works")
          data = parameters[elements].value;
          parameters[elements].description = "GDPR consent string<br><button onclick='getconsent(data," + elements + ")'>Click to decode</button><div id='gdpr'><pre><code></div>";
      }
      }
  try
  {
  let first = parameters[0].par.split("?")[1];
  parameters[0].par = first;
  
  }
  catch
  {
  }
  console.log(parameters);
  }
  
return (
    <div >
    <TextField
    inputRef={textarea}
    variant="outlined"
    rows={10}
    style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} 
    multiline
    fullwidth
    >


    </TextField>
    <br></br>
    <br></br>
    <Button
    onClick={decode}
    variant="contained"
    color="primary"
    >
      Decode
    </Button>
    <br></br>
    <MUIDataTable
            
            title="Results"
            ref={tableRef}
            columns={["Parameter", "Value", "Description"]}
            data={datatableData}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'AdCall_' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please decode ad call first",
                }},
            }}
            
          />
    </div>
);
}