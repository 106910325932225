import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useRef, useState, useEffect } from 'react';
import { Checkbox } from '@material-ui/core';
import { LinearProgress } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function NestedGrid() {
  const classes = useStyles();
  const prefix=useRef()
  const content=useRef()
  const suffix=useRef()
  const tooneline=useRef()
  const wynik=useRef()
  const deletelastcomma=useRef()
  var Prefix;
  var Suffix;
  var Content;
  const [lookups,setlookups] = useState()
  var Domains;
	var ToOneLine = 0;
  const [counter, setcounter] = useState(0);
  var sr;
{/*
  useEffect(() => {
    fetch('https://mobileappscrapper.herokuapp.com/getdb/TextMerger')
    .then(response => response.json())
    .then(data => setdata(data));

  })
  useEffect(() => {
    const interval = setInterval(() => {
      fetch('https://mobileappscrapper.herokuapp.com/getdb/TextMerger')
      .then(response => response.json())
      .then(data => setdata(data));
      console.log(lookups)
      
    
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  function setdata(data)
  {
    setlookups(data);
  }
*/}

function start(counter){
  //currentElement = 0;
  if(counter < sr.length){
    Show(counter);
    setcounter(counter++)
    setTimeout(function(){
      //FillTable(counter)
      start(counter);
    }, 1);
  }
  if(counter >= sr.length)
  {
    setcounter(0);
    prefix.current.value = Prefix;
    content.current.value = Content;
    suffix.current.value = Suffix;
  }
}

  function Merge()
  {
    setcounter(0);
    Prefix=prefix.current.value;
    Suffix=suffix.current.value;
    Content=content.current.value;
    sr = Content.split(/\n|,/g);
    if(tooneline.current.checked == true)
    {
      ToOneLine = 1;
    }
    else
    {
      ToOneLine = 0;
    }
   
    start(counter);
  }
  const Merge2 = async () =>
  {
    setcounter(1);
    if(tooneline.current.checked == true)
    {
      ToOneLine = 1;
    }
    else
    {
      ToOneLine = 0;
    }
    Prefix=prefix.current.value;
    Suffix=suffix.current.value;
    Content=content.current.value;
    var ContentArr = Content.split("\n")


    const response = await fetch('https://mobileappscrapper.herokuapp.com/textmerger/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"prefix": Prefix, "text": ContentArr, "suffix": Suffix})
      })
    var merged = await response.json();
    if(deletelastcomma.current.checked == true)
    {
      try
      {
        merged.Result[merged.Result.length-1] = merged.Result[merged.Result.length-1].replace(',', '');
      }
      catch
      {

      }
      

    }
    if(ToOneLine === 1)
    {
      wynik.current.innerText = merged.Result.map(x => x).join(" ")
      setcounter(0);
      prefix.current.value = Prefix;
      content.current.value = Content;
      suffix.current.value = Suffix;
    }
    if(ToOneLine === 0)
    {
      /*
      for(let element in merged.Result)
      {
        wynik.current.innerText += merged.Result[element] + "\n";
      }
      */
     wynik.current.innerText = merged.Result.map(x => x + "\n").join(" ")
      setcounter(0);
      prefix.current.value = Prefix;
      content.current.value = Content;
      suffix.current.value = Suffix;
    }
   
  }
  function Show(counter)
  {

      if(ToOneLine === 1)
      {
        wynik.current.innerText += Prefix + sr[counter] + Suffix;
      }
      if(ToOneLine === 0)
      {
        wynik.current.innerText += Prefix + sr[counter] + Suffix + "\n";
      }
    
        
      
  }
  function Clear()
  {
    wynik.current.innerText = "";
  }
  function Copy()
  {
    copyToClipboard(wynik.current.innerText);
  }
  function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}
const extractDomain = () =>
{
  let contents = content.current.value.split("\n");
  content.current.value = "";
  for(let element in contents)
  {
    contents[element] = contents[element].split("_")[1];
    content.current.value += contents[element] + "\n";
  }
  
}
  
  function FormRow() {
    return (
      <React.Fragment>
        
        <Grid item xs={3}>
        <br></br>
          Prefix
          <TextField variant="outlined" inputRef={prefix} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        <Grid item xs={6}>
        <br></br>
          Content
          <TextField variant="outlined" inputRef={content} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        <Grid item xs={3}>
        <br></br>
          Suffix
          <TextField variant="outlined" inputRef={suffix} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
      </React.Fragment>

    );
  }

  return (
    <div className={classes.root}>
      <div style={{textAlign:"center"}}>
      <Button style={{marginRight:"20px"}} onClick={()=>{prefix.current.value = "'"; suffix.current.value = "',"}} variant="contained" color="secondary">Add Snowflake '' characters</Button>
      <Button style={{marginRight:"20px"}} onClick={()=>{prefix.current.value = "('"; suffix.current.value = "'),"}} variant="contained" color="secondary">Add Snowflake ('') characters</Button>
      <Button style={{marginRight:"20px"}}  onClick={()=>{prefix.current.value = "'%"; suffix.current.value = "',"}} variant="contained" color="secondary">Add Snowflake '%' characters</Button>
      <Button style={{marginRight:"20px"}}  onClick={()=>{content.current.value = content.current.value.toLowerCase(); wynik.current.innerText = wynik.current.innerText.toLowerCase()}} variant="contained" color="secondary">Change to lowercase</Button>
      <Button style={{marginRight:"20px"}}  onClick={()=>{extractDomain()}} variant="contained" color="secondary">Extract domain from site</Button>
      </div>
     
      <Grid container spacing={1}>
      
        <Grid container item xs={12} spacing={3}>
        
          <FormRow />
        </Grid>

      </Grid>
      <p><br></br> </p>
      <p> </p>
      <p> </p>
      
                  <Checkbox inputRef={tooneline}  color="primary" type="checkbox" id="showresults"></Checkbox>
                  <label htmlFor="showresults">Merge to one line</label><br></br>
                  <Checkbox inputRef={deletelastcomma}  color="primary" type="checkbox" id="deletelastcomma"></Checkbox>
                  <label htmlFor="deletelastcomma">Delete last comma</label><br></br>
      <Button variant="contained" color="primary" onClick={Merge2} >Merge</Button><span>  </span>
      <Button color="primary" variant="outlined" onClick={Clear}>Clear result</Button><span></span>
      <Button color="primary" variant="outlined" onClick={Copy}>Copy</Button>

      {(() => {
               
        if (counter > 0) {
          
          return (
            <div>
              <label>Merging...</label>
            <LinearProgress label="Merging" style={{width:"19.5%", height:"10px"}}></LinearProgress>
           </div>
            )
          }
          else
          {
            return
            (
             <></>
            )
          } 
        })()}

  
      <Grid>
        <div>
        <br></br>
        <br></br>
        <div ref={wynik}>

        </div>
        </div>
      </Grid>
    </div>

  );
}