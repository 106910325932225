import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
export default function CriteoBlocklistExport() {

  const json_textarea = useRef();
  const tableRef = useRef();
  const partnerid = useRef();
  const [datatableData, setdatatableData] = useState([])
  var json;
  var json_parsed;
  var parsed_object79 = [{}];
  var parsed_object314 = [{}];
  var parsed_object329 = [{}];
  var parsed_object330 = [{}];
  var parsed_object351 = [{}];
  var parsed_object360 = [{}];
  var parsed_object361 = [{}];
  var parsed_object362 = [{}];
  var parsed_object363 = [{}];
  var parsed_object364 = [{}];
  var parsed_object365 = [{}];
  var parsed_object366 = [{}];
  var parsed_object367 = [{}];
  var parsed_objects = [{}];
  var bundles;
  var partner;
  function exportJSONToCSV(objArray) {
    var arr = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    var str =
      `${Object.keys(arr[0])
        .map((value) => `"${value}"`)
        .join(',')}` + '\r\n';
    var csvContent = arr.reduce((st, next) => {
      st +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(',')}` + '\r\n';
      return st;
    }, str);
    var element = document.createElement('a');
    element.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    element.target = '_blank';
    element.download = 'Criteo_' + new Date().toISOString().slice(0, 10) + '.csv';
    element.click();
  }
function Download()
{
  parsed_objects=[{}];
  json = json_textarea.current.value;
  console.log(json);
  json_parsed = JSON.parse(json);
  try
  {
    if(json_parsed.bundles === undefined)
        {
          bundles = 0;
          console.log(bundles)
        }
        else
        {
          bundles = 1;
        }
        
      }
      catch
      {
        bundles = 0;
      }
  
    if(bundles == 0)
    {
      
      for(let elementss in json_parsed.urls)
      {
        if(json_parsed.urls[elementss].includes(",") || json_parsed.urls[elementss].includes("#"))
          {
            console.log("includes!");
          }
          else
          {
            parsed_object79[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 79, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object314[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 314, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object329[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 329, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object330[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 330, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object351[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 351, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object360[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 360, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object361[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 361, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object362[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 362, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object363[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 363, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object364[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 364, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"}; 
            parsed_object365[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 365, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};
            parsed_object366[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 366, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"};     
            parsed_object367[elementss] = { Domain: json_parsed.urls[elementss], NetworkId: 0, PartnerId: 367, Date: new Date().toISOString().slice(0, 10), Description: "Criteo domain API blocklist", Type: "Partner Request"}; 
          }
        
      } 
      parsed_objects = parsed_objects.concat(parsed_object79, parsed_object314, parsed_object329, parsed_object330, parsed_object351, parsed_object360, parsed_object361, parsed_object362, parsed_object363, parsed_object364, parsed_object365, parsed_object366, parsed_object367);
      exportJSONToCSV(parsed_objects);
    }
    else
    {
      for(let elementss in json_parsed.bundles)
      {
        if(json_parsed.bundles[elementss].includes(",") || json_parsed.bundles[elementss].includes("#"))
          {
           continue;
          }
          else
          {
            parsed_object79[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 79, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object314[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 314, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object329[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 329, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object330[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 330, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object351[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 351, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object360[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 360, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object361[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 361, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object362[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 362, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object363[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 363, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object364[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 364, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"}; 
            parsed_object365[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 365, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};
            parsed_object366[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 366, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"};     
            parsed_object367[elementss] = { Domain: json_parsed.bundles[elementss], NetworkId: 0, PartnerId: 367, Date: new Date().toISOString().slice(0, 10), Description: "Criteo bundle API blocklist", Type: "Partner Request"}; 
          }
        
      } 
      parsed_objects = parsed_objects.concat(parsed_object79, parsed_object314, parsed_object329, parsed_object330, parsed_object351, parsed_object360, parsed_object361, parsed_object362, parsed_object363, parsed_object364, parsed_object365, parsed_object366, parsed_object367);
      exportJSONToCSV(parsed_objects);
    }
  
}
    function Action()
    {
      json = json_textarea.current.value;
      partner = partnerid.current.value;
      console.log(json);
      json_parsed = JSON.parse(json);
      console.log(json_parsed);
      try
      {
        if(json_parsed.bundles === undefined)
        {
          bundles = 0;
          console.log(bundles)
        }
        else
        {
          bundles = 1;
        }
        
      }
      catch
      {
        bundles = 0;
      }
      if(bundles === 1)
      {
        for(let elem in json_parsed.bundles)
        {
          if(json_parsed.bundles[elem].includes(","))
          {
  
          }
          else
          {
            setdatatableData(datatableData => [...datatableData,[json_parsed.bundles[elem],"0",partner,new Date().toISOString().slice(0, 10),"Criteo bundle API blocklist", "Partner Request"]])
          }
          
        }
      }
      else
      {
        for(let elem in json_parsed.urls)
        {
          if(json_parsed.urls[elem].includes(","))
          {
  
          }
          else
          {
            setdatatableData(datatableData => [...datatableData,[json_parsed.urls[elem],"0",partner,new Date().toISOString().slice(0, 10),"Criteo domain API blocklist", "Partner Request"]])
      }
          
        }
      }
     
    }
    
  
return (
    <div >
      <br></br>
       <TextField variant="outlined" inputRef={json_textarea} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
       <br></br><br></br><br></br>
    <p> </p> 
    <p> </p>
    <br></br><br></br>
    <Button variant="contained" color="primary" onClick={Download}>Download</Button>
    
    <p> </p>

    </div>
);
}