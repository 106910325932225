
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button
} from "@material-ui/core";

export default function SellersJsonValidator() {

  const tableRef = useRef();
  const json = useRef()
  const jsonik = useRef();
  const wynik = useRef()
  const [datatableData,setdatatableData] = useState([]);
  const [datatableData2,setdatatableData2] = useState([]);
  const [datatableData3,setdatatableData3] = useState([]);
  const [datatableData4,setdatatableData4] = useState([]);
  var JsonLines = [];
  var Errors = [];
  var text;
  var names = [];
  var names2 = [];
  var names3 = [];
  var domains = [];
  var countdomains = {};
  var publisher = [];
  var counttypes = {};
  var intermediary = [];
  var idcount = [];
  var countids = {};
  var both = [];
  var count = {};
  var duplicate = [{}];
  var duplicatedomains = [{}];
  var duplicateids = [{}];
  var jsonstrict;
  var sellerids = [];

  var duplicates = 0;

  const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort(); // You can define the comparing function here. 
    // JS by default uses a crappy string compare.
    // (we use slice to clone the array so the
    // original array won't be modified)
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }
  

    function validatejson()
    {
      Errors = [];
      setdatatableData3([[]])
      var RawJson = jsonik.current.value;
      var RemovedSellers = RawJson.split('[').pop();
      var SemiLines = RemovedSellers.replaceAll("\n      ", "")
      SemiLines = SemiLines.replaceAll("    ","")
      var SemiSemiLines = SemiLines.replaceAll("\n", "")
      var Lines = SemiSemiLines.split("},");
      
      try
      {
        JSON.parse(RawJson)
      }
      catch(e)
      {
       setdatatableData3(datatableData3 => [...datatableData3,[<p style={{color:"red"}}>Sellers.json could not be parsed. Consider fixing errors and try again!</p>]]);
      }
      //var Lines2 = Lines.split(/{|}/);
      
      
      for(let element in Lines)
      {
        
        try{
          if(Lines[element].endsWith("]"))
          {
            Lines[element] = Lines[element].slice(0,-1);
          }
          if(!Lines[element].endsWith("}"))
          {
            Lines[element] += "}"
          }
          if(Lines[element].endsWith('"1.0"}'))
          {
            Lines[element] = Lines[element].split(']')[0]
          }
          if(Lines[element].endsWith('}  ]}'))
          {
            Lines[element] = Lines[element].split(']')[0]
          }
          if(Lines[element].endsWith('} ]}'))
          {
            Lines[element] = Lines[element].split(']')[0]
          }
          if(Lines[element].endsWith('}]}'))
          {
            Lines[element] = Lines[element].split(']')[0]
          }
          
          


          Lines[element] = Lines[element].replace(/\[/g, "");
          Lines[element] = Lines[element].replace(/\]/g, "");
          Lines[element] = JSON.parse(Lines[element])
          JsonLines[element] = Lines[element]
        }
        catch(e)
        {
          
          Errors[element] = Lines[element] + " " + e.message;
        }
        
        for(let elements in JsonLines)
        {

          try
          {
            if(JsonLines[elements].name == "" || JsonLines[elements].name == undefined)
            {
              if(JsonLines[elements].is_confidential == 0)
              {
                Errors[elements] = "Seller_Id: " + JsonLines[elements].seller_id + " Seller_name is missing!"
              }
              else
              {

              }
              
            }
          }
          catch
          {
            try
            {
              if(JsonLines[elements].is_confidential == 0)
              {
                Errors[elements] = "Seller_Id: " + JsonLines[elements].seller_id + " Seller_name is missing!"
              }
              
            }
            catch
            {
              if(JsonLines[elements].is_confidential == 0)
              {
                Errors[elements] = "Domain: " + JsonLines[elements].domain + " Seller_name is missing!"
              }
              
            }
            
          }
          try
          {
            if(JsonLines[elements].seller_type.toLowerCase() == "intermediary" || JsonLines[elements].seller_type.toLowerCase() == "both" || JsonLines[elements].seller_type.toLowerCase() == "publisher")
            {
              
            }
            else
            {
              try
              {
                Errors[elements] = "Seller_Id: " + JsonLines[elements].seller_id + " Seller type is invalid or empty!"
              }
              catch
              {
                Errors[elements] = "Name: " + JsonLines[elements].seller_name + " Seller type is invalid or empty!"
              }
              
            }
          }
          catch
          {
            Errors[elements] = "Seller_Id: " + JsonLines[elements].seller_id + " Seller type is invalid or empty!"
          }
          
          if(JsonLines[elements].is_confidential == 0)
          {
            try
            {
              if(JsonLines[elements].domain == undefined || JsonLines[elements].domain == "")
              {
                Errors[elements] = "Seller_ID: " + JsonLines[elements].seller_id + " Sellers.json is not confidential but domain is missing!"
              }
              
            }
            catch
            {
              Errors[elements] = "Sellers.json is not confidential but domain is missing!"
            }
            
          }
          try
          {
            if(JsonLines[elements].seller_id == "" || JsonLines[elements].seller_id == undefined )
            {
             

                Errors[elements] = "Name: " + JsonLines[elements].name + " Seller_id is missing!"
         
              
            }
            else
            {
              if(typeof JsonLines[elements].seller_id !== 'string' )
              {
                Errors[elements] = "Name: " + JsonLines[elements].name + " Seller_id is not a string!"
              }
              sellerids[elements] = JsonLines[elements].seller_id
            }
          }
          catch
          {
            try
            {
              Errors[elements] = "Name: " + JsonLines[elements].name + " Seller_id is missing!"
            }
            catch
            {
              Errors[elements] = "Seller_Id: " + JsonLines[elements].domain + " Seller_id is missing!"
            }
            
          }
          
        }
        

        
      }
      if(findDuplicates(sellerids) == '')
      {

      }
      else
      {
        Errors.push("There are duplicates of seller id's: = " + findDuplicates(sellerids))
      }
      

      for(let elements in Errors.filter(n => n))
      {
        
        setdatatableData3(datatableData3 => [...datatableData3,[Errors.filter(n => n)[elements]]]);
      }

      if(Errors.filter(n => n).length == 0)
      {
        setdatatableData3(datatableData3 => [[<p style={{color:"green"}}>No errors found, all good!</p>]]);
      }
    }
    
  
return (
    <div >
      <PageTitle title="Sellers.json validator"></PageTitle>
      <Widget>
      <TextField variant="outlined" multiline inputRef={jsonik} rows={10} placeholder="Insert SELLERS.JSON file text" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
          <Button variant="contained" color="primary" onClick={validatejson}>Validate</Button>
          <MUIDataTable
            title="Errors"
            ref={tableRef}
            columns={["Error"]}
            data={datatableData3}
            options={{
              filterType: "checkbox",
              pagination: false,
              downloadOptions: {
                filename: 'SellersJson_' + new Date().toISOString().slice(0, 10) + '.csv',
                filterOptions: {
                  useDisplayedColumnsOnly:true
                }
              },
              sort: true,
              sortDirection: 'desc',
              textLabels: {
                body: {
                  noMatch: "Please validate sellers.json first",
                }},
            }}
            
          />
<p>Want more? Go to <a href="#/app/sellersjsonexporter">Sellers.json exporter</a></p>
          
     
      </Widget>
          
    </div>
);
}