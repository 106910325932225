import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useRef } from 'react';
import { Checkbox } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useState } from 'react';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));



export default function NestedGrid() {
  const classes = useStyles();
  const prefix=useRef()
  const content=useRef()
  const suffix=useRef()
  const tooneline=useRef()
  const wynik=useRef()
  const status=useRef();
  var Domains;
	var ToOneLine = 0;
  var sr;
  const dateVal=useRef();
  const [inputList, setInputList] = useState([]);
  var key;
  function Merge()
  {
    var Prefix=prefix.current.value;
    var Suffix=suffix.current.value;
    var Content=content.current.value;
    sr = Content.split(/\n|,/g);
    if(tooneline.current.checked == true)
    {
      ToOneLine = 1;
    }
    else
    {
      ToOneLine = 0;
    }
    for (let i=0; i < sr.length; i++)
{
	if(ToOneLine === 1)
	{
		wynik.current.innerText += Prefix + sr[i] + Suffix;
	}
	if(ToOneLine === 0)
	{
    wynik.current.innerText += Prefix + sr[i] + Suffix + "\n";
	}

	  
}    

  }
  
  function CreateInput()
  {

    setInputList(inputList.concat(DomainInput(key=Math.random())))
  //wynik.current.innerHTML += status.current.value + ";" + content.current.value + ";" + suffix.current.value + ";" + dateVal.current.value + ";" + "1" + ";" + prefix.current.value + "<br></br>";
  }
  function Clear()
  {
    wynik.current.innerText = "";
  }
  function DomainInput() {
    return (
      
          <Grid key={key} container spacing={1}>
        <Grid container item xs={12} spacing={1}>
        <Grid item xs={1.5}>
        <br></br>
          Status<br></br>
          <Select inputRef={status} variant="outlined" defaultValue="Not approved">
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Not approved"}>Not approved</MenuItem>
          </Select>
          
        </Grid>
        <Grid item xs={2}>
        <br></br>
          Domain
          <TextField variant="outlined" inputRef={prefix} rows={1} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        <Grid item xs={1.5}>
        <br></br>
          Reason<br></br>
          <Select inputRef={content} variant="outlined" defaultValue={1}>
          <MenuItem value={1}>1 Ads Density</MenuItem>
          <MenuItem value={2}>2 Adult</MenuItem>
          <MenuItem value={4}>3 Clickbait</MenuItem>
          <MenuItem value={5}>4 LQC - Low quality content</MenuItem>
          <MenuItem value={6}>5 Malicious</MenuItem>    
          <MenuItem value={7}>6 No data</MenuItem>
          <MenuItem value={8}>7 Not Available</MenuItem>
          <MenuItem value={9}>8 Piracy</MenuItem> 
          <MenuItem value={10}>9 Redirect</MenuItem>
          <MenuItem value={11}>10 Traffic Spikes</MenuItem>
          <MenuItem value={12}>11 UnTrDis - Unnatural Traffic Distribution</MenuItem> 
          <MenuItem value={13}>12 Other</MenuItem>    
          <MenuItem value={13}>13 Smart 0,0</MenuItem>              
          </Select>
        </Grid>
        <Grid item xs={1.5}>
        <br></br>
          Date
          <form className={classes.container} noValidate>
  <TextField inputRef={dateVal}
    id="date"
    type="date"
    variant="outlined"
    defaultValue="09.06.2021"
    className={classes.textField}
  />
</form>
        </Grid>
        <Grid item xs={1.5}>
        <br></br>
          Requesting Network
          <br></br>
          <TextField variant="outlined" inputRef={suffix} rows={1} id="tocheck" style={{borderRadius:"10px",textAlign:"center",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        </Grid>
     </Grid>
     

    );
  }
  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={1.5} spacing={3}>
        <br></br>
          Status<br></br>
          <Select inputRef={status} variant="outlined" defaultValue="Not approved">
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Not approved"}>Not approved</MenuItem>
          </Select>
          
        </Grid>
        <Grid item xs={2}>
        <br></br>
          Domain
          <TextField variant="outlined" inputRef={prefix} rows={1} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        <Grid item xs={1.5}>
        <br></br>
          Reason<br></br>
          <Select inputRef={content} variant="outlined" defaultValue={1}>
          <MenuItem value={1}>1 Ads Density</MenuItem>
          <MenuItem value={2}>2 Adult</MenuItem>
          <MenuItem value={4}>3 Clickbait</MenuItem>
          <MenuItem value={5}>4 LQC - Low quality content</MenuItem>
          <MenuItem value={6}>5 Malicious</MenuItem>    
          <MenuItem value={7}>6 No data</MenuItem>
          <MenuItem value={8}>7 Not Available</MenuItem>
          <MenuItem value={9}>8 Piracy</MenuItem> 
          <MenuItem value={10}>9 Redirect</MenuItem>
          <MenuItem value={11}>10 Traffic Spikes</MenuItem>
          <MenuItem value={12}>11 UnTrDis - Unnatural Traffic Distribution</MenuItem> 
          <MenuItem value={13}>12 Other</MenuItem>    
          <MenuItem value={13}>13 Smart 0,0</MenuItem>              
          </Select>
        </Grid>
        <Grid item xs={1.5}>
        <br></br>
          Date
          <form className={classes.container} noValidate>
  <TextField inputRef={dateVal}
    id="date"
    type="date"
    variant="outlined"
    defaultValue="09.06.2021"
    className={classes.textField}
  />
</form>
        </Grid>
        <Grid item xs={1.5}>
        <br></br>
          Requesting Network
          <br></br>
          <TextField variant="outlined" inputRef={suffix} rows={1} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
      </React.Fragment>

    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={9} spacing={3}>

        
        </Grid>

      </Grid>
      <br></br>
          <br></br>
          <Button variant="contained" color="primary" onClick={CreateInput} >Add</Button>
      {inputList}
      <Grid>
      <Button variant="contained" color="primary" onClick={CreateInput} >Add</Button>
        <div ref={wynik}>
        </div>
      </Grid>
      
    </div>

  );
}