import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { FormGroup, Switch,Radio,RadioGroup } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { Domain } from '@material-ui/icons';
export default function PornCheck() {

  const json = useRef()
  const json2 = useRef()
  const wynik = useRef()
  const tableRef = useRef();
  const [datatableData,setdatatableData] = useState([]);
  const [CrawlSwitch, setCrawlSwitch] = useState(0);
  var text;
  var words;
    function Action()
    {
      wynik.current.innerHTML = "";
      var domain = json.current.value;
      var kwds = json2.current.value;
      var Keywords = kwds.split(";");
      var Domains = domain.split("\n");
      console.log(Domains)
      console.log(Keywords)
      if(CrawlSwitch == 0)
      {
        var MainUrl = "https://mobileappscrapper.herokuapp.com/searchhtml/";
      }
      else
      {
        var MainUrl = "https://mobileappscrapper.herokuapp.com/searchtext/";
      }
      
      
      for(let element in Domains)
      {
        fetch(MainUrl + kwds + "/" + Domains[element])
        .then(response => response.json())
        .then(data => dataParse(data, element));
      }


    }
function dataParse(data, element)
{
  console.log(data);
  console.log(JSON.parse(data.WordCount));
  words = JSON.parse(data.WordCount);

  for(var a = 0; a<words.length; a++)
  {
    console.log(a)
    setdatatableData(datatableData => [...datatableData,[data.Domain, words[a].word, words[a].count]])
    console.log(datatableData);
  }


}

function check()
{

}
function ChangeVariant()
{
  if(CrawlSwitch == 0)
  {
    setCrawlSwitch(1);
  }
  else
  {
    setCrawlSwitch(0);
  }
}
const label = { inputProps: { 'aria-label': 'Checkbox' } };
return (
    <div >
<Checkbox {...label} onChange={ChangeVariant} />Use secondary variant of crawl method
      <br></br>
       <TextField variant="outlined" multiline inputRef={json} rows={10} placeholder="Insert domains separated by line break" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
       
       <TextField variant="outlined" multiline inputRef={json2} rows={10} placeholder="Insert keywords separated by ; " id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
       
    <br></br>
    <Button variant="contained" color="primary" onClick={Action}>Analyse</Button>
    <p> </p>
    <div ref={wynik}>
    

    </div>
    <MUIDataTable
            
            title="Results"
            ref={tableRef}
            columns={["Domain", "Word", "Instances"]}
            data={datatableData}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'PornPiracyAnalyser_' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please scan websites first",
                }},
            }}
            
          />
    </div>
);
}