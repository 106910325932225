import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";

import { Button } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Grid } from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import { toast, ToastContainer } from "react-toastify";
import { LinearProgress } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  addDomainForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2), // Added padding for spacing
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
   
  },
  button: {
    marginTop: theme.spacing(2),
  },
  selectContainer: {
    width: "100%", // Set width to 100% for consistency
  },
  header: {
    margin: theme.spacing(2, 0),
  },
  widgetContainer: {
    marginBottom: theme.spacing(3),
  },
  buttonLarge: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    width: "100%",
  },
  progressContainer: {
    textAlign: "center",
    margin: theme.spacing(2, 0),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
}));
const AddBulkDomainForm = ({ onAddDomain }) => 
{
  const [data, setData] = useState("");
  const [newData, setNewData] = useState();
  const [errorCount, setErrorCount] = useState(0);
  
  const inputData = useRef();
  const columns = [
    {
      name: "domain",
      label: "Domain",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "network",
      label: "Network",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "decision",
      label: "Decision",
      options: {
        filter: true,
        sort: true,

      },
    },
    {
      name: "comment",
      label: "Comment",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "is_exception",
      label: "Exception",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

 
 
  const options = 
  {


        
      
  }
  function isNumber(value) {
    return typeof value === 'number';
  }
  const convert = () => {
    let data = inputData.current.value;
    let before = data.split("\n");
    let splitted = before.map((element) => {
      var a = element.split(";");
      return {
        domain: a[0],
        network: a[1],
        decision: a[2],
        comment: a[3],
        is_exception: a[4],
      };
    });

    setNewData(splitted);
    setErrorCount(0);

    const modifiedData = splitted.map((element) => {
      const updatedElement = {
        ...element,
        domain: element.domain.startsWith("www.") ? element.domain.split("www.")[1] : element.domain === "" ? "Error" : element.domain,
        decision: element.decision == 1 ? "Approved" : element.decision == "Approved" ? "Approved" : "Error",
        network: element.network.toString(),
        is_exception: element.is_exception.toLowerCase() === "true" ? "Yes" : element.is_exception.toLowerCase() === "false" ? "No" : "Error",
      };

      if (updatedElement.decision === "Error") {
        setErrorCount((prevCount) => prevCount + 1);
      }
      if (updatedElement.domain === "Error") {
        setErrorCount((prevCount) => prevCount + 1);
      }
      if (updatedElement.network === "Error") {
        setErrorCount((prevCount) => prevCount + 1);
      }
      if (updatedElement.is_exception === "Error") {
        setErrorCount((prevCount) => prevCount + 1);
      }

      return updatedElement;
    });

    setNewData(modifiedData);
  };

  const preSent = () => {
    if(errorCount === 0)
    {
      Send(newData)

    }
 
  };


  const Send = (data) => {

    
    let dataToSend = [];
    for(let element in data)
    {
      dataToSend[element] = {
        "domain": data[element].domain,
        "network": data[element].network,
        "decision": data[element].decision,
        "comment": data[element].comment,
        "is_exception": data[element].is_exception === "Yes" ? true : false
      }
    }
    console.log("Sent!", dataToSend)

      onAddDomain(dataToSend)
    

    
  }
  return (
    <>
    <Grid>

    <Widget style={{marginTop:"25px", textAlign:"center"}} title="Bulk upload" >
    <h2>Bulk upload</h2>
      <h3>Example input: </h3>
      <pre>google.com;1097;1;This is my comment;false</pre>
      <pre>msn.com;1097;1;;false</pre>
      <h3>1 stands for approved, date is aquired automatically</h3>
  <TextField inputRef={inputData} placeholder="Insert approved domains here" rows={15} style={{width:"100%", border:"1px solid black",}} multiline/>
  <div style={{textAlign:"center"}}>
  <Button color="secondary" style={{marginTop:"25px", marginBottom:"25px"}} variant="contained" onClick={()=>{convert()}}>Convert to table ⏬</Button>
  </div>
 
  <MUIDataTable
  key={JSON.stringify(newData)}
  data={newData}
  columns={columns}
  options= {options}
  >

  </MUIDataTable>
        
  <div style={{textAlign:"center", paddingTop:"25px", paddingBottom:"25px"}}>
    {!newData || newData.length == 0 || errorCount > 0? (<Button disabled={true} color="secondary" variant="contained" onClick={()=>{preSent()}}>Send to approved domains database!</Button>):(<Button color="secondary" variant="contained" onClick={()=>{preSent()}}>Send to approved domains database!</Button>)

    }
          
          <div style={{color:"red"}}>
          <h3>Error count: {errorCount}</h3>
          </div>
          
          </div>
  <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </Widget>
    </Grid>
  
    </>
  )
}
const AddDomainForm = ({ onAddDomain }) => {
  const classes = useStyles();
  const [domain, setDomain] = useState("");
  const [network, setNetwork] = useState("");
  const [decision, setDecision] = useState("Approved");
  const [comment, setComment] = useState("");
  const [isException, setIsException] = useState(false);

  const handleAddDomain = () => {
    const newDomain = {
      domain,
      network,
      decision,
      comment,
      is_exception: isException,
    };

    // Call the callback function to add the new domain
    onAddDomain(newDomain);

    // Reset form fields after adding the domain
    setDomain("");
    setNetwork("");
    setDecision("Approved");
    setComment("");
    setIsException(false);
  };

  return (

      <Widget style={{marginTop:"25px", textAlign:"center"}} className={classes.widgetContainer} >
        <h2>Add New Domain</h2>
        <div className={classes.addDomainForm}>
        
          <Grid container spacing={2}>
      <Grid>
      <TextField
        label="Domain"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Network"
        value={network}
        onChange={(e) => setNetwork(e.target.value)}
        margin="normal"
      />
      <FormControl style={{marginTop:"12px"}} className={classes.formControl}>
        <InputLabel>Decision</InputLabel><br></br>
        <Select
          value={decision}
          onChange={(e) => setDecision(e.target.value)}
        >
          <MenuItem value="Approved">Approved</MenuItem>
          <MenuItem value="Not Approved">Not Approved</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Comment"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        margin="normal"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isException}
            onChange={(e) => setIsException(e.target.checked)}
          />
        }
        label="Is Exception"
        style={{marginTop:"30px"}}
      />
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={handleAddDomain}
      >
        Add Domain
      </Button>
      </Grid>
      </Grid>
      </div>
    </Widget>
  );
};


export default function ApprovedDomainsTable() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [newDomains, setNewDomains] = useState([]); // State for new domains input
  const [selectedRows, setSelectedRows] = useState([]); // State to store selected rows
  const [isOpened, setIsOpened] = useState(false)
  const [status, setStatus] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("");
  const [progress, setProgress] = useState(0)
 
  useEffect(() => {
    // Fetch data from the API endpoint
    fetch("https://mobileappscrapper.herokuapp.com/view_approved")
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const columns = [
    {
      name: "DOMAIN",
      label: "Domain",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "NETWORK",
      label: "Network",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "DECISION",
      label: "Decision",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "UPDATE_DATE",
      label: "Update Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "COMMENT",
      label: "Comment",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "IS_EXCEPTION",
      label: "Exception",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (value ? "Yes" : "No"),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    selectableRowsOnClick: true,
    onRowsSelect: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <Tooltip title="Remove Selected Domains">
          <IconButton onClick={() => handleRemoveDomains(selectedRows, displayData, setSelectedRows)}>
            <Delete/>
          </IconButton>
        </Tooltip>
      );
    },
  };

  const handleAddDomains = async (data) => {
    setStatus(true);
    setProgress(0);
    setUploadProgress("0% (0 of " + data.length + " domains are being processed)");

    // Function to split data into batches of 20
    const chunkData = (data, chunkSize) => {
      const chunks = [];
      for (let i = 0; i < data.length; i += chunkSize) {
        chunks.push(data.slice(i, i + chunkSize));
      }
      return chunks;
    };

    // Splitting the data into batches of 20
    const batches = chunkData(data, 20);
    const totalBatches = batches.length;

    // Function to process each batch
    const processBatch = async (batch) => {
      try {
        const response = await fetch("https://mobileappscrapper.herokuapp.com/insert_data", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(batch),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      } catch (error) {
        console.error("Error adding domains:", error);
      }
    };

    const totalDomains = data.length;
    let domainsProcessed = 0; // Initialize a counter for processed domains

    // Process all batches
    for (let i = 0; i < totalBatches; i++) {
      await processBatch(batches[i]); // Await the processing of each batch
      domainsProcessed += batches[i].length; // Update the number of processed domains
      const newProgress = (domainsProcessed / totalDomains) * 100;
      setProgress(newProgress); // Update the state
      setUploadProgress(newProgress.toFixed(2) + "% (" + domainsProcessed + " of " + totalDomains + " domains are being processed)");
    }

   
    // Refresh data after all batches are processed
    try {
      const response = await fetch("https://mobileappscrapper.herokuapp.com/view_approved");
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      setData(jsonData);
      setNewDomains([]); // Clear new domains after adding
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setStatus(false);
      toast.success(`🦄 Wow so easy! Domains were added into database`, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
};


  const handleRemoveDomains = () => {
    // Extract the selected domains
    const domainsToRemove = selectedRows.map(
      (row) => ({
        domain: data[row.dataIndex].DOMAIN,
        network: data[row.dataIndex].NETWORK,
      })
    );

    // Call the Flask endpoint to remove selected domains
    fetch("https://mobileappscrapper.herokuapp.com/delete_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(domainsToRemove),
    })
      .then((response) => response.json())
      .then((result) => {
        // Refresh data after removing domains
        fetch("https://mobileappscrapper.herokuapp.com/view_approved")
          .then((response) => response.json())
          .then((jsonData) => {
            setData(jsonData);
            setSelectedRows([]); // Clear selected rows after removal
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      })
      .catch((error) => {
        console.error("Error removing domains:", error);
      });
  };
  const handleAddDomain = (newDomain) => {
    // Call the Flask endpoint to add the new domain
    fetch("https://mobileappscrapper.herokuapp.com/insert_data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify([newDomain]),
    })
      .then((response) => response.json())
      .then((result) => {
        // Handle the result as needed (e.g., show success message)
        console.log("Domain added successfully:", result);
        fetch("https://mobileappscrapper.herokuapp.com/view_approved")
        .then((response) => response.json())
        .then((jsonData) => {
          setData(jsonData);
          setSelectedRows([]); // Clear selected rows after removal
          toast.success(`🦄 Wow so easy! Domain ${newDomain.domain} was added into database`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        })
        // Optionally, refresh the data or update the state to include the new domain
        // ...

      })
      .catch((error) => {
        // Handle errors (e.g., show error message)
        console.error("Error adding domain:", error);
        toast.error(`Sadly, domain ${newDomain.domain} was NOT added into database`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      
      });
  };
  return (
    <div className={classes.tableContainer}>
      <AddDomainForm onAddDomain={handleAddDomain} />
      <AddBulkDomainForm onAddDomain={handleAddDomains} />

      <div className={classes.progressContainer}>
    {status && 
    <>
        <p>{uploadProgress}</p>
        <LinearProgress variant="determinate" value={progress} color="secondary" />
        <p><b>Do not close the window when you are seeing this message!</b></p>
    </>}
</div>

      <Widget style={{marginTop:"25px"}} className={classes.widgetContainer}>
        <Button
          className={classes.buttonLarge}
          variant="contained"
          color="secondary"
          onClick={() => setIsOpened(!isOpened)}
        >
          {isOpened ? "Hide Final Table" : "Show Final Table"}
        </Button>
      </Widget>

      {isOpened && (
        <MUIDataTable
          title={"Approved Domains"}
          data={data}
          columns={columns}
          options={options}
        />
      )}
    </div>
  );
}
