import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
import punycode from 'punycode';


export default function PunycodeDecoder() {

  const json = useRef()
  const wynik = useRef()
  var text;
    function Action()
    { 
      
      let url = json.current.value;
      let url_split = url.split(/\n|,/g);

      for (let i = 0; i < url_split.length; i++) {
          
        let j = i;

        wynik.current.innerHTML += punycode.toUnicode(url_split[i]) + "<p>";
    }
  }
    
  
return (
    <div >
      <br></br>
       <TextField variant="outlined" inputRef={json} rows={10} fullwidth multiline placeholder="Insert punycode urls here for example: xn--80aaagf6ddsog.xn--90ais" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
    <p> </p> 
    <p> </p>
    <br></br>
    <Button variant="contained" color="primary" onClick={Action}>Decode</Button>
    <p> </p>
    <div ref={wynik}>

    </div>
    </div>
);
}