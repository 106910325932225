import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useRef } from 'react';
import { Checkbox } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function NestedGrid() {
  const classes = useStyles();
  const domain=useRef()
  const type=useRef()
  const networkid=useRef()
  const partnerid=useRef()
  const wynik=useRef()
  const tooneline=useRef()
	var ToOneLine = 0;

  function Merge()
  {
    wynik.current.innerText = "";
    wynik.current.innerText = "Name,Type,NetworkId,PartnerId \n";
    var domains=domain.current.value;
    var types=type.current.value;
    var networkids=networkid.current.value;
    var partnerids=partnerid.current.value;
    var domain_split = domains.split(/\n|,/g);
    var type_split = types.split(/\n|,/g);
    var networkid_split = networkids.split(/\n|,/g);
    var partnerid_split = partnerids.split(/\n|,/g);
    var rules = [{}];

    
    if(tooneline.current.checked == true)
    {
      ToOneLine = 1;
    }
    else
    {
      ToOneLine = 0;
    }

    for(let elements in domain_split)
    {
      console.log("works")
      rules[elements] = {
        name: domain_split[elements],
        type: type_split[elements],
        networkid: networkid_split[elements],
        partnerid: partnerid_split[elements]

      }
      
    }
    for(let elems in rules)
    {
      rules[elems].name = rules[elems].name.toLowerCase();
      rules[elems].name = rules[elems].name.replace('https://', '');
      rules[elems].name = rules[elems].name.replace('http://', '');
      rules[elems].name = rules[elems].name.replace('www.', '');
      if(rules[elems].name.endsWith('/'))
      {
        console.log("works")
        rules[elems].name = rules[elems].name.slice(0, -1);
      }
      if(rules[elems].name == undefined)
      {
        rules[elems].name = "";
      }
      if(rules[elems].type == undefined)
      {
        rules[elems].type = "";
      }
      if(rules[elems].networkid == undefined)
      {
        rules[elems].networkid = "";
      }
      if(rules[elems].partnerid == undefined)
      {
        rules[elems].partnerid = "";
      }
    }
    
    for (let i=0; i < rules.length; i++)
{
	if(ToOneLine === 1)
	{
		wynik.current.innerText += rules[i].name + "," + rules[i].type + "," + rules[i].networkid + "," + rules[i].partnerid
    
  }
	if(ToOneLine === 0)
	{
    wynik.current.innerText += rules[i].name + "," + rules[i].type + "," + rules[i].networkid + "," + rules[i].partnerid + "\n"
  }

	  
}    

  }
  function Clear()
  {
    wynik.current.innerText = "";
  }
  function Copy()
  {
    copyToClipboard(wynik.current.innerText);
  }
  function copyToClipboard(text) {
    navigator.clipboard.writeText(text);
}
function Download()
{
let array = wynik.current.innerHTML.split("<br>")
console.log(array)
var text = array.map(function(d){
  return JSON.stringify(d);
})
.join('\n') 
download_txt(text);
}

function objectsToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr)
  return array.map(row => {
      return Object.values(row).map(value => {
          return typeof value === 'string' ? JSON.stringify(value) : value
      }).toString()
  }).join('<br>')
}

function download_txt(text) {
  var textToSave = text;
var hiddenElement = document.createElement('a');

hiddenElement.href = 'data:attachment/text,' + encodeURI(textToSave);
hiddenElement.target = '_blank';
var today = new Date();
var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
hiddenElement.download = 'Blocklist_' + date + '.csv';
hiddenElement.click();
}
  function FormRow() {
    return (
      <React.Fragment>
        
        <Grid item xs={3}>
        <br></br>
          Domain / Bundle
          <TextField variant="outlined" inputRef={domain} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        <Grid item xs={1}>
        <br></br>
          Type
          <TextField variant="outlined" placeholder="0-Domain           1-Bundle" inputRef={type} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        <Grid item xs={1}>
        <br></br>
          Network Id
          <TextField variant="outlined" inputRef={networkid} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
        <Grid item xs={1}>
        <br></br>
          Partner Id
          <TextField variant="outlined" inputRef={partnerid} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </Grid>
      </React.Fragment>

    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid container item xs={12} spacing={3}>
          <FormRow />
        </Grid>

      </Grid>
      <p><br></br> </p>
      <p> </p>
      <p> </p>
      
                  <Checkbox inputRef={tooneline}  color="primary" type="checkbox" id="showresults"></Checkbox>
                  <label htmlFor="showresults">Merge to one line</label><br></br>
      <Button variant="contained" color="primary" onClick={Merge} >Merge</Button><span>  </span>
      <Button color="primary" variant="outlined" onClick={Clear}>Clear result</Button><span></span>
      <Button color="primary" variant="outlined" onClick={Copy}>Copy</Button><span></span>
      <Button color="primary" variant="outlined" onClick={Download}>Download</Button>
      <Grid>
        <div>
        <br></br>
        <br></br>
        <div ref={wynik}>

        </div>
        </div>
      </Grid>
    </div>

  );
}