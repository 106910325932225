import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { FormGroup, Switch,Radio,RadioGroup } from '@material-ui/core';

import MUIDataTable from "mui-datatables";
export default function CTVAnalyser() {

  const json = useRef()
  const json2 = useRef()
  const wynik = useRef()
  const tableRef = useRef();
  const AddDomains = useRef();
  const Analyse = useRef();

  const [datatableData,setdatatableData] = useState([]);
  const [CrawlSwitch, setCrawlSwitch] = useState(0);
  var text;
  var words;
  var Domains;
  var PornDane = [];
  var PiracyDane = [];
  var Dane2 = [];
  var Scores = [];
  var PiracyResults = [];
  var PornResults = [];
  var Scor = [];
  var respons = "";
  var appTitle = "";
  var appStoreUrl = "" ;
  var developerWebsite = "";
  var hasAppAdsTxt = "";
  var starRatingCount = "";
  var rating = "";
  var appStore = "";
  var categories = "";
  var containsSensitiveCategory = "";
  var isHavingMinReviews = "";
  var reasonForResult = "";
  var Result = "";
  var Status = "";
  var appId = "";
  var containsSensitiveKeyword = "";
  var Keywords = [
    "bdsm",
    "erotic",
    "erotik",
    "fuck",
    "hentai",
    "lesbian",
    "naughty",
    "porn",
    "pussy",
    "rape",
    "xtube",
    "locker",
    "launcher",
    "wallpaper",
    "flashlight",
    "calc",
    "bang",
    "bdsm",
    "erotic",
    "erotik",
    "fuck",
    "hentai",
    "lesbian",
    "naughty",
    "nude",
    "porn",
    "pussy",
    "rape",
    "xtube",
    "locker",
    "launcher",
    "wallpaper",
    "flashlight",
    "calc",
   "lockscreen",
   "launcher",
   "wallpaper",
   "wallpapers",
   "screensaver",
   "screensavers",
   "screen",
   "saver",
   "screen saver",
   "photo gallery",
   "ringtones",
   "ringtone",
   "vpn",
   "calculator",
   "widget",
   "sticker",
   "keyboard",
   "cleaner",
   "emoji",
   "bitmoji",
   "alarm clock",
   "clock",
   "adblock",
   "theme",
   "themes",
   "remote"
   
  ];
    function Action()
    {
      setdatatableData([])
      wynik.current.innerHTML = "";
      var domain = json.current.value;
      //var kwds = json2.current.value;
      //var Keywords = kwds.split(";");
      Domains = domain.split("\n");
      //console.log(Domains)
      //console.log(Keywords)
      if(CrawlSwitch == 0)
      {
        var MainUrl = "https://mobileappscrapper.herokuapp.com/ctv/";
      }
      else
      {
        var MainUrl = "https://mobileappscrapper.herokuapp.com/ctv/";
      }
      
      
      for(let element in Domains)
      {
        
       
        appTitle = "";
        appStoreUrl = "" ;
        developerWebsite = "";
        hasAppAdsTxt = "";
        starRatingCount = "";
        rating = "";
        appStore = "";
        containsSensitiveCategory = "";
        containsSensitiveKeyword = "";
        isHavingMinReviews = "";
        reasonForResult = "";
        Result = "";
        Status = "";
        appId = "";
       
        fetch(MainUrl +  Domains[element])
        .then(res => res.json())
        .then(res => respons = res)
        .then((response) => {
          if(!response.ok) 
          {
            try
            {
              if(Status = respons.docs[0].status == undefined)
              {
                Status = "OK"
              }
              else
              {
                Status = respons.docs[0].status;
              }
              
            }
            catch
            {
              Status = 'OK'
            }
            try
            {
              appId = respons.docs[0].appId
            }
            catch
            {
              appId = Domains[element];
            }
            try{
              
              appTitle = respons.docs[0].appOverview.appTitle
              let appname = appTitle.split(" ");
              for (let elms in appname)
  {
    console.log(appname[elms])
    appname[elms] = appname[elms].toLowerCase();
              if(Keywords.some(r => appname[elms].includes(r)) == true)
   {

    containsSensitiveKeyword = 1;
    console.log("Contains!")
    break;
   
   }
   else
   {
    containsSensitiveKeyword = 0;
    console.log("NOT Contains!")
   }
  
            }
          }
            catch
            {
              appTitle = "Not found";
            }
            try
            {
              appStoreUrl = respons.docs[0].appDetails.appStoreUrl;
            }
            catch
            {
              appStoreUrl = "Not found";
            }
            try
            {
              developerWebsite = respons.docs[0].developerOverview.developerWebsite;
            }
            catch
            {
              developerWebsite = "Not found";
            }
            try
            {
              hasAppAdsTxt = respons.docs[0].pixalateAdvisories.hasAppAdsTxt.toString();
            }
            catch
            {
              hasAppAdsTxt = "Not found";
            }
            try
            {
              rating = respons.docs[0].appDetails.starRatingCount
            }
            catch
            {
              rating = "Not found"
            }
            try
            {
              starRatingCount = respons.docs[0].appDetails.rating;
            }
            catch
            {
              starRatingCount = "Not found";
            }
            try
            {
             appStore = respons.docs[0].device;
            }
            catch
            {
              appStore = "Not found";
            }
            try
            {

              for(let elements in respons.docs[0].appOverview.categories)
              {
                if(respons.docs[0].appOverview.categories[elements] == "Screensavers" || respons.docs[0].appOverview.categories[elements] == "Themes")
                {
                  containsSensitiveCategory = 1;
                }
                categories +=  respons.docs[0].appOverview.categories[elements] + " | ";
               
              }
              if(containsSensitiveCategory != 1)
              {
                containsSensitiveCategory = 0;
              }
             
            }
            catch
            {
              categories = "Not found";
            }

            
            if(starRatingCount !== "Not found")
            {
              
              if(rating !== "Not found")
              {
                console.log(parseInt(starRatingCount) + " Star rating count");
                console.log(parseInt(rating) + " rating");
               
                if(containsSensitiveCategory == 1)
                {
                  Result = "Not approved"
                  reasonForResult = "Contains Sensitive Category "
                }
                if(containsSensitiveKeyword == 1)
                {
                  Result = "Not approved"
                  reasonForResult = "Contains Sensitive Keyword "
                }
                if(starRatingCount > 60)
                {
                  if(rating > 10)
                  {
                    isHavingMinReviews = 1;
                    Result = "Approved"
                  }
                  else
                  {
                    Result = "Not Approved"
                    isHavingMinReviews = 0;
                    reasonForResult = "Minimal reviews criteria not met "
                  }
                }
                else
                {
                  if(starRatingCount > 60)
                  {
                    isHavingMinReviews = 1;
                    Result = "Approved"
                  }
                  else
                  {
                    Result = "Not Approved"
                    isHavingMinReviews = 0;
                    reasonForResult = "Minimal reviews criteria not met "
                  }
                }
              }
            }
            
           //Result = "Not working yet :D "
            
          
          setdatatableData(datatableData => [...datatableData,[appId, Status, appTitle, appStoreUrl, appStore, developerWebsite, hasAppAdsTxt, starRatingCount, rating, categories, containsSensitiveCategory, containsSensitiveKeyword, isHavingMinReviews, reasonForResult, Result]])
          categories = "";
          appTitle = "";
        appStoreUrl = "" ;
        developerWebsite = "";
        hasAppAdsTxt = "";
        starRatingCount = "";
        rating = "";
        appStore = "";
        containsSensitiveCategory = "";
        isHavingMinReviews = "";
        reasonForResult = "";
        Result = "";
        containsSensitiveKeyword = "";
          }
          
        }

        ) 
        .catch(error => 
          {
            toast.error('Api request limit exceeded :( ' ,{
              autoclose: false,
              toastId: "roku",
              position: "top-center",
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              });
              
          })
        
          
/*  
        

       */ 
      }

      
      console.log(Scores)
       /*
      for(let element in Scores)
      {
        
        Scor[element] = 
        {
          Result: Scores[element]
        }
        //setdatatableData(datatableData => [...datatableData,[Scor[element].Result.Domain, Scor[element].Result.PiracyScore, Scor[element].Result.PornScore]])
      }
   
     
      for(let element in Domains)
      {
        
        fetch(MainUrl + PornData + "/" + Domains[element])
        .then(response => response.json())
        .then(data => PornDane.push(data))
        .catch((error) => {}
        )

      }


      //AddDomains.current.style.display = "None";
      //json.current.disabled = 'true';
*/
    }
    
    function groupArrayOfObjects(list, key) {
      return list.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };


function check()
{
  console.log(Scores)
  /*
  let Score;
  console.log(PiracyDane);
  var Piracymatching = [];
  for(let element in PiracyDane)
  {
    Piracymatching[element] = 0;
  }
  var Piracydifferent = [];
  for(let element in PiracyDane)
  {
    Piracydifferent[element] = 0;
  }
  var Pornmatching = [];
  for(let element in PornDane)
  {
    Pornmatching[element] = 0;
  }
  var Porndifferent = [];
  for(let element in PornDane)
  {
    Porndifferent[element] = 0;
  }
  var PiracyRiskScore = [];
  var PornRiskScore = [];
  for(let element in PiracyDane)
  {
    let kvds = JSON.parse(PiracyDane[element].WordCount)
    PiracyRiskScore[element] = 0;
    for(let elem in kvds)
    {
      Piracymatching[element] += kvds[elem].count;
      if(kvds[elem].count >= 1)
      {
        Piracydifferent[element] += 1;
      }
    }
    if(Piracydifferent[element] >= 2)
    {
      PiracyRiskScore[element]++;
    }
    if(Piracymatching[element] >= 50)
    {
      PiracyRiskScore[element] += 1;
    }
    PiracyResults[element] = 
    {
      "Domain": PiracyDane[element].Domain,
      "Words": JSON.parse(PiracyDane[element].WordCount),
      "Matching": Piracymatching[element],
      "Different": Piracydifferent[element],
      "RiskScore": PiracyRiskScore[element]
    }
  }
  for(let element in PornDane)
  {
    let kvds = JSON.parse(PornDane[element].WordCount)
    PornRiskScore[element] = 0;
    for(let elem in kvds)
    {
      Pornmatching[element] += kvds[elem].count;
      if(kvds[elem].count >= 1)
      {
        Porndifferent[element] ++;
      }
    }
    if(Porndifferent[element] >= 2)
    {
      PornRiskScore[element]++;
    }
    if(Pornmatching[element] >= 50)
    {
      PornRiskScore[element]++;
    }
    PornResults[element] = 
    {
      "Domain": PornDane[element].Domain,
      "Words": JSON.parse(PornDane[element].WordCount),
      "Matching": Pornmatching[element],
      "Different": Porndifferent[element],
      "RiskScore": PornRiskScore[element]
    }
  }
//var Results = Array.prototype.push.apply(PiracyResults,PornResults); 
console.log(PiracyResults);
console.log(PornResults);

var Results = [];
for(let elements in PiracyDane)
{
  
  Results[elements] = 
  {
    "Domain": PiracyResults[elements].Domain,
    "PiracyRiskScore": PiracyResults[elements].RiskScore,
    "PornRiskScore": 0

  }
  for(let elem in PornDane)
  {
    if(Results[elements].Domain == PornResults[elem].Domain)
    {
      Results[elements].PornRiskScore = PornResults[elem].RiskScore
      
    }
  }
  setdatatableData(datatableData => [...datatableData,[Results[elements].Domain, Results[elements].PiracyRiskScore, Results[elements].PornRiskScore]])
}

console.log(Results);

/*
for(let element in PiracyDane)
{
  

}
Dane2[element] = 
{
  "Domain": Dane[element].Domain,
  "PiracyScore": 0,
  "PornScore": 0,
  "Matching": 0,
  "Words": JSON.parse(Dane[element].WordCount)
}
*/
console.log(Dane2);

}
function ChangeVariant()
{
  if(CrawlSwitch == 0)
  {
    setCrawlSwitch(1);
  }
  else
  {
    setCrawlSwitch(0);
  }
}
const label = { inputProps: { 'aria-label': 'Checkbox' } };
return (
    <div >
{/* <Checkbox {...label} onChange={ChangeVariant} />Use secondary variant of crawl method 
      <TextField variant="outlined" multiline inputRef={json2} rows={10} placeholder="Insert keywords separated by ; " id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
  */     
}
      <br></br>
       <TextField variant="outlined" multiline inputRef={json} rows={10} placeholder="Insert bundles separated by line break" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
       
 
    <br></br>
    <Button ref={AddDomains} variant="contained" color="primary"  onClick={Action}>Analyse</Button>
    <p> </p>
    <div ref={wynik}>
    

    </div>
    <ToastContainer
                  position="top-right"
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  ></ToastContainer>
    <MUIDataTable
            
            title="Results"
            ref={tableRef}
            columns={["BundleId", "Status", "AppName", "StoreUrl", "Store", "DeveloperURL", "AppAds.txt", "StarRating", "ReviewCount", "Categories", "ContainsSensitiveCategory", "ContainsSensitiveKeyword", "IsHavingMinimumReviews", "Reason for Result", "Result"]}
            data={datatableData}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'CTVAnalyser_' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please scan bundles first",
                }},
            }}
            
          />
    </div>
);
}