import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter, Redirect, useHistory } from "react-router-dom";
import classnames from "classnames";
import logo from './gadget.png';
// styles
import useStyles from "./styles";

// logo
import google from "../../images/google.svg";
import { GoogleLogin } from 'react-google-login';
// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [loginValue, setLoginValue] = useState("admin@flatlogic.com");
  var [passwordValue, setPasswordValue] = useState("password");
const [cookiecheck, setcookiecheck] = useState(false)
const refe = useRef(null);
  const responseGoogle = (response) => {
    
    
    if(response.profileObj.email == "damianchymkowski@gmail.com" || response.profileObj.email.endsWith("@smartadserver.com") || response.profileObj.email.endsWith('@equativ.com'))
    {
      console.log("Success!");
      setisSignedIn(true);
      document.cookie = "Logincookie; expires=Thu, 18 Dec 2025 12:00:00 UTC";
      localStorage.setItem('email', response.profileObj.email);
      localStorage.setItem('imageUrl', response.profileObj.imageUrl);
      localStorage.setItem('name', response.profileObj.givenName);
      
    }
    else
    {
      console.log("Failed");
      setisSignedIn(true);
      refe.current.innerText= "You don't have permissions to open this app";
      
      
      
    }

  }

  function Home()
  {
    let history = useHistory();
    history.push("/#/login")
  }
  useEffect(() => {
    var cookie = document.cookie.split(";");
    var cookies = document.cookie;
    for(let element in cookie)
    {
      if(cookie[element].endsWith("Logincookie"))
      {
    setisSignedIn(true);
    loginUser(
      userDispatch,
      loginValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
    )
    //setcookiecheck(true);
    
      }
    }

     

  })

  const [isSignedIn, setisSignedIn] = useState(false);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
      
        <Typography className={classes.logotypeText}>Quality Tooling</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
        <img src={logo} className={classes.form} />

            
            
        
          
            <React.Fragment>
              
              <Typography variant="h3" className={classes.greeting}>

              {(() => {
                let date = new Date();
        if (date.getHours() < 12) {
          
          return (
            <h3>Good Morning, Inspector!</h3>
            )
          } 
        })()}
        {(() => {
                let date = new Date();
        if (date.getHours() >= 12 && date.getHours() <= 18) {
          
          return (
            <h3>Good Afternoon, Inspector!</h3>
            )
          } 
        })()}
        {(() => {
                let date = new Date();
        if (date.getHours() > 18) {
          
          return (
            <h3>Good Evening, Inspector!</h3>
            )
          } 
        })()}
                
                
                <div className={classes.formButtons}>

                  {
              /*
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      {
                        setisSignedIn(true);
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                  */
                  }

     <GoogleLogin
    clientId="1039534305222-s6q749i6l4lqs4l2i3m11lqcnprcvpcf.apps.googleusercontent.com"
    buttonText="Login"
    onSuccess={responseGoogle}
    onFailure={()=>{refe.current.innerText= "You don't have permissions to open this app";}}
    cookiePolicy={'single_host_origin'}
  />
 
  <p ref={refe}></p>
              </div>
              </Typography>


            </React.Fragment>
          
            
        </div>
        <Typography color="primary" className={classes.copyright}>
        © {new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }}  rel="noopener noreferrer" target="_blank">Damian Chymkowski</a>,  All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
