import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from "mui-datatables";
import SQTA from './SQTA';

export default function AppAnalyser() {
  const [datatableData,setdatatableData] = useState([]);
    const tekst = useRef();
    const result = useRef();
    const approved = useRef();
    const notapproved = useRef();
    const buttonsection = useRef();
    const tableRef = useRef();
    var mydata;
var mydata2;
var adt;
var Apps;
var Apps_split;
var Apps_checked = [];
var a=0;
var data;
var rating =0;
var numberofratings=0;
var checked = 1;
var minrating;
var minreviews;
var utilities;
var file;
var once = 0;
var appss = [{"domain":"","adt":"","dlm":"","piracy":"","adult":""}];
var piracy=0;
var adult=0;
var domains = [{"domain": "","adt": "","dlm": "", "isPorn": "", "isPiracy": "", "Nodata": ""}];


    function Scan()
    {
      setdatatableData([]);
      once = 0;
      
      result.current.innerHTML = "";


        Apps = tekst.current.value;
        Apps_split = Apps.split(/\n|,/g);

for (let i=0; i < Apps_split.length; i++)
{

    let j=i;
        var text = "https://api.adsafeprotected.com/db2/client/28824/itgrl.json?adsafe_url=" + Apps_split[j] + "&callback=?";
       

    $.getJSON(text, function (data) {

      domains[j] = {"domain": Apps_split[j],"adt": data.bsc.adt,"dlm": data.bsc.dlm, "isPorn": "", "isPiracy": "", "Nodata": "", "Approved": ""};
    
});
$(document).ajaxStop(function () {
    if(once == 0 )
    {
      
      for(let element in domains)
      {
 
       
        if(domains[element].adt <= 500)
        {

          domains[element].isPorn = 1;
          if(domains[element].dlm < 500)
          {
         
            domains[element].isPiracy = 1;
          }
          if(domains[element].dlm >= 500)
          {

            domains[element].isPiracy = 0;
          }
          if(domains[element].dlm == undefined)
          {

            domains[element].Nodata = 1;
            domains[element].isPiracy = "?";
          }
        }
        if(domains[element].adt == undefined)
        {

          domains[element].Nodata = 1;
          domains[element].isPorn = "?";
          if(domains[element].dlm < 500)
          {

            domains[element].isPiracy = 1;
          }
          if(domains[element].dlm >= 500)
          {

            domains[element].isPiracy = 0;
          }
          if(domains[element].dlm == undefined)
          {

            domains[element].Nodata = 1;
            domains[element].isPiracy = "?";
          }
          if(domains[element].dlm != undefined)
          {

            domains[element].Nodata = 0;
          }
        }
        if(domains[element].adt > 500)
        {

          domains[element].isPorn = 0;
          if(domains[element].dlm < 500)
          {

            domains[element].isPiracy = 1;
          }
          if(domains[element].dlm >= 500)
          {
           
            domains[element].isPiracy = 0;
          }
          if(domains[element].dlm == undefined)
          {

            domains[element].Nodata = 1;
            domains[element].isPiracy = "?";
          }
          if(domains[element].dlm != undefined)
          {

            domains[element].Nodata = 0;
          }
          
        }
        if(domains[element].adt != undefined)
        {
          domains[element].Nodata = 0;
        }
        if(domains[element].isPorn == 1 || domains[element].isPiracy == 1)
        {
         domains[element].Approved = "Not Approved"
        }
        else if(domains[element].Nodata == 1)
        {
              domains[element].Approved = "No data";
            }
            else
            {
            domains[element].Approved = "Approved";
            
        }
        setdatatableData(datatableData => [...datatableData,[domains[element].domain,domains[element].adt, domains[element].dlm, domains[element].isPorn,domains[element].isPiracy,domains[element].Approved]])
      }
  

    }
    
    once = 1;

          }
          
          );
        
  
          
      
  
  
  
         fillTable();
         
         
             
     
   
      console.log(domains);
      
}

    }

function fillTable()
{

}
return (
    <div >
        <Typography>Insert naked URLs separated by line break</Typography>
        <TextField inputRef={tekst} variant="outlined"  rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        <br></br>
        <br></br>
        <br></br>
        <Button variant="contained" color="primary" onClick={Scan}>Analyse</Button>
        <div ref={buttonsection}></div>
        <br></br>
        <br></br>
        <MUIDataTable
            
            title="Results"
            ref={tableRef}
            columns={["Domain", "Adult score", "Piracy score", "HasPorn", "HasPiracy", "Status"]}
            data={datatableData}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'WebsiteAnalyser_' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please scan websites first",
                }},
            }}
            
          />
        <div ref={result}></div>

<SQTA></SQTA>
    </div>
);
}