
import { Button } from '@material-ui/core';
import React, { useRef, useState, useEffect, useCallback} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { each } from 'jquery';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from "mui-datatables";
import mock from '../../pages/dashboard/mock';
import { Table } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Typography } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Fade from '@material-ui/core/Fade';
import { Avatar } from '@material-ui/core';
import { ControlPointOutlined, DoneAllOutlined } from '@material-ui/icons';
import { withRouter, Redirect, useHistory } from "react-router-dom";
import Iga3 from './Iga3.png';
import Patrycja from './Patrycja.jpg';
import StarIcon from '@material-ui/icons/Star';
import Kasia from './Kasia.jpg';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import Push from 'push.js';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,

} from "@material-ui/core";
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
// styles


import { makeStyles } from '@material-ui/styles';
import parse from 'iab-vast-parser/lib/parse';
// components

const LGAnalyser = () => {
  const [appIds, setAppIds] = useState('');
  const [appData, setAppData] = useState([]);

  // Function to handle data fetch for multiple appIds
  const fetchData = async () => {
    const idsArray = appIds.split('\n').filter(id => id.trim() !== '');
    const data = [];

    for (let id of idsArray) {
      try {
        const response = await fetch(`https://us.lgappstv.com/api/tvapp/retrieveAppInfo.ajax?appId=${id.trim()}`);
        const result = await response.json();

        // Add fetched data or fallback in case of missing information
        data.push({
          appId: id.trim(),
          appName: result.prodInfo?.[0]?.appName || 'N/A',
          appStoreUrl: `https://us.lgappstv.com/main/tvapp/detail?appId=${id.trim()}`,
          avgSscr: result.prodInfo?.[0]?.avgSscr ? (Math.round((result.prodInfo[0].avgSscr / 2) * 2) / 2) : 'N/A',
          cat: result.prodInfo?.[0]?.cat || 'N/A',
          sellrUsrName: result.prodInfo?.[0]?.sellrUsrName || 'N/A',
          sellrUrl: result.prodInfo?.[0]?.sellrUrl || 'N/A',
        });
      } catch (error) {
        console.error(`Error fetching data for appId ${id}:`, error);
        // Push empty data for this appId in case of an error
        data.push({
          appId: id.trim(),
          appName: 'N/A',
          appStoreUrl: `https://us.lgappstv.com/main/tvapp/detail?appId=${id.trim()}`,
          avgSscr: 'N/A',
          cat: 'N/A',
          sellrUsrName: 'N/A',
          sellrUrl: 'N/A',
        });
      }
    }

    setAppData(data);
  };

  // Define columns for the MUIDataTable
  const columns = [
    {
      name: 'appId',
      label: 'App ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'appName',
      label: 'App Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'appStoreUrl',
      label: 'Store Url',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'avgSscr',
      label: 'Score',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'cat',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sellrUsrName',
      label: 'Developer',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'sellrUrl',
      label: 'Developer Page',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  return (
    <>
      <h1>LG App Analyser</h1>
      <TextField
        label="App IDs (one per line)"
        multiline
        rows={4}
        variant="outlined"
        value={appIds}
        onChange={(e) => setAppIds(e.target.value)}
        fullWidth
      />
      <Button variant="contained" color="primary" onClick={fetchData} style={{ marginTop: '20px' }}>
        Fetch App Data
      </Button>
      <MUIDataTable
        title={'LG App Data'}
        data={appData}
        columns={columns}
        options={{
          filterType: 'checkbox',
        }}
      />
    </>
  );
};
const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const popupShown = getCookie('popupShown');
    setShowPopup(!popupShown);
    if (!popupShown) {
      setCookie('popupShown', true, 7); // Set cookie to expire in 7 days
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  if (!showPopup) {
    return null; // Do not render the popup if it should not be shown
  }

  return (
    <div style={overlayStyle}>
      <div style={popupStyle}>
        <h2>Are you human?</h2>
        <p>Please verify that you are not Patrycja.</p>
        <button style={buttonStyle} onClick={closePopup}>Close</button>
      </div>
    </div>
  );
};

// Function to set a cookie
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// Function to get a cookie by name
const getCookie = (name) => {
  const cookieName = name + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return true;
    }
  }
  return false;
}

// Inline styles
const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const popupStyle = {
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
};

const buttonStyle = {
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  padding: '10px 20px',
  borderRadius: '4px',
  cursor: 'pointer',
};


const Countdown = () => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const countdownDate = new Date('2023-12-22T00:00:00Z').getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));

      setDaysLeft(days);

      if (distance < 0) {
        clearInterval(interval);
        setDaysLeft(0); // Set to 0 if the countdown is finished
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ textAlign: "center" }}>
      <img style={{ borderRadius: "50%" }} src={Kasia} width="20%" alt="Kasia"></img><br></br>
      <b>Days left to say goodbye 😥 :  {daysLeft}</b>
      <hr></hr>
    </div>
  );
};


const RokuChecker = () => {
  const [data, setData] = useState({ feedChannel: {} });
  const [bundleData, setBundleData] = useState([]); // State to store bundle-wise data
  const text = useRef();
  const [tableData, setTableData] = useState([]);

  const fetchBundlesData = async (bundles) => {
    const batchSize = 20; // Number of fetches in each batch
    const bundleChunks = []; // Split bundles into chunks

    for (let i = 0; i < bundles.length; i += batchSize) {
      bundleChunks.push(bundles.slice(i, i + batchSize));
    }

    const fetchDataBatch = async (bundleChunk) => {
      const batchPromises = bundleChunk.map(async (bundle) => {
        try {
          const response = await fetch(
            `https://mobileappscrapper.herokuapp.com/html3/https://channelstore.roku.com/api/v6/channels/detailsunion/${bundle}`
          );
          if (!response.ok) {
            throw new Error(`Error fetching bundle: ${bundle}`);
          }
          const bundleData = await response.json();
          return bundleData;
        } catch (error) {
          console.error('Error fetching bundle:', error);
          return null;
        }
      });

      const batchResults = await Promise.all(batchPromises);
      return batchResults.filter((result) => result !== null);
    };

    const allBundleData = [];

    for (const chunk of bundleChunks) {
      const batchResults = await fetchDataBatch(chunk);
      allBundleData.push(...batchResults);
    }

    return allBundleData;
  };


  const columns = [
    {
      name: 'hdPosterUrl',
      label: 'Poster',
      options: {
        customBodyRender: (value) => {
          return (
            <img src={value} alt="Poster" style={{ width: '100px', height: 'auto' }} />
          );
        },
      },
    },
    { name: 'bundleId', label: 'bundleId' },
    { name: 'channelId', label: 'Channel ID' },
    { name: 'bundleurl', label: 'Bundle URL' },
    { name: 'name', label: 'Name' },
    { name: 'starRating', label: 'Star Rating' },
    {
      name: 'starRating',
      label: 'Star Rating',
      options: {
        customBodyRender: (value) => {
          const rating = parseFloat(value);
          const maxRating = 5;
          const starPercentage = (rating / 100) * maxRating;

          const stars = [];

          let remainingPercentage = starPercentage;

          for (let i = 0; i < maxRating; i++) {
            if (remainingPercentage >= 1) {
              stars.push(<StarIcon key={i} />);
            } else if (remainingPercentage >= 0.5) {
              stars.push(<StarHalfIcon key={i} />);
            } else {
              stars.push(<StarBorderIcon key={i} />);
            }

            remainingPercentage = Math.max(0, remainingPercentage - 1);
          }

          return (
            <div>
              {stars}
            </div>
          );
        },
      },
    },
    { name: 'starRatingCount', label: 'Star Rating Count' },
    
    { name: 'categories', label: 'Categories' },

    { name: 'developerUrl', label: 'developerUrl' },
    { name: 'privacyurl', label: 'privacypolicyurl'},

    { name: 'developer', label: 'Developer' },

  ];

  const formattedData = [data.feedChannel];


  const fetchChannelId = async (url) => {
    try {
      const response = await fetch(`https://mobileappscrapper.herokuapp.com/ctv/${encodeURIComponent(url)}`);
      const data = await response.json();
      if (data && data.docs && data.docs.length > 0) {
        return data.docs[0].appOverview.channelId;
      }
      return null;
    } catch (error) {
      console.error('Error fetching channel ID:', error);
      return null;
    }
  };
  
  const Scan = async () => {
    try {
      const inputBundles = text.current.value;
      const splitBundles = inputBundles.split('\n');
  
      const bundleData = [];
      for (const bundle of splitBundles) {
        const alphanumericMatch = bundle.match(/^([a-f0-9]{32})\/?$/i); // Alphanumeric format match
        const urlMatch = bundle.match(/^https:\/\/channelstore\.roku\.com\/(?:\w{2}-\w{2}\/)?(?:[a-z]+\/)?details\/(\d+)\/[\w-]+$/i); // Updated URL format match
        const numberMatch1 = bundle.match(/\/details\/(\d+)/);
        const numberMatch2 = bundle.match(/\/details\/(\d+)/);
        console.log(numberMatch1);
        console.log(numberMatch2);
        const numericMatch = bundle.match(/\/details\/([a-f0-9]{6,})/i); // Numeric format match for longer IDs
  
        if (numericMatch) {
          const numericId = numericMatch[1];
          bundleData.push(numericId); // Add numericId to fetch directly via fetchBundlesData
        } else {
          if (alphanumericMatch) {
            const alphanumericId = await fetchChannelId(alphanumericMatch[1]);
            if (alphanumericId) {
              bundleData.push(alphanumericId);
            }
          } else if (urlMatch) {
            const urlId = await fetchChannelId(urlMatch[1]);
            if (urlId) {
              bundleData.push(urlId);
            }
          }
          else
          {
            if (numberMatch1) {
              const extractedNumber1 = numberMatch1[1];
              let bund = await fetchChannelId(extractedNumber1);
              if(bund)
              {
                bundleData.push(bund);
              }
            } else {
              if (numberMatch2) {
                
                const extractedNumber2 = numberMatch2[1];
                let bund = await fetchChannelId(extractedNumber2);
                if(bund)
                {
                  bundleData.push(bund);
                }
              } else {
                console.log('No number found in url2');
              }
            }

         
           
          }
        }
      }
  
      const fetchedBundleData = await fetchBundlesData(bundleData);
      setBundleData(fetchedBundleData);
      console.log(fetchedBundleData);
    } catch (error) {
      console.error('Error scanning bundles:', error);
    }
  };
  const updateTableData = useCallback(() => {
    const formattedBundleData = bundleData.map((bundle) => ({
      
      name: bundle.feedChannel.name || '',
      starRating: bundle.feedChannel.starRating || '',
      starRatingCount: bundle.feedChannel.starRatingCount || '',
      categories: bundle.feedChannel.categories.map(category => category.name).join(', '),
      hdPosterUrl: bundle.feedChannel.hdPosterUrl || '',
      channelId: bundle.feedChannel.channelId || '',
      developer: bundle.feedChannel.developer || '',
      developerUrl: (bundle.details.currentDetail.channelStoreDetails.flatMap(detail => detail.appAdsTxtUrls)[0]) || '',
      bundleId: bundle.details.storeId || '',
      privacyurl: bundle.details.currentDetail.developerPrivacyUrl || '',
      bundleurl: "https://channelstore.roku.com/details/" + bundle.feedChannel.channelId
    }));

    setTableData(formattedBundleData);
  }, [bundleData]);

  useEffect(() => {
    updateTableData();
  }, [updateTableData]);



  return (
<>
<Grid container spacing={4}>
        <Grid item xs={12}>
        <Grid item xs={12}>
          <Widget>
<TextField placeholder="Insert Roku channel store urls like https://channelstore.roku.com/details/a20e3c294993147c6cda435497594031/apple-tv separated by line breaks " variant="outlined" inputRef={text} rows={10} fullwidth="true" multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
<div style={{alignItems:"center", textAlign:"center"}}>
<Button onClick={()=>{Scan();}} variant="contained" color="secondary" style={{marginTop:"40px", marginBottom:"40px"}}>Scan</Button>    
</div>
      
    <MUIDataTable
      title={'Roku Analyser'}
      data={tableData}
      columns={columns}
      options={{
        selectableRows: 'none', // Enable/disable row selection as needed
        // Add more MUIDataTable options as required
      }}
    />
    </Widget>
</Grid>
</Grid>
</Grid>
</>
  );
  
};

export default function AppAnalyserBeta() {
  
  const [datatableData,setdatatableData] = useState([]);
  const [rokutableData,setrokutableData] = useState([]);

  const useStyles = makeStyles(theme => ({
    tableOverflow: {
      overflow: 'auto'
    }
  }))
  var classes = useStyles();
  var elemento;
  var element;
  var elements;
  const [showvisual, setshowvisual] = useState(true);
  const [blocksensitive,setblocksensitive] = useState(true);
  const [blockuttility, setblockuttility] = useState(true);
  const [blockdeveloper,setblockdeveloper] = useState(true);
  const [appadscheck,setappadscheck] = useState(true);
  const [appadsblock,setappadsblock] = useState(false);
  const [loader, setloader] = useState(false);
  const [minreviews,setminreviews] = useState(10);
  const [minrating,setminrating] = useState(4);
  const [indicator2,setIndicator2] = useState();
  const [progressbar,setProgressbar] = useState(0);
    const id_check = useRef(null)
    const id_result = useRef(null)
    const id_notapproved = useRef(null)
    const id_approved = useRef(null)
    const id_notfound = useRef(null)
    const id_manual = useRef(null)
    const id_Blockkeywords = useRef(null)
    const id_Appadstxt = useRef(null)
    const id_BlockDeveloper = useRef(null)
    const id_Blockuttility = useRef(null)
    const id_showresults = useRef(null)
    const id_checkresults = useRef(null)
    const id_Appadstxtblock = useRef(null)
    const id_image = useRef(null)
    const id_indicator = useRef(null)
    const id_downloadcsv = useRef(null)
    const id_tocheck = useRef(null)
    const tableRef = useRef();
    const rokuRef = useRef();
    const RokuScan = useRef();
    const resultRoku = useRef();
    const [isDisabled, setisDisabled] = useState(false);
    const [lookups, setlookups] = useState();
    const [rokulookups, setrokulookups] = useState();

    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (event, newValue) => {
      setminreviews(newValue);
      console.log(newValue)
    };
    var mydata;
    var utc;
    var mydata2;
    var adt;
    var App = [{
      "BundleId": "",
      "ImageUrl": "",
      "Name": "",
      "ShopURL": "",
      "DeveloperPage": "",
      "AverageRating": "",
      "ReviewCount": "",
      "PrimaryGenre": "",
      "isHaving100Reviews": "",
      "Score0": "",
      "ScoreBetween1to3": "",
      "ScoreBetween3to4": "",
      "ScoreAbove4": "",
      "IsUttility": "",
      "AppAds": "",
      "Result": ""

    }];


    /*
    var dupa;
    useEffect(() => {
      const interval = setInterval(() => {
        fetch('https://mobileappscrapper.herokuapp.com/getdb/AppAnalyser2')
        .then(response => response.json())
        .then(data => setdata(data));
        console.log(lookups)
        fetch('https://mobileappscrapper.herokuapp.com/getdb/RokuAnalyser')
        .then(response => response.json())
        .then(data => setdata2(data));
      
      }, 30000);
      return () => clearInterval(interval);
    }, []);
    useEffect(() => {
      fetch('https://mobileappscrapper.herokuapp.com/getdb/AppAnalyser2')
      .then(response => response.json())
      .then(data => setdata(data));

    })
    useEffect(() => {
      fetch('https://mobileappscrapper.herokuapp.com/getdb/RokuAnalyser')
      .then(response => response.json())
      .then(data => setdata2(data));

    })
    
    function setdata(data)
    {
      setlookups(data);
    }
    function setdata2(data)
    {
      setrokulookups(data);
    }
 */
    var Apps;
    var Apps_split;
    var Apps_checked = [];
    var a = 0;
    var blockkeywords;
   var Keywords = [
      "bdsm",
      "erotic",
      "erotik",
      "fuck",
      "hentai",
      "lesbian",
      "naughty",
      "porn",
      "pussy",
      "rape",
      "xtube",
      "locker",
      "launcher",
      "wallpaper",
      "flashlight",
      "calc",
      "bang",
      "bdsm",
      "erotic",
      "erotik",
      "fuck",
      "hentai",
      "lesbian",
      "naughty",
      "nude",
      "porn",
      "pussy",
      "rape",
      "xtube",
      "locker",
      "launcher",
      "wallpaper",
      "flashlight",
      "calc",
     "lockscreen",
     "launcher",
     "wallpaper",
     "wallpapers",
     "screensaver",
     "photo gallery",
     "ringtones",
     "ringtone",
     "vpn",
     "calculator",
     "widget",
     "sticker",
     "keyboard",
     "cleaner",
     "emoji",
     "bitmoji",
     "alarm clock",
     "clock",
     "adblock",
     "theme",
     "themes",
     "remote",
     "battery booster",
     "battery",
     "booster"
     
    ];
    var data;
    var rating = 0;
    var isGoogle = 0;
    var numberofratings = 0;
    var checked = 1;
    var finished = 0;
    var length2;

    var utilities;
    var file;
    var showresults = 0;
    var appadstxtcheck;
    var utilitycheck;
    var appadsblockcheck;
    var blockdevpage;
    var notapproved;
    var approved;
    var notfound;
    var manual;
    var indicator;
    var result;
    var finishAjax=1;
    var sensitivename;
    
    var reqArr= [];
    var datatable = [];
    var RokuApp = 
[{
BundleName: "",
BundleId: "",
DeveloperUrl: "",
AverageRating: "",
Numberofratings: ""
}]
var doc;
function getItemProp3(metaName){  let metas4 = doc.getElementsByTagName('h1');  for (let i = 0; i < metas4.length; i++) {    if (metas4[i].getAttribute('itemprop') === metaName) {      return metas4[i].innerHTML;    }  }  return '';}
var left;
var numberOfApps;
var urltable = [];


    function SkanujRoku()
    {
      /*
      fetch('https://mobileappscrapper.herokuapp.com/setdb/RokuAnalyser')
      .then(response => response.json())
      .then(data => console.log(data));
      */
      toast.dismiss("roku_completed"); 
      Apps = RokuScan.current.value;
      Apps_split = Apps.split(/\n|,/g);
      for(let x in Apps_split)
      {
        if(!Apps_split[x].startsWith("https://"))
        {
          Apps_split.splice(x, 1);
        }
      }
      console.log(Apps_split)
      left = 1/Apps_split.length;
      numberOfApps = 1;
      console.log(left);
      toast.info('Processing... Please wait for scan to finish!' ,{
        autoclose: false,
        toastId: "roku",
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        });
        toast.update("roku", {            progress:numberOfApps         })
      reqArr= [];
datatable = [];

var error = 0
var z = 0;

for(let apps in Apps_split)
{
  
  reqArr.push({url:"https://mobileappscrapper.herokuapp.com/js/" + Apps_split[apps]});
  
}

  makeRequest();

function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function makeRequest(){
  if(reqArr.length==0) 
  {
    
    Checkresults();
    
    toast.success('Processing completed! Check results', {
      position: "top-center",
      toastId: "roku_completed",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      
      });
    return;//stop recursion
  }


    var obj=reqArr.shift();
		console.log(datatable)
  



  $.ajax({
    url: obj.url,
  })
  
  .done(function( data ) {
    doc = new DOMParser().parseFromString(data, "text/html")
    if(data == "<html><head></head><body></body></html>" || data == "" || data.startsWith("<html lang='en'><head><meta charset='utf-8'><meta http-equiv='X-UA-Compatible' content='IE=edge'><meta name='fragment' content='!'><meta name='viewport' content='width=device-width, height=device-height, initial-scale=1, minimum-scale=1'><title>Roku Channel Store</title>") || getItemProp3('name') == undefined  /* || getMeta('appstore:bundle_id') == 267229 */ )
    {
      
    console.log(data)
    reqArr.push(obj)
    }
    else
    {
      console.log(data)
    datatable.push(data)
    urltable.push(obj.url.replace("https://mobileappscrapper.herokuapp.com/js/", ""))
    numberOfApps = numberOfApps - left;
    console.log(numberOfApps)
    toast.update("roku", {            progress:numberOfApps         })
    if(numberOfApps <= 0.001)
    {
      toast.dismiss("roku"); 
    }
    }
    
    console.log(reqArr);
    sleep(3000).then(()=> {return makeRequest();})
  })
  .fail(function () {
  	console.log(obj);
    datatable.push();
    sleep(3000).then(()=> {return makeRequest();})
    
  })
}

    }
var bundlename;
var ApprovalStatus = "";
var DevpageAppAdsTxt = 0;
var splitstring = [];
var works = 0;
function Checkresults()
{
  console.log(urltable)
  setrokutableData([]);
  console.log(datatable)
  
  function getMeta(metaName){  let metas = doc.getElementsByTagName('meta');  for (let i = 0; i < metas.length; i++) {    if (metas[i].getAttribute('name') === metaName) {      return metas[i].getAttribute('content');    }  }  return '';}
  function getMetaProp(metaName){  let metas2 = doc.getElementsByTagName('meta');  for (let i = 0; i < metas2.length; i++) {    if (metas2[i].getAttribute('itemprop') === metaName) {      return metas2[i].getAttribute('content');    }  }  return '';}
  function getItemProp(metaName){  let metas3 = doc.getElementsByTagName('small');  for (let i = 0; i < metas3.length; i++) {    if (metas3[i].getAttribute('itemprop') === metaName) {      return metas3[i].innerText;    }  }  return '';}
  function getSpanProp(metaName){  let metas8 = doc.getElementsByTagName('span');  for (let i = 0; i < metas8.length; i++) {    if (metas8[i].getAttribute('itemprop') === metaName) {      return metas8[i].innerText;    }  }  return '';}
  function getItemProp2(metaName){  let metas5 = doc.getElementsByTagName('small');  for (let i = 0; i < metas5.length; i++) {    if (metas5[i].getAttribute('class') === metaName) {      return metas5[i].innerText;    }  }  return '';}
  function getItemProp4(metaName){  let metas6 = doc.getElementsByTagName('p');  for (let i = 0; i < metas6.length; i++) {    if (metas6[i].getAttribute('class') === metaName) {      return metas6[i].innerText;    }  }  return '';}
  function getItemProp5(metaName){  let metas7 = doc.getElementsByTagName('i');  for (let i = 0; i < metas7.length; i++) {    if (metas7[i].getAttribute('class') === metaName) {      return metas7[i].innerHTML    }  }  return '';}
    for(let elems in datatable)
    {
      var MinAvgRating = 0;
      var MinReviewCount = 0;
      var HasDeveloperPage = 0;
      var HasPrivacyPolicy = 0;
      var ApprovalStatus = "";
      var ContainsSensitiveCategory = 0;
      var DevelopedBy = "";
      var PrivacyPolicy2 = "";
      var HasDeveloperPageAdsTxt = 0;
      var checksdone = 0;
      doc = new DOMParser().parseFromString(datatable[elems], "text/html")
      
      console.log(RokuApp);
      console.log(getSpanProp('averageRating'));

      try
      {
        DevelopedBy = doc.querySelector("#read-more > p:nth-child(2) > i:nth-child(3)").innerText;
      }
      catch
      {
        DevelopedBy = "";
      }
      try
      {
        PrivacyPolicy2 = doc.querySelector("#read-more > p:nth-child(2) > i:nth-child(9) > a").href;
      }
      catch
      {
        PrivacyPolicy2 = "";
      }
      try
      {

          splitstring[1] = getSpanProp('averageRating');
          var avg = getItemProp('starRating');
          console.log(avg);
          
          if(avg.includes("."))
          {
            var avg2 = avg.substring(3);
            var avg3 = avg2.replace(" ratings", "");
            splitstring[0] = avg3;
          }
          else
          {
            var avg2 = avg.substring(1);
            var avg3 = avg2.replace(" ratings", "");
            splitstring[0] = avg3;
          }
          
          //getItemProp('starRating').split( /(?<=^(?:.{3})+)(?!$)/ );
      
        
        
        console.log(avg);
      }
      catch
      {
        splitstring[0] = 'There was an error with processing';
        splitstring[1] = 'There was an error with processing';
      }
      try
      {
        var category = getItemProp4('categories');
        
        var category2 = category.replace('Categories: ', '');
        var category3 = category2.replace('Categorías:', '');
        if(category3.includes("Screensavers"))
        {
          ContainsSensitiveCategory = 1;
        }
        else
        {
          ContainsSensitiveCategory = 0;
        }
      }
      catch
      {
        var category3 = 'There was an error with processing';
      }
      try
      {
        var privacy = new DOMParser().parseFromString(getItemProp5('privacy-policy'), "text/html")
        console.log(privacy)
      }
      catch
      {
        var pp = 0
        while(getItemProp5('privacy-policy') == "" || getItemProp5('privacy-policy') == undefined)
        {
          pp++;
          privacy = getItemProp5('privacy-policy')

          if(privacy != "" || privacy != undefined)
          {
            break;
          }
          if(pp == 200)
          {
            privacy = "None OR there was an error with processing";
            break;
          }
        }
        
      }
      try
      {
        var privacy2 = privacy.querySelector("body > a").href;
      }
      catch
      {
        var privacy2 = 'None OR there was an error with processing';
      }
      
        var is = 0;
        bundlename = "";
        try
      {
        var ShopURL = getMetaProp('url');
      }
      catch
      {
        var ShopURL = "There was an error with processing"
      }
        while(bundlename == "" || bundlename == undefined)
        {
          is++;
          bundlename = getItemProp3('name');

          if(is == 100)
          {
            bundlename = "Not found"
            ShopURL = urltable[elems];
            break;
          }
          
          
        }
        
      

        
      
      try
      {
        var bundleid = getMeta('appstore:bundle_id');
      }
      catch
      {
        var bundleid = "None OR there was an error with processing"
      }
      

        var DeveloperUrl = getMeta('appstore:developer_url');
        var di = 0;
        if(DeveloperUrl == "" || DeveloperUrl == undefined)
        {
          while(getMeta('appstore:developer_url') == undefined || getMeta('appstore:developer_url') == "")
          {
            di++
            var DeveloperUrl = getMeta('appstore:developer_url');
            if(di == 20)
            {
              DeveloperUrl = "None";
              break;
            }
          }
        }


        let parsed = parseFloat(splitstring[1]);
        if(parsed < 3 || parsed == undefined || parsed == "" || isNaN(parsed))
        {
          MinAvgRating = 0;
        }
        else
        {
          console.log("Wszystko działa tutaj!")
          MinAvgRating = 1;
        }

        let parsed2 = parseFloat(splitstring[0]);
        if(parsed2 < 10 || isNaN(parsed2))
        {
          MinReviewCount = 0;
        }
        else
        {
          MinReviewCount = 1;
        }
        if(DeveloperUrl != "None")
        {
          if(!DeveloperUrl.endsWith("gmail.com"))
          {
            HasDeveloperPage = 1;
          }
          else
          {
            HasDeveloperPage = 0;
          }
          HasDeveloperPage = 0;
        
        }

        if(privacy2 != "None OR there was an error with processing" || PrivacyPolicy2 != "")
        {
          HasPrivacyPolicy = 1;
        }
        else
        {
          HasPrivacyPolicy = 0;
        }
        if(MinAvgRating == 1 && MinReviewCount == 1)
        {
          if(HasDeveloperPage == 1 || HasPrivacyPolicy == 1)
          {
            ApprovalStatus = "Approved";
          }
          
        }
        if(MinAvgRating == 0 && MinReviewCount == 0 && HasDeveloperPage == 0 && HasPrivacyPolicy == 0)
        {
          ApprovalStatus = "Not Approved";
        }
        if(ContainsSensitiveCategory == 1)
        {
          ApprovalStatus = "Not Approved";
        }

        
      function replacer()
      {
        let repl1 = category3.replace("Categories: ", "");
        let repl2 = repl1.replace("Categorias: ", "");

        return repl2;
      }
      console.log(privacy2)
     

      RokuApp[elems] = {

        "BundleName":bundlename,
        "BundleId":bundleid,
        "ShopURL":ShopURL,
        "DevelopedBy":DevelopedBy,
        "DeveloperUrl":DeveloperUrl,
        "DevpageAppAdsTxt": DevpageAppAdsTxt,
        "PrivacyPolicyUrl":privacy2,
        "PrivacyPolicy2Url":PrivacyPolicy2,
        "AverageRating":splitstring[1],
        "Numberofratings":splitstring[0],
        "Categories":replacer(),
        "HasMinimumAvgRating": MinAvgRating,
        "HasMinimumReviewCount": MinReviewCount,
        "HasDeveloperPage": HasDeveloperPage,
        "HasPrivacyPolicy": HasPrivacyPolicy,
        "HasDeveloperPageAdsTxt": HasDeveloperPageAdsTxt,
        "ContainsSensitiveCategory": ContainsSensitiveCategory,
        "ApprovalStatus": ApprovalStatus
        
      }

              // 0 === $.active
            
    }
    var ixx = 0;
    for(let elx in RokuApp)
    {
      ixx++
      if(RokuApp[elx].DeveloperUrl != "Null")
      {
        let devurl = extractRootDomain(RokuApp[elx].DeveloperUrl)
        let adsurl = "http://mobileappscrapper.herokuapp.com/text/https://" + devurl + "/app-ads.txt";
        try
        {
          $.get(adsurl, function () {


          }
          
          )
          .done(function () {
            console.log("Działa")
          RokuApp[elx].HasDeveloperPageAdsTxt = 1;
          
          })
          .fail(function () {
            RokuApp[elx].HasDeveloperPageAdsTxt = 0;
          
          })
        }
        catch
        {
          console.log("Cos nie dziala")
        }
        /*
        ads = ""
        
        */
      }
        if(elx == RokuApp.length-1)
        {
          CheckresultsRoku();
        }
        
  
    }
  
   function CheckresultsRoku()
   {
    for(let elementsroku in RokuApp)
    {
      setrokutableData(rokutableData => [...rokutableData,[RokuApp[elementsroku].BundleId, RokuApp[elementsroku].BundleName, RokuApp[elementsroku].ShopURL, RokuApp[elementsroku].DevelopedBy, RokuApp[elementsroku].DeveloperUrl, RokuApp[elementsroku].PrivacyPolicyUrl, RokuApp[elementsroku].PrivacyPolicy2Url, RokuApp[elementsroku].Numberofratings, RokuApp[elementsroku].AverageRating, RokuApp[elementsroku].Categories, RokuApp[elementsroku].HasDeveloperPage, RokuApp[elementsroku].HasPrivacyPolicy, RokuApp[elementsroku].HasMinimumAvgRating, RokuApp[elementsroku].HasMinimumReviewCount, RokuApp[elementsroku].HasDeveloperPageAdsTxt, RokuApp[elementsroku].ContainsSensitiveCategory, RokuApp[elementsroku].ApprovalStatus]]);
  
    }
   }

 

  
}
    function Changer()
    {
      setloader(true);
    }



    function Changer2()
    {
      setloader(false);
    }
function Selectall()
{
  if(appadscheck == true && blockdeveloper == true && blocksensitive == true && blockuttility == true && showvisual == true && appadsblock == true)
  {
    setappadscheck(false);
    setblockdeveloper(false);
    setblocksensitive(false);
    setblockuttility(false);
    setshowvisual(false);
    setappadsblock(false);
  }
  else
  {
    setappadscheck(true);
    setblockdeveloper(true);
    setblocksensitive(true);
    setblockuttility(true);
    setshowvisual(true);
    setappadsblock(true);
  }

}

function showvisualhandler()
{
  if(showvisual == true)
  {
    setshowvisual(false);
  }
  else
  {
    setshowvisual(true);
  }
}
function blocksensitivehandler()
{
  if(blocksensitive == true)
  {
    setblocksensitive(false);
  }
  else
  {
    setblocksensitive(true);
  }
}
function blockdeveloperhandler()
{
  if(blockdeveloper == true)
  {
    setblockdeveloper(false);
  }
  else
  {
    setblockdeveloper(true);
  }
}
function appadscheckhandler()
{
  if(appadscheck == true)
  {
    setappadscheck(false);
  }
  else
  {
    setappadscheck(true);
  }
}
function appadsblockhandler()
{
  if(appadsblock == true)
  {
    setappadsblock(false);
  }
  else
  {
    setappadsblock(true);
  }
}
function blockuttilityhandler()
{
  if(blockuttility == true)
  {
    setblockuttility(false);
  }
  else
  {
    setblockuttility(true);
  }
}


    function Skanuj() {
      /*
      fetch('https://mobileappscrapper.herokuapp.com/setdb/AppAnalyser2')
      .then(response => response.json())
      .then(data => console.log(data));
      */
      Apps = [""];
      setdatatableData([])
      finished = 0;
      setisDisabled(true);
      finishAjax = 0;
      
      toast.info('Processing... Please wait for scan to finish!', {
        position: "top-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: progressbar,
        style: {backgroundColor:"#181818"}
        });
        
      id_result.current.innerHTML = "";
      

      if (id_Blockkeywords.current.checked == true) {
        blockkeywords = 1;
      } else {
        blockkeywords = 0;
      }
      if(id_Appadstxt.current.checked == true)
      {
        appadstxtcheck = 1;
      }
      else
      {
        appadstxtcheck = 0; 
      }
      if(id_Appadstxtblock.current.checked == true)
      {
        appadsblockcheck = 1;
      }
      else
      {
        appadsblockcheck = 0;
      }
      if (id_BlockDeveloper.current.checked == true) {
        blockdevpage = 1;
      } else {
        blockdevpage = 0;
      }
      if (id_Blockuttility.current.checked == true) {
        utilitycheck = 1;
      } else {
        utilitycheck = 0;
      }
      Apps_checked = [];
      Apps_split = [];
      notapproved = "";
      approved = "";
      notfound = "";
      result = "";


      
      rating = 0;
      numberofratings = 0;
      Apps = id_tocheck.current.value;
      Apps_split = Apps.split(/\n|,/g);
      setProgressbar(Apps_split.length)
    
      for (let i = 0; i < Apps_split.length; i++) {
          setProgressbar(progressbar - 1)
          console.log(progressbar)
        let j = i;

        if (isNaN(Apps_split[j])) {
          
            isGoogle = 1;
          
          
        } else {
          isGoogle = 0;
        }
        if (isGoogle == 0) {
            let text = "https://itunes.apple.com/lookup?id=" + Apps_split[j] + "&callback=?";

$.getJSON(text, function (data) {

  var tried = 0;
  if (data.resultCount == 0) {

  }
 else {


    let app_name = data.results[0].trackId;
    console.log(app_name);
    //App[j].BundleId = app_name;
    Apps_checked.push("" + app_name + "");
    try
    {
      var Bundleid = data.results[0].trackId;
    }
    catch
    {
      var Bundleid = "Not found";
    }
    try
    {
      var Imageurl = data.results[0].artworkUrl100;
    }
    catch
    {
      var Imageurl = "";
    }
    try
    {
      var Name = data.results[0].trackCensoredName;
    }
    catch
    {
      var Name = "Not found"
    }
    try
    {
      var Shopurl = data.results[0].trackViewUrl;
    }
    catch
    {
      var Shopurl = "Not found"
    }
    try
    {
      if(data.results[0].sellerUrl !== undefined)
      {
        var Developerpage = data.results[0].sellerUrl;
      }
      else
      {
        var Developerpage = "";
      }
      

    }
    catch
    {
      var Developerpage = "";
    }
    try
    {
      var Averagerating = data.results[0].averageUserRating;
    }
    catch
    {
      var Averagerating = "Not found"
    }
    try
    {
      var Reviewcount = data.results[0].userRatingCount;
    }
    catch
    {
     var Reviewcount = "Not found";
    }
    try
    {
      var Primarygenre = data.results[0].primaryGenreName;
    }
    catch
    {
      var Primarygenre = "Not found";
    }
    App[j] = {
      "BundleId": Bundleid,
      "ImageUrl": Imageurl,
      "Name": Name,
      "ShopURL": Shopurl,
      "DeveloperPage": Developerpage,
      "AverageRating": Averagerating,
      "ReviewCount": Reviewcount,
      "PrimaryGenre": Primarygenre,
      "HasDeveloperPage": "",
      "ContainKeyword": "",
      "AppAds": "",
      "Result":""
    };
    var DataToSend = 
    {
      'AverageRating': Averagerating,
      'ReviewCount': Reviewcount,
      'ImageURL': Imageurl,
      'Name': Name,
      'ShopURL': Shopurl,
      'DeveloperPage': Developerpage,
      'PrimaryGenre': Primarygenre

    }
    //bundledb?getset=set&bundle=testbundle&content={"AverageRating":0}
    //$.get("https://mobileappscrapper.herokuapp.com/bundledb?getset=set&bundle=" + Bundleid + "&content=" + JSON.stringify(DataToSend), function(data, status){
   //   
   // });
    let length = Keywords.length;
    /*while (length--) {
      if (App[j].Name.indexOf(Keywords[length]) != -1) {
        App[j].ContainKeyword = 1;
        console.log("passed");
      }
      else
      {
        App[j].ContainKeyword = 0;
        console.log("failed");
      }

    }
    */
  let appname = App[j].Name.split(" ");
  for (let elms in appname)
  {
    appname[elms].toLowerCase();
    if(appname[elms] == "a")
    {
      appname.splice(appname.indexOf("a"), 1);
    }
    if(appname[elms] == "A")
    {
      appname.splice(appname.indexOf("A"), 1);
    }
  }
  console.log(appname);
  for(elemento in appname)
  {
    appname[elemento] = appname[elemento].toLowerCase();
    //if(Keywords.some(e => e.includes(appname[elemento].toLowerCase())))
    if(Keywords.some(r => appname.includes(r)) == true)
   {

    App[j].ContainKeyword = 1;
    console.log("Contains!")
    break;
   
   }
   else
   {
    App[j].ContainKeyword = 0;
    console.log("NOT Contains!")
   }
  }
    


 

    if (App[j].ReviewCount >= 10) {
      App[j].isHaving100Reviews = 1;
    } else {
      App[j].isHaving100Reviews = 0;
    }
    if (App[j].AverageRating == 0) {
      App[j].Score0 = 1;
    } else {
      App[j].Score0 = 0;
    }
    if (App[j].AverageRating >= 3 && App[j].AverageRating < 4) {
      App[j].ScoreBetween3to4 = 1;
    } else {
      App[j].ScoreBetween3to4 = 0;
    }
    if (App[j].AverageRating >= 4) {
      App[j].ScoreAbove4 = 1;
    } else {
      App[j].ScoreAbove4 = 0;
    }
    if (App[j].AverageRating > 0 && App[j].AverageRating < 3) {
      App[j].ScoreBetween1to3 = 1;
    } else {
      App[j].ScoreBetween1to3 = 0;
    }
    if (App[j].PrimaryGenre == "Utilities" || App[j].PrimaryGenre == "Personalization") {
      App[j].IsUttility = 1;
    } else {
      App[j].IsUttility = 0;
    }
    if (App[j].DeveloperPage !== "None" || !App[j].DeveloperPage.endsWith("gmail.com")) 
    {
        App[j].HasDeveloperPage = 1;
        let removed = App[j].DeveloperPage;
        let removed2 = removed.replace("mailto:","");
        removed2.replace("mailto:","");
        let removed3 = getUrlParts(removed2);
      
        console.log(removed3.origin);
        removed3 = removed3.origin.replace("https://", "");
        removed3 = removed3.replace("http://", "");
        let ads = "https://mobileappscrapper.herokuapp.com/text/" + "http://" + removed3 + "/app-ads.txt";
        console.log(ads);
      
      
      
      if(appadstxtcheck == 1)
      {
        $.get(ads, function (data2) {


}

)
.done(function () {
App[j].AppAds = 1;
})
.fail(function () {
App[j].AppAds = 0
})
}
else
{
    App[j].AppAds = "No check performed"
}
      }
       

    //Manual validation check
  


  }


})






} 
        else {

          console.log("To Google!");
          let text = "https://mobileappscrapper.herokuapp.com/html2/" + Apps_split[j];
        
          $.get(text, function (data) {

var data2 = JSON.parse(data.apps)
//let doc = new DOMParser().parseFromString(data2[0].source, "text/html")
console.log(data.apps)
var app_name = Apps_split[j];
if(data2[0].name != "No name")
{
  console.log("data2[0].name")
  Apps_checked.push("" + app_name + "");
  
}

App[j] = {
  "BundleId": Apps_split[j],
  "ShopURL": "https://play.google.com/store/apps/details?id=" + Apps_split[j],
  "HasDeveloperPage": "",
  "ContainKeyword": "",
  "AppAds": "",
  "Result":""
};

var subratingcount;
App[j].isGoogle = 1;
if(data2[0].reviewCount != 0)
{
    subratingcount = data2[0].reviewCount;
    
}
else
{
    subratingcount = 0;
}
let apac = subratingcount.toString().replace(/ |,/g, '');
let test = apac.toString().replace(/,|,/g, '');
var test2 = parseInt(test);
if(isNaN(test2))
{
  App[j].ReviewCount = "No reviews"
}
else
{
  App[j].ReviewCount = test2;
}


if(data2[0].imageUrl != '')
{
    App[j].ImageUrl = data2[0].imageUrl;
}
else
{
    App[j].ImageUrl = "undefined";
}
if(data2[0].name)
{
    App[j].Name = data2[0].name;
  var length2 = Keywords.length;
  
  let appname = App[j].Name.split(" ");
  
 // for(let elements in appname)
 // {
   
   // 
   // {
    //  appname.splice(appname.indexOf("a"), 1); 
   // }
  //}
  for (let elms in appname)
  {
    appname[elms].toLowerCase();
    if(appname[elms] == "a")
    {
      appname.splice(appname.indexOf("a"), 1);
    }
    if(appname[elms] == "A")
    {
      appname.splice(appname.indexOf("A"), 1);
    }
  }
  for(elemento in appname)
  {
    //if(Keywords.some(e => e.includes(appname[elemento].toLowerCase())))
    appname[elemento] = appname[elemento].toLowerCase();
    if(Keywords.some(r => appname.includes(r)) == true)
   {

    App[j].ContainKeyword = 1;
    console.log("Contains!")
    break;
   
   }
   else
   {
    App[j].ContainKeyword = 0;
    console.log("NOT Contains!")
   }
  }
   
  console.log(appname);

}
else
{
    App[j].Name = "No name"
    var length2 = Keywords.length;
    let appname = App[j].Name.split(" ");
    for (let elms in appname)
    {
      appname[elms] = appname[elms].toLowerCase();
      if(appname[elms] == "a")
      {
        appname.splice(appname.indexOf("a"), 1);
      }
      if(appname[elms] == "A")
      {
        appname.splice(appname.indexOf("A"), 1);
      }
    }
    for(elemento in appname)
    {
      //if(Keywords.some(e => e.includes(appname[elemento].toLowerCase())))
      appname[elemento] = appname[elemento].toLowerCase();
      if(Keywords.some(r => appname.includes(r)) == true)
     {
  
      App[j].ContainKeyword = 1;
      console.log("Contains!")
      break;
     
     }
     else
     {
      App[j].ContainKeyword = 0;
      console.log("NOT Contains!")
     }
    }
}
if(data2[0].developerPage != 'No developer page')
{
 
  {
    App[j].DeveloperPage = data2[0].developerPage
    console.log(data2[0].developerPage)
  }

}
else
{
    App[j].DeveloperPage = 0
}
if(data2[0].rating != 0)
{
    App[j].AverageRating = data2[0].rating;
}
else
{
    App[j].AverageRating = 0;
}
if(data2[0].ratingES != 0)
{
  App[j].AverageRatingES = data2[0].ratingES;
}
else
{
  App[j].AverageRatingES = 0
}
if(data2[0].ratingDE != 0)
{
  App[j].AverageRatingDE = data2[0].ratingDE;
}
else
{
  App[j].AverageRatingDE = 0
}
if(data2[0].ratingFR != 0)
{
  App[j].AverageRatingFR = data2[0].ratingFR;
}
else
{
  App[j].AverageRatingFR = 0
}
if(data2[0].ratingPL != 0)
{
  App[j].AverageRatingPL = data2[0].ratingPL;
}
else
{
  App[j].AverageRatingPL = 0
}
if(data2[0].ratingIT != 0)
{
  App[j].AverageRatingIT = data2[0].ratingIT;
}
else
{
  App[j].AverageRatingIT = 0
}
if(data2[0].installs != 0)
{
  App[j].Installs = data2[0].installs.toString();
}
else
{
  App[j].Installs = 0;
}
if(data2[0].genre != '')
{
    App[j].PrimaryGenre= data2[0].genre;
}
else
{
    App[j].PrimaryGenre = "undefined";
}
if(isNaN(Averager(App[j].AverageRating, App[j].AverageRatingES, App[j].AverageRatingDE, App[j].AverageRatingFR, App[j].AverageRatingPL, App[j].AverageRatingIT)))
{
  App[j].AverageFromAllStores = 0
}
else
{
  App[j].AverageFromAllStores = Averager(App[j].AverageRating, App[j].AverageRatingES, App[j].AverageRatingDE, App[j].AverageRatingFR, App[j].AverageRatingPL, App[j].AverageRatingIT);
}


                if (App[j].ReviewCount >= minreviews) {
                  App[j].isHaving100Reviews = 1;
                } else {
                  App[j].isHaving100Reviews = 0;
                }
                if (App[j].AverageFromAllStores == 0) {
                  App[j].Score0 = 1;
                } else {
                  App[j].Score0 = 0;
                }
                if (App[j].AverageFromAllStores >= 3 && App[j].AverageFromAllStores < 4) {
                  App[j].ScoreBetween3to4 = 1;
                } else {
                  App[j].ScoreBetween3to4 = 0;
                }
                if (App[j].AverageFromAllStores >= 4) {
                  App[j].ScoreAbove4 = 1;
                } else {
                  App[j].ScoreAbove4 = 0;
                }
                if (App[j].AverageFromAllStores > 0 && App[j].AverageFromAllStores < 3) {
                  App[j].ScoreBetween1to3 = 1;
                } else {
                  App[j].ScoreBetween1to3 = 0;
                }
                if (App[j].PrimaryGenre == "Utilities" || App[j].PrimaryGenre == "Personalization") {
                  App[j].IsUttility = 1;
                } else {
                  App[j].IsUttility = 0;
                }
                if (App[j].DeveloperPage !== 0) {
                  if(App[j].DeveloperPage !== "")
                  {
                    if(App[j].DeveloperPage != null)
                    {
                      App[j].HasDeveloperPage = 1;
                    }
                    
                  }
                  
                  var removed = App[j].DeveloperPage;
                  var removed2 = removed.replace('mailto:','');
                  
                  var removed3 = getUrlParts(removed2);
                  removed3 = removed3.origin.replace("https://", "");
                  removed3 = removed3.replace("http://", "");
                  let ads = "https://mobileappscrapper.herokuapp.com/text/" + "http://" + removed3 + "/app-ads.txt";
                  console.log(ads);
                  if(appadstxtcheck == 1)
      {
        $.get(ads, function (data2) {


})
.done(function () {
App[j].AppAds = 1;
})
.fail(function () {
App[j].AppAds = 0
})
      }
      else
      {
          App[j].AppAds = "No check performed";
      }

                } else {
                  App[j].HasDeveloperPage = 0;
                }
                
                //Manual validation check





                //console.log(myApp[j].AverageRating);

              
                //"Rating: " + App[j].AverageRating;
                function setchecked() {
                  checked = 1;
                }

                function isInArray(value, array) {
                  return array.indexOf(value) > -1;
                }
              

            })
        }
        $(document).ajaxStop(function () {
          
          
          if(finishAjax == 0)
          {
            finishAjax = 1;
            approval()
            Push.create("App Analyser", {
              body: "Processing completed! Check results",
              requireInteraction: "true",
              onClick: function () {
                  window.focus();
                  this.close();
              }
          });
            toast.success('Processing completed! Check results', {
              position: "top-center",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {backgroundColor:"#fe5000"}
              });
              
            
            
          }
          else
          {

          }
  
  
});
finished = 1;
    } 

   
               if(finished == 1)
               {
                
               }
            }
            function Numbercheck()
            {
              let appsnumber = id_tocheck.current.value;
              let appsnumber_split = appsnumber.split(/\n|,/g);

              if(appsnumber_split.length > 200)
              {
                setIndicator2("Showing visual results one by one for this amount of apps(" + appsnumber_split.length + ")is not recommended! Please try to avoid using this feature in settings. I've done it for you already! :)");
                setshowvisual(false);
              }
              else
              {
                setIndicator2("Apps detected:" + appsnumber_split.length);
              }
            }
            function approval()
            {


             
               id_result.current.innerHTML = "";
                
      id_checkresults.current = true;
                check();

              if(id_showresults.current.checked == true)
              {
                for(element in App)
    {
        
        id_result.current.innerHTML += "<center><img id='image' src='" + App[element].ImageUrl +
                "' width='100'></center><center>"
        id_result.current.innerHTML += App[element].Name + "<br>";
        id_result.current.innerHTML += "BundleId: <a target='_blank' href='" + App[element]
                .ShopURL + "'>" + App[element].BundleId + "</a><br>";
                if (App[element].DeveloperPage != null) {
                id_result.current.innerHTML += "Developer page: <a target='_blank' href='" + App[element]
                  .DeveloperPage + "'>" + App[element].DeveloperPage + "</a><br>";
                  if(App[element].AppAds == 1)
                  {
                    id_result.current.innerHTML += "App-ads.txt detected!<br>"
                  }
              } else {
                id_result.current.innerHTML += "Developer page: not found<br>";
              }
              if (App[element].AverageRating < minrating) {
                id_result.current.innerHTML += "<b style='color:red'>Rating: " + App[element]
                  .AverageRating + "<br></b>";

              }
              if (App[element].AverageRating >= minrating) {
                id_result.current.innerHTML += "<b style='color:green'>Rating: " + App[element]
                  .AverageRating + "<br></b>";

              }
              if (App[element].ReviewCount < minreviews) {
                id_result.current.innerHTML += "<b style='color:red'>Number of user ratings: " +
                  App[element].ReviewCount + "<br></b>";

              }
              if (App[element].ReviewCount >= minreviews) {
                id_result.current.innerHTML += "<b style='color:green'>Number of user ratings: " +
                  App[element].ReviewCount + "<br></b>";

              }


              if (App[element].PrimaryGenre == "Utilities" || App[element].PrimaryGenre == "Personalization") {
                id_result.current.innerHTML += "<b style='color:orange'>Primary Category: " + App[element]
                  .PrimaryGenre + "<br>";
              } else {
                id_result.current.innerHTML += "Primary Category: " + App[element].PrimaryGenre + "<br>";
              }
              if (App[element].ContainKeyword == 1) {
                id_result.current.innerHTML +=
                  "<b style='color:red'>Contains sensitive keyword!<br><hr>";
                if(App[element].isGoogle == 1)
              {
                

              }
              } else {
                if(App[element].isGoogle == 1)
              {
                
              }
                
              }        

id_result.current.innerHTML += "<hr>";
                
        finished = 1;
    }
              }
              console.log(App)
                for(let elements2 in App)
                {
                  if(App[elements2].Name == "No name")
                  {
                    continue;
                  }
                  if (appadsblockcheck == 1 && App[elements2].AppAds == 0 && App[elements2].Name != null) {
                    App[elements2].Result = "No app-ads.txt";
                setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL, App[elements2].Result, "Not approved", getUrlParts(App[elements2]
                  .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                  elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                  .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT, App[elements2].AverageFromAllStores, App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                    continue;
            } 
                    if (blockdevpage == 1 && App[elements2].HasDeveloperPage == 0) {
                      App[elements2].Result = "No developer page";
                  setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,"None", App[elements2].Result, "Not approved", App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                    elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                    .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT, App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                      continue;
              } else {
                if (blockkeywords == 1 && App[elements2].ContainKeyword == 1) {
                  App[elements2].Result = "Contains sensitive keyword";
                    console.log(datatableData);
                      setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,App[elements2].Result, "Not approved", getUrlParts(App[elements2]
                        .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                        elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                        .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT,  App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                        console.log(datatableData)
                        continue;
                    } else {
                  if (utilitycheck == 1 && App[elements2].IsUttility == 1) {
                    App[elements2].Result = "App is uttility";

                      setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,App[elements2].Result, "Not approved", getUrlParts(App[elements2]
                        .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                        elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                        .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT,  App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                        continue;
                  } else {


                    if (App[elements2].Score0 == 1 || App[elements2].ScoreBetween3to4 == 1) {
                      App[elements2].Result = "Doesn't match app tresholds";

                        setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,App[elements2].Result, "Manual check", getUrlParts(App[elements2]
                          .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                          elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                          .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT,  App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                          continue;
                      }
                      if (App[elements2].isHaving100Reviews == 0 && App[elements2].ScoreAbove4 == 1) {
                        App[elements2].Result = "Doesn't match app tresholds";
  
                          setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,App[elements2].Result, "Manual check", getUrlParts(App[elements2]
                            .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                            elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                            .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT,  App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                            continue;
                        }

                    //Automatic approval validation check
                    if (App[elements2].isHaving100Reviews == 1 && App[elements2].ScoreAbove4 == 1) {
                      App[elements2].Result = "Approved";

                        setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,App[elements2].Result, "Approved", getUrlParts(App[elements2]
                          .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                          elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                          .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT, App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                          continue;
                    }
                    //Automatic rejection validation check

                    if (App[elements2].isHaving100Reviews == 1 && App[elements2].ScoreBetween1to3) {
                      App[elements2].Result = "Doesn't match app tresholds";

                        setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,App[elements2].Result, "Not approved", getUrlParts(App[elements2]
                          .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                          elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                          .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT,  App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                          continue;
                    }
                    if (App[elements2].isHaving100Reviews == 0 && App[elements2].ScoreBetween1to3) {
                      App[elements2].Result = "Doesn't match app tresholds";
                      
                        setdatatableData(datatableData => [...datatableData,[<Avatar variant="rounded" src={App[elements2].ImageUrl}></Avatar>,App[elements2].BundleId,App[elements2].Name,App[elements2].ShopURL,App[elements2].Result, "Not approved",getUrlParts(App[elements2]
                          .DeveloperPage).origin, App[elements2].HasDeveloperPage, App[elements2].isHaving100Reviews, App[elements2].Score0, App[
                          elements2].ScoreBetween1to3, App[elements2].ScoreBetween3to4, App[elements2].ScoreAbove4, App[elements2]
                          .IsUttility, App[elements2].Installs, App[elements2].AverageRating, App[elements2].AverageRatingES, App[elements2].AverageRatingDE, App[elements2].AverageRatingFR, App[elements2].AverageRatingPL, App[elements2].AverageRatingIT, App[elements2].AverageFromAllStores,App[elements2].ReviewCount, App[elements2].AppAds, App[elements2].ContainKeyword, ]])
                          continue;
                    }

                  }
                  
                }
                
              }
              
                }
                
            }

            function download_csv() {
                  id_checkresults.current = false;

                  let temp = id_approved.current.innerText;
                  let temp2 = id_notapproved.current.innerText;
                  let temp3 = id_notfound.current.innerText;
                  let temp4 = id_manual.current.innerText;
                  temp = temp.split((/\n|,/g));
                  temp2 = temp2.split((/\n|,/g));
                  temp3 = temp3.split((/\n|,/g));
                  temp4 = temp4.split((/\n|,/g));
                  temp.splice(-1, 1);
                  temp2.splice(-1, 1);
                  temp3.splice(-1, 1);
                  temp4.splice(-1, 1);
                  let file = temp;
                  let file2 = temp2;
                  let file3 = temp3;
                  let file4 = temp4;
                  var csv =
                    'BundleId;DeveloperPage;HasDeveloperPage;isHavingMinimalReviews;Score0;ScoreBetween1to3;ScoreBetween3to4;ScoreAbove4;IsUttility;AverageRating;ReviewCount;App-Ads.txt;ContainSensitiveKeyword;ReasonForResult;Result';
                  for (let element in file) {
                    csv += "\n";
                    csv += file[element] + ";Approved";
                  }
                  for (let element in file2) {
                    csv += "\n";
                    csv += file2[element] + ";Not Approved";
                  }
                  for (let element in file3) {
                    csv += "\n";
                    csv += file3[element] + ";Not Found";
                  }
                  for (let element in file4) {
                    csv += "\n";
                    csv += file4[element] + ";Manual Check";
                  }


                  var hiddenElement = document.createElement('a');
                  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
                  hiddenElement.target = '_blank';
                  hiddenElement.download = 'Export.csv';
                  hiddenElement.click();
                }
                var extractRootDomain = function (url) {
                    url.replace("mailto:","");
                    let root = getUrlParts(url)
                  return root.domainroot
                };
                function getUrlParts(fullyQualifiedUrl) {
    var url = {},
        tempProtocol
    var a = document.createElement('a')
    // if doesn't start with something like https:// it's not a url, but try to work around that
    if (fullyQualifiedUrl.indexOf('://') == -1) {
        tempProtocol = 'https://'
        a.href = tempProtocol + fullyQualifiedUrl
    } else
        a.href = fullyQualifiedUrl
    var parts = a.hostname.split('.')
    url.origin = tempProtocol ? "" : a.origin
    url.domain = a.hostname
    url.subdomain = parts[0]
    url.domainroot = ''
    url.domainpath = ''
    url.tld = '.' + parts[parts.length - 1]
    url.path = a.pathname.substring(1)
    url.query = a.search.substr(1)
    url.protocol = tempProtocol ? "" : a.protocol.substr(0, a.protocol.length - 1)
    url.port = tempProtocol ? "" : a.port ? a.port : a.protocol === 'http:' ? 80 : a.protocol === 'https:' ? 443 : a.port
    url.parts = parts
    url.segments = a.pathname === '/' ? [] : a.pathname.split('/').slice(1)
    url.params = url.query === '' ? [] : url.query.split('&')
    for (var j = 0; j < url.params.length; j++) {
        var param = url.params[j];
        var keyval = param.split('=')
        url.params[j] = {
            'key': keyval[0],
            'val': keyval[1]
        }
    }
    // domainroot
    if (parts.length > 2) {
        url.domainroot = parts[parts.length - 2] + '.' + parts[parts.length - 1];
        // check for country code top level domain
        if (parts[parts.length - 1].length == 2 && parts[parts.length - 1].length == 2)
            url.domainroot = parts[parts.length - 3] + '.' + url.domainroot;
    }
    // domainpath (domain+path without filenames) 
    if (url.segments.length > 0) {
        var lastSegment = url.segments[url.segments.length - 1]
        var endsWithFile = lastSegment.indexOf('.') != -1
        if (endsWithFile) {
            var fileSegment = url.path.indexOf(lastSegment)
            var pathNoFile = url.path.substr(0, fileSegment - 1)
            url.domainpath = url.domain
            if (pathNoFile)
                url.domainpath = url.domainpath + '/' + pathNoFile
        } else
            url.domainpath = url.domain + '/' + url.path
    } else
        url.domainpath = url.domain
    return url
}
                function check() {
                  
                  
                  for (let elementx in Apps_split) {
                    if (Apps_checked.includes(Apps_split[elementx]) == true) {

                    } else {

                      //id_notfound.current.innerHTML += Apps_split[elementx] + ";;;;;;;;;;;;;" + "<br>";
                      setdatatableData(datatableData => [...datatableData,["",Apps_split[elementx],"","","","","","","","","","","","","","","","","","","","","","","Not found"]]);
                      console.log("Działa!")
                    }
                  }
                
                }


        
    window.onload = function (){
      
    }
    
    function Averager(US,ES,DE,FR,PL,IT)
    {
      let Averages = [];
      let sum = 0;
      if(US > 0 && US != undefined)
      {
        Averages.push(parseFloat(US))
      }
      if(ES > 0 && ES != undefined)
      {
        Averages.push(parseFloat(ES))
      }
      if(DE > 0 && DE != undefined)
      {
        Averages.push(parseFloat(DE))
      }
      if(FR > 0 && FR != undefined)
      {
        Averages.push(parseFloat(FR))
      }
      if(PL > 0 && PL != undefined)
      {
        Averages.push(parseFloat(PL))
      }
      if(IT > 0 && IT != undefined)
      {
        Averages.push(parseFloat(IT))
      }
      console.log(Averages)
      for(let elements in Averages)
      {
        sum += Averages[elements]
      }
      console.log(sum);
      let average = sum / Averages.length;

      return parseFloat(average).toFixed(2);
    }
    
  return (
    <div >
        <PageTitle title="" />
       
      <Grid container spacing={4}>
        <Grid item xs={12}>

        <Grid item xs={12}>
          <Widget>
          
          <div style={{ textAlign: "center" }}>
      {/*<img  src={Iga3} width="40%" alt="Kasia"></img><br></br> */}
      
      </div>
          <p style={{textAlign:"center"}}>Insert naked bundle IDs like 417926051 or com.whatsapp separated by line break</p>
          <p align="center">Tool supports both Android and iOS bundles</p>
          <p align="center">Allow notifications to get information when your scanning is completed</p>
          
          <center>
          <TextField variant="outlined" onChange={Numbercheck} inputRef={id_tocheck} rows={10} fullwidth="true" multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
          
          </center>
          <br></br>
          <br></br>
          {indicator2}
        <div className="col-4">
        <Button aria-controls="simple-menu" variant="outlined" aria-haspopup="true" onClick={handleClick}>
        Settings
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        
        <MenuItem><Typography >Minimal reviews value is hardcoded as {minreviews}.</Typography></MenuItem>
        <MenuItem><Checkbox onChange={showvisualhandler} checked={showvisual} color="secondary" inputRef={id_showresults} type="checkbox" id="showresults" ></Checkbox>
                  <label htmlFor="showresults">Show visual results one by one</label></MenuItem>
        <MenuItem ><Checkbox onChange={blocksensitivehandler} checked={blocksensitive} color="secondary" inputRef={id_Blockkeywords} type="checkbox" id="Blockkeywords" ></Checkbox>
                  <label htmlFor="Blockkeywords">Block apps with sensitive keywords</label></MenuItem>
        <MenuItem> <Checkbox onChange={blockuttilityhandler} checked={blockuttility} color="secondary" inputRef={id_Blockuttility} type="checkbox" id="Blockuttility" ></Checkbox>
                  <label htmlFor="Blockuttility">Block apps with utility category</label></MenuItem>
        <MenuItem> <Checkbox onChange={blockdeveloperhandler} checked={blockdeveloper} color="secondary" inputRef={id_BlockDeveloper} type="checkbox" id="BlockDeveloper" ></Checkbox>
                  <label htmlFor="BlockDeveloper">Block apps without developer page</label></MenuItem>
        <MenuItem>                  <Checkbox onChange={appadscheckhandler} checked={appadscheck} color="secondary" inputRef={id_Appadstxt} type="checkbox" id="Appadstxt" ></Checkbox>
                  <label htmlFor="Appadstxt">App-ads.txt check (beta)</label></MenuItem>
        <MenuItem>                  <Checkbox onChange={appadsblockhandler} checked={appadsblock} color="secondary" inputRef={id_Appadstxtblock} type="checkbox" id="Appadstxtblock" ></Checkbox>
                  <label htmlFor="Appadstxtblock">Block apps without app-ads.txt (beta)</label></MenuItem>
        <MenuItem><Button variant="outlined" color="secondary" onClick={Selectall}>Select all</Button><br></br></MenuItem>
      </Menu>
    
          


                  <br></br>
                  <ToastContainer
                  position="top-right"
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  ></ToastContainer>
                  <div >
                  
                  {loader ?
                  <CircularProgress size={26}
                  /> : 
                  <center><Button ref={id_indicator} variant="contained" color="secondary" className=" btn btn-secondary width" id="indicator" onClick={()=>{Skanuj()}}>Scan</Button></center>
                  }
                  
                  
                  
                </div>

                
                  {/*  
<Button variant="contained" color="primary" disabled={isDisabled} ref={id_checkresults} style={{}} className='btn btn-secondary width' id="checkresults"  onClick={() => {approval()}}>2.Check results</Button>
                  */}
        
              </div>
              <div  id="result"></div></Widget>
</Grid></Grid></Grid>
        <PageTitle title="" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            ref={tableRef}
            title="Results"
            data={datatableData}
            columns={[{  name: "Icon",
            label: "Icon",
            options: {download: false}
            },"BundleId", "BundleName", "ShopURL", "ReasonForResult", "Result", "DeveloperPage", "HasDeveloperPage", "isHavingMinReviews", "Score0", "ScoreBetween1to3", "ScoreBetween3and4", "ScoreAbove4", "IsUttility", "Installs", "AverageRatingUS", "AverageRatingES", "AverageRatingDE", "AverageRatingFR", "AverageRatingPL", "AverageRatingIT", "AverageFromAllStores", "ReviewCount", "App-ads.txt", "ContainsSensitiveKeyword", ]}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'AppAnalyser_' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please scan the apps first",
                }},
            }}
            
          />
        </Grid>
        
      </Grid>
      

          <center><img ref={id_image} id="image" width="100" /></center><center>
        <div className="result" style={{textAlign:"center",alignContent:"center", width:"30%"}} ref={id_result} id="result"></div></center>

            <Grid>
            </Grid>

            {/*
            <PageTitle title="Roku Analyser" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
        <Widget upperTitle noBodyPadding>
        <p style={{textAlign:"center"}}>Insert Roku channel link like https://channelstore.roku.com/en-ca/details/9d058b99b88200c654d993848d5f1a00/free-grindhouse-flix-tonight separated by line break</p>
          <p align="center">Tool supports only Roku bundles</p>
         
        <center><TextField variant="outlined" inputRef={RokuScan} rows={10} fullwidth="true" multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"95%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
        </center><br></br><br></br>
        <p></p>
        <br></br><br></br>
        <center><Button variant="contained" color="primary" className=" btn btn-secondary width" id="indicator" onClick={()=>{SkanujRoku()}}>Scan</Button>
        </center>
        <br></br><br></br>
          </Widget>
            </Grid>
            </Grid>
            <PageTitle title="" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            ref={rokuRef}
            title="Results"
            data={rokutableData}
            columns={["BundleId", "BundleName", "ShopURL", "DevelopedBy", "DeveloperPage", "PrivacyPolicyUrl", "PrivacyPolicy2Url", "ReviewCount", "AverageReview", "Categories", "HasDeveloperPage", "HasPrivacyPolicy", "HasMinRating", "HasMinReviewCount", "HasDeveloperPageAdsTxt", "ContainsSensitiveCategory", "ApprovalStatus"]}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'RokuAnalyser_' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please scan the apps first",
                }},
            }}
            
          />
            </Grid>
            </Grid>
          */}
            
        <RokuChecker></RokuChecker>
        <LGAnalyser></LGAnalyser>
     
 
    </div>
  );
  }
