import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
export default function JsonExporter() {

  const tableRef = useRef();
  const json = useRef()
  const jsonik = useRef();
  const wynik = useRef()
  const [datatableData,setdatatableData] = useState([]);
  const [datatableData2,setdatatableData2] = useState([]);
  const [datatableData3,setdatatableData3] = useState([]);
  const [datatableData4,setdatatableData4] = useState([]);
  var JsonLines = [];
  var Errors = [];
  var text;
  var names = [];
  var names2 = [];
  var names3 = [];
  var domains = [];
  var countdomains = {};
  var publisher = [];
  var counttypes = {};
  var intermediary = [];
  var idcount = [];
  var countids = {};
  var both = [];
  var count = {};
  var duplicate = [{}];
  var duplicatedomains = [{}];
  var duplicateids = [{}];
  var jsonstrict;

  function Check()
  {
    try
    {
    jsonstrict = json.current.value;
    console.log(JSON.parse(jsonstrict))
    Action()
    }
    catch
    {
      let error = "It is not valid Json file!";
      wynik.current.innerHTML = "<b style='color:red''>" + error + "</b>";
    }
  }

    function validatejson()
    {
      var RawJson = jsonik.current.value;
      var RemovedSellers = RawJson.split('sellers": [').pop();
      var SemiLines = RemovedSellers.replaceAll("\n      ", "")
      SemiLines = SemiLines.replaceAll("    ","")
      var SemiSemiLines = SemiLines.replaceAll("\n", "")
      var Lines = SemiSemiLines.split("},");
      try
      {
        JSON.parse(RawJson)
      }
      catch(e)
      {
        console.log("Sellers.json could not be parsed. Consider fixing errors and try again!")
      }
      //var Lines2 = Lines.split(/{|}/);
      
      
      for(let element in Lines)
      {
        try{
          if(Lines[element].endsWith("]"))
          {
            Lines[element] = Lines[element].slice(0,-1);
          }
          if(!Lines[element].endsWith("}"))
          {
            Lines[element] += "}"
          }
          if(Lines[element].endsWith('"1.0"}'))
          {
            Lines[element] = Lines[element].split(']')[0]
          }
          if(Lines[element].endsWith('}  ]}'))
          {
            Lines[element] = Lines[element].split(']')[0]
          }
          Lines[element] = JSON.parse(Lines[element])
          JsonLines[element] = Lines[element]
        }
        catch(e)
        {
          console.log(e.message)
          Errors[element] = Lines[element] + " " + e.message;
        }
        for(let elements in JsonLines)
        {
          try
          {
            if(JsonLines[elements].name == "" || JsonLines[elements].name == undefined)
            {
              if(JsonLines[elements].is_confidential == 0)
              {
                Errors[elements] = JsonLines[elements].seller_id + " Seller_name is missing!"
              }
              else
              {

              }
              
            }
          }
          catch
          {
            try
            {
              if(JsonLines[elements].is_confidential == 0)
              {
                Errors[elements] = JsonLines[elements].seller_id + " Seller_name is missing!"
              }
              
            }
            catch
            {
              if(JsonLines[elements].is_confidential == 0)
              {
                Errors[elements] = JsonLines[elements].domain + " Seller_name is missing!"
              }
              
            }
            
          }
          try
          {
            if(JsonLines[elements].seller_type.toLowerCase() == "intermediary" || JsonLines[elements].seller_type.toLowerCase() == "both" || JsonLines[elements].seller_type.toLowerCase() == "publisher")
            {
              
            }
            else
            {
              try
              {
                Errors[elements] = JsonLines[elements].seller_id + " Seller type is invalid or empty!"
              }
              catch
              {
                Errors[elements] = JsonLines[elements].seller_name + " Seller type is invalid or empty!"
              }
              
            }
          }
          catch
          {
            Errors[elements] = JsonLines[elements].seller_id + " Seller type is invalid or empty!"
          }
          
          if(JsonLines[elements].is_confidential == 0)
          {
            try
            {
              if(JsonLines[elements].domain == undefined || JsonLines[elements].domain == "")
              {
                Errors[elements] = "Seller_ID: " + JsonLines[elements].seller_id + " Sellers.json is not confidential but domain is missing!"
              }
              
            }
            catch
            {
              Errors[elements] = "Sellers.json is not confidential but domain is missing!"
            }
            
          }
          try
          {
            if(JsonLines[elements].seller_id == "" || JsonLines[elements].seller_id == undefined)
            {
              Errors[elements] = JsonLines[elements].name + " Seller_id is missing!"
            }
          }
          catch
          {
            try
            {
              Errors[elements] = JsonLines[elements].name + " Seller_id is missing!"
            }
            catch
            {
              Errors[elements] = JsonLines[elements].domain + " Seller_id is missing!"
            }
            
          }
        }
        
        
      }
      
      console.log(JsonLines);

      console.log(Errors.filter(n => n));
    }
    function Action()
    {

      wynik.current.innerHTML = "";
      setdatatableData('');
      setdatatableData2('');
      setdatatableData3('');
      setdatatableData4('');

      jsonstrict = json.current.value;
      
      
      for(let a = 0; a<JSON.parse(jsonstrict).sellers.length; a++)
      {
        names.push(JSON.parse(jsonstrict).sellers[a].name);
        domains.push(JSON.parse(jsonstrict).sellers[a].domain);
        publisher.push(JSON.parse(jsonstrict).sellers[a].seller_type);
        idcount.push(JSON.parse(jsonstrict).sellers[a].seller_id)
      }
      
      function checkIfDuplicateExists(arr) {
        return new Set(arr).size !== arr.length
    }
    names.forEach((i) => { count[i] = ++count[i]|| 1});
    domains.forEach((i) => { countdomains[i] = ++countdomains[i]|| 1});
    publisher.forEach((i) => { counttypes[i] = ++counttypes[i]|| 1});
    idcount.forEach((i) => { countids[i] = ++countids[i]|| 1});
    console.log(countids);
    var i = 0;
    for (const [key, value] of Object.entries(count)) {
      
       let a = key;
       let b = value;
      duplicate[i] = {
        "id":i,
        "Company":key,
        "Instances":value
      }
      i++;
    }
    var k = 0;
    for (const [key, value] of Object.entries(countdomains)) {
      
       let a = key;
       let b = value;
      duplicatedomains[k] = {
        "id":k,
        "Domain":key,
        "Instances":value
      }
      k++;
    }
    var j = 0;
    for (const [key, value] of Object.entries(countids)) {
      
      let a = key;
      let b = value;
     duplicateids[j] = {
       "id":key,
       "Instances":value
     }
     j++;
   }

  
    for(let element in duplicateids)
    {
      setdatatableData4(datatableData4 => [...datatableData4,[duplicateids[element].id, duplicateids[element].Instances]])
    }
    for(let element in duplicate)
    {
      setdatatableData(datatableData => [...datatableData,[duplicate[element].Company, duplicate[element].Instances]])
    }
    for(let element in duplicatedomains)
    {
      setdatatableData2(datatableData2 => [...datatableData2,[duplicatedomains[element].Domain, duplicatedomains[element].Instances]])
    }

      setdatatableData3(datatableData3 => [...datatableData3,["PUBLISHER", counttypes.PUBLISHER]]);
      setdatatableData3(datatableData3 => [...datatableData3,["INTERMEDIARY", counttypes.INTERMEDIARY]]);
      setdatatableData3(datatableData3 => [...datatableData3,["BOTH", counttypes.BOTH]]);
    
        console.log(duplicate);
    //wynik.current.innerText = text;

      //$.get("https://mobileappscrapper.herokuapp.com/text/" + jsonstrict, function (data) {
        
  }


    
    function ExportSellers()
    {
      wynik.current.innerHTML = "";
      var jsonstrict = json.current.value;
      var jsonstrict2 = jsonstrict.replace(/&#39;/g, "");
      var jsonstrict3 = jsonstrict2.replace(/#/g, "");
      try
      {
      var json2 = JSON.parse(jsonstrict3)
      //text = objectsToCSV(json2.sellers);
      var newObj = []
      for(let element in json2.sellers)
      {
        newObj[element] = {
          "seller_id":json2.sellers[element].seller_id,
          "is_confidential":json2.sellers[element].is_confidential,
          "seller_type":json2.sellers[element].seller_type,
          "is_passthrough":json2.sellers[element].is_passthrough,
          "name":json2.sellers[element].name,
          "domain":json2.sellers[element].domain,
          "comment":json2.sellers[element].comment,
          "ext": json2.sellers[element].ext
        }
      }
      text = objectsToCSV(newObj);
      //wynik.current.innerText = text;
       download_txt();
      }
      catch
      {
        let error = "It is not valid Json file!";
        wynik.current.innerHTML = "<b style='color:red''>" + error + "</b>";
      }
    
    }
    function objectsToCSV(arr) {
      const array = [Object.keys(arr[0])].concat(arr)
      return array.map(row => {
          return Object.values(row).map(value => {
              return typeof value === 'string' ? JSON.stringify(value) : value
          }).toString()
      }).join('\n')
  }
  function download_txt() {
    var textToSave = text;
  var hiddenElement = document.createElement('a');

  hiddenElement.href = 'data:attachment/text,' + encodeURI(textToSave);
  hiddenElement.target = '_blank';
  hiddenElement.download = 'Json.csv';
  hiddenElement.click();
}
  
return (
    <div >
      <br></br>
       <TextField variant="outlined" multiline inputRef={json} rows={10} placeholder="Insert valid SELLERS.JSON file text" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
       
    <br></br>
    <Button variant="contained" color="primary" onClick={Check}>Check Sellers.json</Button>
    <Button variant="contained" color="primary" onClick={ExportSellers}>Export Sellers</Button>

    <p> </p>
    <div ref={wynik}>

    </div>
    <MUIDataTable
            
            title="Seller type"
            ref={tableRef}
            columns={["Type", {name:"Instances",label:"Instances",options: {
              filter: true,
              sort: true,
              sortDirection: 'desc',
          }}]}
            data={datatableData3}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'SellersJSON_SellerTypes' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              sort: true,
              sortDirection: 'desc',
              textLabels: {
                body: {
                  noMatch: "Please check sellers.json first",
                }},
            }}
            
          />
          <MUIDataTable
            
            title="Seller id"
            ref={tableRef}
            columns={["Id", {name:"Instances",label:"Instances",options: {
              filter: true,
              sort: true,
              sortDirection: 'desc',
          }}]}
            data={datatableData4}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'SellersJSON_SellerIds' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              sort: true,
              sortDirection: 'desc',
              textLabels: {
                body: {
                  noMatch: "Please check sellers.json first",
                }},
            }}
            
          />
    <MUIDataTable
            
            title="Company count"
            ref={tableRef}
            columns={["Company", {name:"Instances",label:"Instances",options: {
              filter: true,
              sort: true,
              sortDirection: 'desc',
          }}]}
            data={datatableData}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'SellersJson_CompanyCount' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              sort: true,
              sortDirection: 'desc',
              textLabels: {
                body: {
                  noMatch: "Please check sellers.json first",
                }},
            }}
            
          />
          <MUIDataTable
            
            title="Domain count"
            ref={tableRef}
            columns={[{name:"Domain", label:"Domain"}, {name:"Instances",label:"Instances",options: {
              filter: true,
              sort: true,
              sortDirection: 'desc',
          }}]}
            data={datatableData2}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'SellersJson_DomainCount' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please check sellers.json first",
                }},
            }}
            
          />
          <TextField variant="outlined" multiline inputRef={jsonik} rows={10} placeholder="Insert valid SELLERS.JSON file text" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
          <Button variant="contained" color="primary" onClick={validatejson}>Validate</Button>
    </div>
);
}