import React, { useState, useEffect } from 'react';
import { 
    Paper, 
    Typography, 
    List, 
    ListItem, 
    ListItemText, 
    Divider, 
    Grid,
    makeStyles,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    Button,
    TextField
} from '@material-ui/core';
import { 
    Bar, 
    BarChart,  
    LineChart, 
    Line, 
    PieChart, 
    Label, 
    Pie, 
    Tooltip, 
    Legend, 
    ResponsiveContainer, 
    CartesianGrid, 
    XAxis, 
    YAxis, 
    Cell 
} from 'recharts';
import { Check, Close, HelpOutline } from '@material-ui/icons';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'; // Import ArrowBackIos and ArrowForwardIos icons

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    infoBox: {
        marginBottom: theme.spacing(2),
        marginRight:"10px"
    },
    infoItem: {
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        display: 'inline-flex', // Use inline-flex to enable flexbox
        justifyContent: 'center', // Horizontally center the content
        alignItems: 'center', // Vertically center the content
        width: "150px",
        height: "150px",
        textAlign: "center",
        transition: 'transform 0.3s ease-in-out', // Add transition for smooth effect
        cursor: 'pointer', // Change cursor on hover
        '&:hover': {
            transform: 'scale(1.1)', // Enlarge on hover
        },
    },

    approved: {
        backgroundColor: '#4CAF50', // Green color
        color: '#fff', // White text
    },
    notApproved: {
        backgroundColor: '#F44336', // Red color
        color: '#fff', // White text
    },
    manualCheck: {
        backgroundColor: '#2196F3', // Blue color
        color: '#fff', // White text
    },
    autoApproved: {
        backgroundColor: '#4CAF50', // Green color
        color: '#fff', // White text
    },
    notAutoApproved: {
        backgroundColor: '#2196F3', // Blue color
        color: '#fff', // White text
    },
    monetized: {
        backgroundColor: '#4CAF50', // Green color
        color: '#fff', // White text
    },
    notMonetized: {
        backgroundColor: '#2196F3', // Blue color
        color: '#fff', // White text
    },
    blocked: {
        backgroundColor: '#F44336', // Red color
        color: '#fff', // White text
    },
    notBlocked: {
        backgroundColor: '#4CAF50', // Green color
        color: '#fff', // White text
    },
}));

const formatNumber = (num) => {
    if (num === undefined) return "N/A"; // Return "N/A" for undefined values

    let absNum = Math.abs(num); // Change from constant to variable

    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const divisor = 1000;

    let suffixIndex = 0;

    while (absNum >= divisor && suffixIndex < suffixes.length - 1) {
        absNum /= divisor;
        suffixIndex++;
    }

    let formattedNum = absNum.toFixed(2);

    // Remove trailing zeros
    formattedNum = formattedNum.replace(/\.?0+$/, "");

    return `${num < 0 ? "-" : ""}${formattedNum}${suffixes[suffixIndex]}`;
};


const Favicon = ({ domain }) => {
    const [faviconUrl, setFaviconUrl] = useState(null);
    const classes = useStyles();
    useEffect(() => {
        const fetchFavicon = async () => {
            try {
                // Attempt to fetch favicon from the website's root directory
                const response = await fetch(`https://${domain}/favicon.ico`);
                if (response.ok) {
                    const url = URL.createObjectURL(await response.blob());
                    setFaviconUrl(url);
                }
            } catch (error) {
                console.error('Error fetching favicon:', error);
            }
        };

        fetchFavicon();

        return () => {
            // Clean up the favicon URL to prevent memory leaks
            if (faviconUrl) {
                URL.revokeObjectURL(faviconUrl);
            }
        };
    }, [domain]);

    return (
        <Paper className={classes.paper}>
            {faviconUrl && <img src={faviconUrl} alt="Favicon" />}
            <Typography variant="h4" gutterBottom>{domain}</Typography>
        </Paper>
    );
};

const SQTA = () => {
    const classes = useStyles();
    const [domainQueue, setDomainQueue] = useState([]);
    const [domainInput, setDomainInput] = useState('');
        const [domainIndex, setDomainIndex] = useState(0);
        
    const [apiData, setApiData] = useState({
        "results": [
            {
                "approval_status": "approved",
                "authority": 93,
                "autoapproved": false,
                "blocked": "false",
                "comment": "Domain has a high score",
                "domain": "example.com",
                "monetized_by": 1,
                "moz": 93,
                "rv_data": {
                    "adTech": [
                        "NetRatings SiteCensus",
                        "ScoreCard Research Beacon",
                        "Sizmek",
                        "Facebook",
                        "Amazon Mobile Ads",
                        "Certifica Metric",
                        "DoubleVerify",
                        "Integral Ad Science",
                        "Amazon TAM",
                        "GDPR Enforcement Module",
                        "Branch Metrics"
                    ],
                    "averageRank": 175,
                    "bounceRate": 0.28736589649456784,
                    "contentCategory": null,
                    "contentCategoryCombined": {
                        "": null
                    },
                    "contentCategoryID": null,
                    "country": "United States of America",
                    "language": "",
                    "legacyCategory": "video games consoles and accessories",
                    "monthlyPageviews": "1011988696",
                    "pagesPerVisit": 4.154921223001891,
                    "pageviewsHistory": [
                        904919772,
                        632163554,
                        1102587251,
                        625124710,
                        1011988696,
                        871438048,
                        1072558953,
                        991911478,
                        820138153,
                        1212126852,
                        1062952469,
                        970227347,
                        1181176036,
                        1202627319,
                        643622573,
                        1043394317,
                        1104613869,
                        1073008693,
                        969214720,
                        1295878860,
                        0,
                        1119510836,
                        894132314,
                        742014462,
                        677814932,
                        910882888,
                        907284213,
                        1123429517,
                        983610597,
                        862284092,
                        842058941,
                        1075624320,
                        868156792,
                        1121600728,
                        1124134576,
                        1150339068,
                        1112293838,
                        1167092107,
                        1081383977,
                        1157803153,
                        991596388,
                        1015380878,
                        611400439
                    ],
                    "rank": "171",
                    "status": "found",
                    "timeOnSite": 569.0414267705821,
                    "trafficByCountry": [
                        {
                            "country": "United States of America",
                            "percentage": 0.2118261483207769
                        },
                        {
                            "country": "Germany",
                            "percentage": 0.07358706381193635
                        },
                        {
                            "country": "Russian Federation",
                            "percentage": 0.05154472928365221
                        },
                        {
                            "country": "France",
                            "percentage": 0.04951928213305007
                        },
                        {
                            "country": "Japan",
                            "percentage": 0.04437416405916083
                        }
                    ]
                },
                "score": 1.0,
                "traffic_data": {
                    "monthly_total_visits": {
                        "2023-08-01": 988564192.2119999,
                        "2023-09-01": 884426112.4499998,
                        "2023-10-01": 904334509.133,
                        "2023-11-01": 868600712.956,
                        "2023-12-01": 980637427.1500001,
                        "2024-01-01": 960488704.085,
                        "2024-02-01": 878291792.6879998,
                        "2024-03-01": 905221926.3569999
                    },
                    "percentages": {
                        "Direct": 89.67574469055822,
                        "Display Ads": 0.014031698303944781,
                        "Mail": 0.29515710026526093,
                        "Referrals": 1.1287819771773537,
                        "Search": 4.033218126622821,
                        "Social": 4.853066407072391
                    }
                }
            }
        ]
    });
    const [allData, setAllData] = useState([{
        "results": [
            {
                "approval_status": "approved",
                "authority": 93,
                "autoapproved": false,
                "blocked": "false",
                "comment": "Domain has a high score",
                "domain": "example.com",
                "monetized_by": 1,
                "moz": 93,
                "rv_data": {
                    "adTech": [
                        "NetRatings SiteCensus",
                        "ScoreCard Research Beacon",
                        "Sizmek",
                        "Facebook",
                        "Amazon Mobile Ads",
                        "Certifica Metric",
                        "DoubleVerify",
                        "Integral Ad Science",
                        "Amazon TAM",
                        "GDPR Enforcement Module",
                        "Branch Metrics"
                    ],
                    "averageRank": 175,
                    "bounceRate": 0.28736589649456784,
                    "contentCategory": null,
                    "contentCategoryCombined": {
                        "": null
                    },
                    "contentCategoryID": null,
                    "country": "United States of America",
                    "language": "",
                    "legacyCategory": "video games consoles and accessories",
                    "monthlyPageviews": "1011988696",
                    "pagesPerVisit": 4.154921223001891,
                    "pageviewsHistory": [
                        904919772,
                        632163554,
                        1102587251,
                        625124710,
                        1011988696,
                        871438048,
                        1072558953,
                        991911478,
                        820138153,
                        1212126852,
                        1062952469,
                        970227347,
                        1181176036,
                        1202627319,
                        643622573,
                        1043394317,
                        1104613869,
                        1073008693,
                        969214720,
                        1295878860,
                        0,
                        1119510836,
                        894132314,
                        742014462,
                        677814932,
                        910882888,
                        907284213,
                        1123429517,
                        983610597,
                        862284092,
                        842058941,
                        1075624320,
                        868156792,
                        1121600728,
                        1124134576,
                        1150339068,
                        1112293838,
                        1167092107,
                        1081383977,
                        1157803153,
                        991596388,
                        1015380878,
                        611400439
                    ],
                    "rank": "171",
                    "status": "found",
                    "timeOnSite": 569.0414267705821,
                    "trafficByCountry": [
                        {
                            "country": "United States of America",
                            "percentage": 0.2118261483207769
                        },
                        {
                            "country": "Germany",
                            "percentage": 0.07358706381193635
                        },
                        {
                            "country": "Russian Federation",
                            "percentage": 0.05154472928365221
                        },
                        {
                            "country": "France",
                            "percentage": 0.04951928213305007
                        },
                        {
                            "country": "Japan",
                            "percentage": 0.04437416405916083
                        }
                    ]
                },
                "score": 1.0,
                "traffic_data": {
                    "monthly_total_visits": {
                        "2023-08-01": 988564192.2119999,
                        "2023-09-01": 884426112.4499998,
                        "2023-10-01": 904334509.133,
                        "2023-11-01": 868600712.956,
                        "2023-12-01": 980637427.1500001,
                        "2024-01-01": 960488704.085,
                        "2024-02-01": 878291792.6879998,
                        "2024-03-01": 905221926.3569999
                    },
                    "percentages": {
                        "Direct": 89.67574469055822,
                        "Display Ads": 0.014031698303944781,
                        "Mail": 0.29515710026526093,
                        "Referrals": 1.1287819771773537,
                        "Search": 4.033218126622821,
                        "Social": 4.853066407072391
                    }
                }
            }
        ]
    }]);
    // Function to update domain input
    const handleDomainInputChange = (event) => {
        setDomainInput(event.target.value);
    };
    const addToQueue = () => {
        if (domainInput.trim() !== '') {
            const domainsArray = domainInput.trim().split(/\r?\n/); // Split input by line breaks
            setDomainQueue(prevQueue => [...prevQueue, ...domainsArray]); // Add domains to the queue
            setDomainInput('');
        }
    };
    const removeFromQueue = (index) => {
        const newQueue = [...domainQueue];
        newQueue.splice(index, 1);
        setDomainQueue(newQueue);
    };


    const callApiWithAuth = async (domains) => {
        try {
            // Check if the queue is empty
            if (domains.length === 0) return;
    
            // Get the first domain from the queue
            const domain = domains[0];
    
            // Make the API call with the domain
            const response = await fetch('https://mobileappscrapper.herokuapp.com/api/endpoint', {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa('PN4QDirqY6mrzjNyvRPy4jJsYrfXXY:VYpGGkC3GrPnFUqv6Nro4nhUdqrzwS'),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ inventory: [domain] }) // Pass an array with one domain
            });
    
            const data = await response.json();
            console.log(data);
            setAllData(prevData => [...prevData, data]);
    
            // Call the function recursively with the remaining domains after a delay of 3 seconds
            setTimeout(() => {
                callApiWithAuth(domains.slice(1));
            }, 3000);
        } catch (error) {
            console.error('Error calling API:', error);
        }
    };
    

    useEffect(() => {
        callApiWithAuth(domainQueue);
    }, [domainQueue]);
        useEffect(() => {
            console.log(allData);
        }, [allData]);
     

        if (!apiData) {
            return null; // If API data is not yet available, return null or a loading indicator
        }
    
        const handleNextDomain = () => {
            if (domainIndex < allData.length - 1) {
                setDomainIndex(domainIndex + 1);
            }
        };
    
        const handlePrevDomain = () => {
            if (domainIndex > 0) {
                setDomainIndex(domainIndex - 1);
            }
        };

        const currentDomainData = allData[domainIndex]?.results[0] || {};
        console.log(currentDomainData)

const {
    domain,
    approval_status,
    comment,
    moz,
    monetized_by,
    blocked,
    autoapproved,
    score,
    rv_data: { adTech = [], trafficByCountry, averageRank, bounceRate, pagesPerVisit, legacyCategory } = {},
    traffic_data: { monthly_total_visits, percentages } = {}
} = currentDomainData;

    // Convert monthly total visits data into an array of objects with date and visits
    const monthlyVisitsData = monthly_total_visits ? Object.entries(monthly_total_visits).map(([date, visits]) => ({
        date,
        visits: parseFloat(visits.toString().replace(/[^\d.]/g, '')) // Remove non-numeric characters
    })) : [];
    // Convert traffic by country data into an array of objects with name and percentage
    const COLORS = ['#FFBB28', '#0088FE', '#00C49F', '#FF8042', '#AF19FF'];
    const trafficByCountryData = (trafficByCountry || []).map(({ country = "", percentage = 0 }, index) => ({
        name: country,
        value: Math.round(percentage * 100), // Convert percentage to a value between 0 and 100
        fill: COLORS[index % COLORS.length] // Use a color from the COLORS array
    }));

    const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#00C49F', '#FFBB28'];
    const formatPercentage = (value) => `${value.toFixed(2)}%`;

    return (
        <>
                   <TextField
                label="Add Domain"
                variant="outlined"
                value={domainInput}
                onChange={handleDomainInputChange}
                fullWidth
                margin="normal"
                multiline
            />
            {/* Button to add a domain to the queue */}
            <Button onClick={addToQueue} variant="contained" color="primary">
                Add Domain to Queue
            </Button>
            <Button onClick={handlePrevDomain} disabled={domainIndex === 0}><ArrowBackIos /></Button>
            <Button onClick={handleNextDomain} disabled={domainIndex === domainQueue.length - 1}><ArrowForwardIos /></Button>
        <Paper className={classes.paper} style={{textAlign:"center",alignItems:"center", justifyContent:"center"}}>
        <Grid container spacing={2}>
    <Grid item xs={12}>
        <Paper className={classes.paper}>
            <Favicon domain={domain}></Favicon>
            <Typography variant="h4" gutterBottom>{domain}</Typography>
        </Paper>
    </Grid>
    <Grid container spacing={4} style={{ display: 'flex', flexWrap: 'nowrap', justifyContent:"center" }}>
    <div className={classes.infoBox}>
    <Typography variant="subtitle1">Approval Status</Typography>
    <Paper className={`${classes.infoItem} ${approval_status === 'approved' ? classes.approved : approval_status === 'manual check' ? classes.manualCheck : classes.notApproved}`}>
        {approval_status === 'approved' ? <Check fontSize="large" /> : approval_status === 'manual check' ? <HelpOutline /> : <Close />}
    </Paper>
</div>
<div className={classes.infoBox}>
    <Typography variant="subtitle1">Autoapproved</Typography>
    <Paper className={`${classes.infoItem} ${autoapproved ? classes.autoApproved : classes.notAutoApproved}`}>
    {autoapproved === true ? <Check fontSize="large" /> : autoapproved === false ? <HelpOutline /> : <Close />}
    </Paper>
</div>
<div className={classes.infoBox}>
    <Typography variant="subtitle1">Blocked</Typography>
    <Paper className={`${classes.infoItem} ${blocked === "true" ? classes.blocked : classes.notBlocked}`}>
    {blocked === "true" ? <><h3>Yes</h3></> : blocked === "false" ? <><h3>No</h3></> : <Close />}
    </Paper>
    </div>
<div className={classes.infoBox}>
    <Typography variant="subtitle1">Monetized by</Typography>
    <Paper className={`${classes.infoItem} ${monetized_by > 0 ? classes.monetized : classes.notMonetized}`}>
    <Typography variant="body1">{monetized_by || "N/A"}</Typography>
</Paper>
</div>
    

        <div className={classes.infoBox}>
            <Typography variant="subtitle1">Comment</Typography>
            <Paper className={classes.infoItem} style={{ backgroundColor: "#D9D6D2" }}>
    <Typography variant="body1">{comment || "N/A"}</Typography>
</Paper>
        </div>


        <div className={classes.infoBox}>
            <Typography variant="subtitle1">Moz score</Typography>
            <Paper className={classes.infoItem} style={{ backgroundColor: "#D9D6D2" }}>
    <Typography variant="body1">{moz || "N/A"}</Typography>
</Paper>
        </div>
        <div className={classes.infoBox}>
            <Typography variant="subtitle1">SQTA score</Typography>
            <Paper className={classes.infoItem} style={{ backgroundColor: "#D9D6D2" }}>
    <Typography variant="body1">{Math.round(score * 100) / 100 || "N/A"}</Typography>
</Paper>
        </div>
    </Grid>
    </Grid>

        <Divider style={{ margin: '20px 0' }} />
    </Paper>
                <Paper className={classes.paper}>
                <Typography variant="h5" gutterBottom>Traffic sources distribution</Typography>
                <br></br>
                    <Grid container spacing={4}>
                    
                    <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={Object.entries(percentages)}>
                        <XAxis dataKey={([key]) => key} />
                        <YAxis tickFormatter={formatPercentage} />
                        <Legend
                            verticalAlign="bottom"
                            align="center"
                            height={36}
                            iconSize={20}
                            wrapperStyle={{ color: '#333', fontSize: '14px' }}
                            payload={Object.keys(percentages).map((key, index) => ({
                                value: key,
                                type: 'square',
                                color: colors[index],
                            }))}
                        />
                        {Object.entries(percentages).map(([category, value], index) => (
                            <Bar key={category} dataKey={([key]) => (key === category ? value : 0)} stackId="a" fill={colors[index]} />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
                    </Grid>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h5" gutterBottom>Traffic Statistics</Typography>
                    
                    <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Visits</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.entries(monthly_total_visits).map(([date, visits]) => (
                        <TableRow key={date}>
                            <TableCell>{date}</TableCell>
                            <TableCell>{formatNumber(visits)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
                    <Divider style={{ margin: '20px 0' }} />
                    <Typography variant="h5" gutterBottom>Ad Tech Used</Typography>
                    <List>
    {(adTech || []).map((tech, index) => (
        <ListItem key={index}>
            <ListItemText primary={tech} />
        </ListItem>
    ))}
</List>
                </Grid>
                <Grid item xs={24} sm={6}>
                    <Typography variant="h5" gutterBottom>Monthly Total Visits</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={monthlyVisitsData}>
    <Line type="monotone" dataKey="visits" stroke="#8884d8" />
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" tickCount={monthlyVisitsData.length} interval={0} angle={-30} />
    <YAxis tickFormatter={formatNumber} />
    <Tooltip formatter={(value) => formatNumber(Math.round(value))} />
</LineChart>
                    </ResponsiveContainer>
                    <Divider style={{ margin: '20px 0' }} />
                    <Typography variant="h5" gutterBottom>Traffic by Country</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie data={trafficByCountryData} dataKey="value" nameKey="name" fill="#8884d8" label="5">
                                {trafficByCountryData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.fill}></Cell>
                                ))}
                            </Pie>
                            <Tooltip formatter={(value) => `${formatNumber(value)}%`} />
                            <Legend />
                        </PieChart>
                    </ResponsiveContainer>
                    <Divider style={{ margin: '20px 0' }} />
                    <Typography variant="h5" gutterBottom>Additional Metrics</Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={`Average Rank: ${averageRank}`} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={`Bounce Rate: ${bounceRate}`} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={`Pages Per Visit: ${pagesPerVisit}`} />
                        </ListItem>
                    </List>
                </Grid>
                
            </Grid>
        </Paper>
        </>
    );
};

export default SQTA;
