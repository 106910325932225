import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
  Link
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
// styles
import useStyles from "./styles";


// components
import { Badge, Typography, Button } from "../Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";

const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];

const notifications = [
  { id: 0, color: "warning", message: "Check out this awesome ticket" },
  {
    id: 1,
    color: "success",
    type: "info",
    message: "What is the best way to get ...",
  },
  {
    id: 2,
    color: "secondary",
    type: "notification",
    message: "This is just a simple notification",
  },
  {
    id: 3,
    color: "primary",
    type: "e-commerce",
    message: "12 new orders has arrived today",
  },
];

export default function Header(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);


  function eraseCookie(name) {
    document.cookie = name + '=; Max-Age=0'
}
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        
<svg width="177" height="28" viewBox="0 0 195 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M102.763 18.0821L106.071 11.0471L109.342 18.0821H102.763ZM110.752 3.39258H101.657L89.7422 28.1568H98.0087L99.8696 24.2246H112.199L114.023 28.1568H122.667L110.752 3.39258Z" fill="white"/>
<path d="M146.855 9.94397V3.38672H118.926V9.94397H128.985V28.151H136.801V9.94397H146.855Z" fill="white"/>
<path d="M157.695 3.38672H149.879V28.151H157.695V3.38672Z" fill="white"/>
<path d="M183.955 3.38672L176.449 19.5305L169.042 3.38672H160.262L171.972 28.151H180.648L192.358 3.38672H183.955Z" fill="white"/>
<path d="M10.1366 10.0067V12.6265H26.5229V18.9107H10.1366V21.7562H26.5229V28.0405H2.64062V3.50195H26.5229V10.0067H10.1366Z" fill="white"/>
<path d="M51.0191 18.3748L47.8634 15.6133L43.2872 20.1966L45.1796 21.8188C44.9804 21.8293 44.7759 21.8398 44.5715 21.8398C40.682 21.8398 37.5473 19.5613 37.5473 15.7708C37.5473 11.9803 40.682 9.70181 44.5715 9.70181C48.461 9.70181 51.5957 11.9751 51.5957 15.7708C51.5957 16.7421 51.3913 17.6188 51.0138 18.3801M59.5844 15.7708C59.5844 8.35781 53.3517 2.97656 44.5715 2.97656C35.7912 2.97656 29.5586 8.35781 29.5586 15.7708C29.5586 23.1838 35.7912 28.5651 44.5715 28.5651C47.14 28.5651 49.4884 28.1031 51.5328 27.2683L54.4945 29.8093L59.2961 25.6146L56.8429 23.4673C58.5989 21.3673 59.5896 18.7318 59.5896 15.7708" fill="white"/>
<path d="M76.302 28.5657C84.6366 28.5657 89.7004 24.5652 89.7004 17.5617V3.38672H81.8846V16.664C81.8846 20.1447 79.7512 21.767 76.3072 21.767C72.8633 21.767 70.7298 20.1447 70.7298 16.664V3.38672H62.9141V17.5617C62.9141 24.5652 67.9778 28.5657 76.3124 28.5657" fill="white"/>
<path d="M26.5229 31.7949H2.64062V38.3049H26.5229V31.7949Z" fill="#FE5000"/>
</svg>

        <div className={classes.grow} />
        
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
           
          </div>
          
        </div>
        


        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          {(() => {
        if (localStorage.getItem("imageUrl")) {
          return (
            
            <img src={localStorage.getItem("imageUrl")} style={{width:"45px", height:"45px", borderRadius:"50px"}}></img>
            )
          } 
        })()}
                  {(() => {
        if (!localStorage.getItem("imageUrl")) {
          document.cookie = "Logincookie; expires=" + new Date(0).toUTCString();
          localStorage.removeItem('email');
          localStorage.removeItem('imageUrl');
          localStorage.removeItem('name');
          //signOut(userDispatch, props.history);
          return (
            <AccountIcon classes={{ root: classes.headerIcon }} />
            )
          } 
        })()}

         
        </IconButton>
        <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
           
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu>
        
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          
          <div className={classes.profileMenuUser}>
          {(() => {
        if (localStorage.getItem("name")) {
          return (
            <Typography
            className={classes.profileMenuLink}
            color="black"
          >
            Hello, {localStorage.getItem("name")}
          </Typography>
            )
          } 
        })()}
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => {signOut(userDispatch, props.history);document.cookie = "Logincookie; expires=" + new Date(0).toUTCString();}}
            ><br></br>
              Log out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
