
import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MUIDataTable from "mui-datatables";
import mock from '../../pages/dashboard/mock';
import { Table } from '@material-ui/core';
import { ReactFragment } from 'react';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
} from "@material-ui/core";
import Widget from "../Widget/Widget";
import PageTitle from "../PageTitle/PageTitle";
import imagenotify from "./blocked-popup-clue.jpg"
// styles


import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
// components


export default function Miscellaneous() {
  
  const id_tocheck = useRef();
  const temp_list = useRef();
  const blocker = useRef();

  const useStyles = makeStyles(theme => ({
    tableOverflow: {
      overflow: 'auto'
    }
  }))
  var classes = useStyles();
 var Domains;
 var Temp_Domains;
 var sr;
var dr;
var ar;
var mr;
var jr;
var kr;
var i = 0;
var j = 0;
    function addTen()
    {
      
      Temp_Domains = temp_list.current.value;
      sr = Temp_Domains.split(/\n|,/g);
dr = sr.map(v => v.replace(' ',''));
ar = dr.map(v => v.toLowerCase());
mr = ar.map(v => v.replace('http://',''));
jr = mr.map(v => v.replace('https://',''));
if(jr.length > 10)
{


if(jr.length >= 10)
{
  kr = jr.slice(Math.max(jr.length - 10, 1));
}
else
{
  kr = jr.slice(Math.max(jr.length - jr.length, 1))
}


      for(j; j < kr.length; j++)
      {
        id_tocheck.current.value += kr[j] + '\n';
      }
      if(jr.length >= 10)
      {
        jr.splice(-10);
      }
      else
      {
        jr.splice(jr.length-1);
      }
      j = 0;
      temp_list.current.value = null;
      for(j; j < jr.length; j++)
      {
        temp_list.current.value += jr[j] + '\n';
      }
      j = 0;

    }
    else
    {
      for(j; j < jr.length; j++)
      {
        id_tocheck.current.value += jr[j] + '\n';
      }
      j = 0;
      temp_list.current.value = null;
    }
    }
    function open()
    {
      Domains = id_tocheck.current.value;
      sr = Domains.split(/\n|,/g);
dr = sr.map(v => v.replace(' ',''));
ar = dr.map(v => v.toLowerCase());
mr = ar.map(v => v.replace('http://',''));
jr = mr.map(v => v.replace('https://',''));
var notified = 0;
console.log(jr)
for (i; i < jr.length; i++)
{
if(jr[i] == "")
{
  jr.splice(i, 1);
  continue;
}

var newWin = window.open("http://" + jr[i], '_blank');
if(!newWin || newWin.closed || typeof newWin.closed=='undefined') 
{ 
  if(notified == 0)
  {
    toast.info(<div><img width="100%" src={imagenotify} /><br></br>Please allow pop-up to this site<br></br> unless it will not work<br></br></div>, {
      position: "top-center",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
      notified = 1;
  }
  
}
else
{
  
}

}
i = 0;

}
    
    
    
    
  return (
    <div >
<br></br>
<TextField variant="outlined" inputRef={temp_list} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea" placeholder="Temporary list for you to use"></TextField>
<br></br><br></br><br></br>
<Button variant="contained" color="primary" onClick={addTen}>Add last 10</Button>
<br></br><br></br>
<TextField variant="outlined" inputRef={id_tocheck} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea" placeholder="Your URLs separated by line break"></TextField>
<ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  ></ToastContainer>
<p> </p>  
<Button variant="contained" color="primary" onClick={open}>Open</Button>
    </div>
  );
  }
