import React, { useState, useRef } from "react";
import {
  Grid,
  LinearProgress,
  OutlinedInput,
  MenuItem,
  Button
} from "@material-ui/core";

import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";
import { TextField } from "@material-ui/core";
// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import RokuAnalyser from "../../components/RokuAnalyser/RokuAnalyser";
import Select from 'react-select'
import MUIDataTable from "mui-datatables";
import CTVAnalyser from "../../components/CTVAnalyser/CTVAnalyser";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Apps as AppsIcon,
  YoutubeSearchedFor as WebsiteAnalyser,
  ImageSearch as AdRenderers,
  TextFields as TextFields,
  LiveTvOutlined as LiveTV
} from "@material-ui/icons";

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  // local
  var [mainChartState, setMainChartState] = useState("monthly");
  const [advertisers, setAdvertisers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tableStatus, setTableStatus] = useState("hidden")
  var current;
  const adv = useRef("");
  const advfield = useRef("");
  const categoryselect = useRef();
  const textfield = useRef();
  const progress = useRef();
  const image = useRef(null);
  var Advertiser = [{}];
  var Images = [{}];
  var advertiserssplit;
  var currentcategory;
  var currentcategoryname;
  const [datatableData,setdatatableData] = useState([]);
  const tableRef = useRef();
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function finish()
  {
    for(let element in Advertiser)
    {
      let cat = parseInt(Advertiser[element].Category)
      console.log(cat);
      
      setdatatableData(datatableData => [...datatableData,[Advertiser[element].Id, Number(cat)]])
    }
    setTableStatus("visible");
  }
  function upload() {
    console.log(adv.current.value);
    let advertiserssplit = adv.current.value.split('\n');
    for (let i = 0; i < advertiserssplit.length; i++) {
      // Using a regular expression to split on both comma and tab
      let ids = advertiserssplit[i].split(/,|\t/);
      Advertiser[i] = {
        Name: ids[1].trim(),
        Id: ids[0],
        Category: '',
        CategoryName: '',
        Adult: ''
      };
    }
    current = -1;
    console.log(Advertiser);
    for (let i = 0; i < Advertiser.length; i++) {
      Images[i] = {
        url: '',
        adult: ''
      };
    }
    textfield.current.style.display = "none";
    var counter = 0;
    start(counter);
  }

  

  async function start(counter) {
    for (let i = counter; i < Advertiser.length; i++) {
      await getImage(i); // Wait for the image to be fetched before proceeding
      advfield.current.innerText = "Processing...";
      progress.current.value = i + 1; // Assuming progress is a progress element
    }
    advfield.current.innerText = "Done, you can work!";
    progress.current.max = Advertiser.length; // This could be set once outside of this loop if it doesn't change
  }
  
  const options = [
    { value: '0', label: 'Uncategorized' },
    { value: '1', label: 'Arts & Entertainment' },
    { value: '2', label: 'Books & Literature' },
    { value: '3', label: 'Celebrities' },
    { value: '4', label: 'Humor' },
    { value: '5', label: 'Cinema' },
    { value: '6', label: 'Music & Audio' },
    { value: '7', label: 'Television and Radio' },
    { value: '8', label: 'Automotive' },
    { value: '9', label: 'Business' },
    { value: '10', label: 'Advertising' },
    { value: '11', label: 'Agriculture Business' },
    { value: '12', label: 'Biotech/Biomedical Business' },
    { value: '13', label: 'Business Software' },
    { value: '14', label: 'Construction Business' },
    { value: '15', label: 'Forestry Business' },
    { value: '16', label: 'Law & Government' },
    { value: '17', label: 'Green Solutions Business' },
    { value: '18', label: 'Human Resources Business' },
    { value: '19', label: 'Logistics Business' },
    { value: '20', label: 'Marketing Business' },
    { value: '21', label: 'Metal Business' },
    { value: '22', label: 'Careers' },
    { value: '23', label: 'Education' },
    { value: '24', label: 'Banks, Insurance, Social Benefits' },
    { value: '25', label: 'Cryptocurrency' },
    { value: '26', label: 'Family & Parenting' },
    { value: '27', label: 'Sports' },
    { value: '28', label: 'Society' },
    { value: '29', label: 'Health & Fitness' },
    { value: '30', label: 'Food & Drink' },
    { value: '31', label: 'Alcohol' },
    { value: '32', label: 'Hobbies & Interests' },
    { value: '33', label: 'Games and Toys' },
    { value: '34', label: 'Gambling' },
    { value: '35', label: 'Tobacco' },
    { value: '36', label: 'CBD' },
    { value: '37', label: 'Science and Research' },
    { value: '38', label: 'Video Games and Online Games' },
    { value: '39', label: 'Home & Garden' },
    { value: '40', label: 'Politics' },
    { value: '41', label: 'News / Weather / Information' },
    { value: '42', label: 'Dating Services' },
    { value: '43', label: 'Social Networks' },
    { value: '44', label: 'Animals' },
    { value: '45', label: 'Style & Fashion' },
    { value: '46', label: 'Technology and Computing' },
    { value: '47', label: 'Mobile Phones, Logos, Ringtones' },
    { value: '48', label: 'Freeware and Software Download' },
    { value: '49', label: 'Online Data Storage' },
    { value: '50', label: 'Video Sharing services' },
    { value: '51', label: 'Email / Chat / Messaging' },
    { value: '52', label: 'Search Engines and Portals' },
    { value: '53', label: 'Travel and Tourism' },
    { value: '54', label: 'Real Estate' },
    { value: '55', label: 'Online Shopping' },
    { value: '56', label: 'Religion & Spirituality' },
    { value: '57', label: 'Weight Loss' },
    { value: '58', label: 'Illegal Content' },
    { value: '59', label: 'Arms & Ammunition' },
    { value: '60', label: 'Hunting / Shooting' },
    { value: '61', label: 'Spyware / Malware / Malicious' },
    { value: '62', label: 'Online Piracy' },
    { value: '63', label: 'Alternative Religions' },
    { value: '64', label: 'Scam & Harmful Content' },
    { value: '65', label: 'Adult & Explicit Sexual Content' },
    { value: '66', label: 'Sexuality' },
    { value: '67', label: 'Sex Toys' },
    { value: '68', label: 'Obscenity and Profanity' },
    { value: '69', label: 'Hate Speech and Acts of Aggression' },
    { value: '70', label: 'Incentivized' },
    { value: '71', label: 'Illegal Drugs & Paraphernalia' },
    { value: '72', label: 'Cannabis/THC' },
    { value: '73', label: 'Unavailable Site' },
    { value: '74', label: 'CDN and Not Definable' },
    { value: '76', label: 'Consumer Services' },
  ];

  function getCategoryName(id)
  {
    console.log(options);
    if(id >= 0 && !NaN)
    {
      return options[id].label;
    }
    else
    {
      return 0;
    }
    
  }
  function updateUI(currentIndex) {
    progress.current.value = currentIndex + 1;
    const advertiser = Advertiser[currentIndex];
    const imageInfo = Images[currentIndex];
    advfield.current.innerHTML = `<h3><center>${advertiser.Name} ${advertiser.CategoryName}</center></h3>`;
    if(advertiser.Adult === true) {
        advfield.current.innerHTML += '<h3>Watch out! This site is marked as ADULT</h3>';
    }
    if(imageInfo.url === 'No screenshot available' || imageInfo.url === '') {
        image.current.alt = 'No screenshot available';
        image.current.src = ''; // You can set this to a default placeholder image if you have one
    } else {
        image.current.src = imageInfo.url;
    }
}
  function fill() {
    if(current < Advertiser.length - 1) {
        current++;
        updateUI(current); // Update UI with current advertiser
    } else {
        console.log("No more advertisers to show.");
    }
}
function openWebsite() {
  const advertiserUrl = Advertiser[current].Name; // Assuming this is the domain or URL
  console.log(advertiserUrl);

  // Ensure the URL starts with http:// or https://
  const formattedUrl = advertiserUrl.startsWith('http://') || advertiserUrl.startsWith('https://') ? advertiserUrl : `http://${advertiserUrl}`;
  
  // Open the URL in a new window (or tab)
  window.open(formattedUrl, '_blank'); // '_blank' opens the URL in a new window or tab
}
function fillback() {
  if(current > 0) {
      current--;
      updateUI(current); // Update UI with current advertiser
  } else {
      console.log("This is the first advertiser.");
  }
}
  
  function categorize()
  {
    advfield.current.innerHTML= '<h3><center>' + Advertiser[current].Name + '' + Advertiser[current].CategoryName + '</h3></center>'
    console.log(current);
    console.log(Advertiser);
    console.log(Images);
    Advertiser[parseInt(current)] = 
    {
      Name: Advertiser[current].Name,
      Id: Advertiser[current].Id,
      Category: parseInt(currentcategory),
      CategoryName: currentcategoryname,
      Adult: Images[current].adult
    }
    
    //Advertiser[current].Category = categoryselect.current.props.value.value
    //Advertiser[current].Adult = 
    
  }
  
  function getImage(counter) {
    return new Promise((resolve, reject) => {
        fetch('http://mobileappscrapper.herokuapp.com/screenshots/http://' + Advertiser[counter].Name)
            .then(response => {
                if (response.ok) {
                    return response.blob(); // Expecting a blob response
                }
                console.error('Network response was not ok for', Advertiser[counter].Name);
                throw new Error('Screenshot not available'); // Throw an error to catch block
            })
            .then(blob => {
                const imageUrl = URL.createObjectURL(blob); // Create a URL for the blob
                Images[counter].url = imageUrl;
                resolve(imageUrl); // Resolve with imageUrl
            })
            .catch(error => {
                console.error('Error fetching image:', error);
                Images[counter].url = 'No screenshot available'; // Placeholder text
                resolve('No screenshot available'); // Resolve with placeholder text
            });
    });
}
  function changeCategory(category)
  {
    currentcategory = category.value;
    currentcategoryname = category.label;
    console.log(Advertiser);
  }

  const columns = [
    {
     name: "AdvertiserId",
     label: "AdvertiserId",
    },
    {
      name: "EquativAdvCategoryV2Id",
      label: "EquativAdvCategoryV2Id"
    }]
  
  return (
    <>
    
      <PageTitle title="" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          
          <Widget
            title="aa"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            
          >
            <div ref={textfield}>
            <TextField variant="outlined" inputRef={adv} rows={10} fullwidth="true" multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"95%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
            <Button onClick={upload}>Upload Advertisers</Button>
            </div>
            <center>
            <img ref={image}></img>
            </center>
           
           
           <Grid container>
           <center>
     <Grid item xs={3} style={{textAlign:"center", alignContent:"center", justifyContent:"center", alignItems:"center"}} ref={advfield}> 
     
     </Grid>
     
     </center>
     </Grid>
     <Grid>
       <Grid>
         <center>
       <div style={{width:"50%"}}>
         <center>
         <Select style={{zIndex: 9999}} menuPortalTarget={document.body} placeholder="Category" options={options} onChange={(category) => {changeCategory(category)}} ></Select>
         </center>
        
</div>
</center>


 
     <div>

     <center>
     <progress style={{width:"50%"}}ref={progress} max="0" value="0"></progress><br></br>
          <Button onClick={fillback}>Previous Advertiser</Button>
          <Button variant="outlined" color='primary' style={{color:"green"}} onClick={categorize}>Categorize!</Button> 
          <Button onClick={fill}>Next Advertiser</Button>
          <Button onClick={openWebsite}>Open Website</Button>
          <Button onClick={finish}>Finish</Button>
          </center>
          </div>
</Grid>
</Grid>
<div style={{width:"100%", }}>
</div>
          <div style={{visibility:tableStatus}}>
          <MUIDataTable
  title="Results"
  ref={tableRef}
  columns={columns}
  data={datatableData}
  options={{
    filterType: "checkbox",
    downloadOptions: {
      filename: 'AdCall_' + new Date().toISOString().slice(0, 10) + '.csv',
      separator: ','
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      // Custom header builder to avoid quotes
      const customBuildHead = (columns) => {
        return columns.map(column => column.name).join(',') + '\r\n';
      };
      
      // Custom body builder to avoid quotes in data
      const customBuildBody = (data) => {
        return data.map(row => {
          return row.data.map(cell => String(cell).replace(/"/g, '')).join(',');
        }).join('\r\n');
      };
      
      return "\uFEFF" + customBuildHead(columns) + customBuildBody(data);
    },
    textLabels: {
      body: {
        noMatch: "Please categorize advertisers first",
      }
    },
  }}
/>
          </div>
         </Widget>
        </Grid>
        

       
       
      </Grid>
          

    
        
        
        
       
   
        
        
        
       

      
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
