import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
export default function UrlDecoder() {

  const url = useRef()
  const Decode = useRef();
  const Encode = useRef();
  const wynik = useRef();
  var sr;
    function Action()
    {
      wynik.current.innerText = "";
      let Url = url.current.value;
      sr = Url.split(/\n|,/g);

      if(Decode.current.checked == true)
      {
        for (let i=0; i < sr.length; i++)
        {
            let decodes = decodeURIComponent(sr[i]);
            wynik.current.innerText += decodes + "\n";
        }
      }
      if(Encode.current.checked == true)
      {
        for (let i=0; i < sr.length; i++)
        {
            let decodes = encodeURIComponent(sr[i]);
            wynik.current.innerText += decodes + "\n";
        }
      }

    }
    function copyElementText() {
      console.log("Copy")
      var text = wynik.current.innerText;
      var elem = document.createElement("textarea");
      document.body.appendChild(elem);
      elem.value = text;
      elem.select();
      document.execCommand("copy");
      document.body.removeChild(elem);
      toast.success('Copied!', {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        
        });
  }
return (
    <div >
      <br></br>
       <TextField variant="outlined" inputRef={url} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField>
    <p> </p> 
    <p> </p>
    <RadioGroup>
      <p> </p>
    <FormControlLabel ref={Decode} onClick={()=> {Decode.current.checked = true;Encode.current.checked = false}} value="true" control={<Radio />} label="Decode" />
    <FormControlLabel ref={Encode} onClick={()=> {Encode.current.checked = true;Decode.current.checked = false}} value="false" control={<Radio />} label="Encode" />
    </RadioGroup>
    <Button variant="contained" color="primary" onClick={Action}>Action</Button>
    <Button variant="contained" color="primary" onClick={copyElementText}>Copy</Button>
    <p> </p>
    <div ref={wynik}>

    </div>
    </div>
);
}