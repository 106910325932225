import React, { useState, useRef } from "react";
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  YAxis,
  XAxis,
} from "recharts";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import TextMerger from "../../components/TextMerger/TextMerger";
const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const urlopener = useRef(null);
  const textmerger = useRef();
  const urldecoder = useRef();
  const base64decoder = useRef();
  const jsonexporter = useRef();
  const punycodetourl = useRef();
  const parameterreporter = useRef();
  const criteoblocklist = useRef();
  const domainblockingtoolconverter = useRef();

  const executeScroll = () => urlopener.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const executeScroll2 = () => textmerger.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const executeScroll3 = () => urldecoder.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  
  const executeScroll4 = () => base64decoder.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const executeScroll5 = () => jsonexporter.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) 
  const executeScroll6 = () => punycodetourl.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const executeScroll7 = () => parameterreporter.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const executeScroll8 = () => criteoblocklist.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const executeScroll9 = () => domainblockingtoolconverter.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  // local
  var [mainChartState, setMainChartState] = useState("monthly");

  return (
    <>
    
      <PageTitle title="Text merger" />
   
      <br></br><br></br><br></br>
      <div ref={urlopener}></div>
     
      <Grid container spacing={4}>
      <div ref={textmerger}></div>
        <Grid item xs={12}>
          
          <Widget
            title="aa"
            upperTitle
            bodyClass={classes.fullHeightBody}
            className={classes.card}
            
          >
           <Typography>Text Merger</Typography>
            <TextMerger></TextMerger>
          </Widget>
          
        </Grid>
        
        
        
       
      </Grid>


    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  var array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  var resultArray = [];
  var tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  var desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  var mobile = getRandomData(31, 1500, 7500, 7500, 1500);

  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
