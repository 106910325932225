import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { FormGroup, Switch,Radio,RadioGroup } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
export default function PiracyPornAnalyser() {

  const json = useRef()
  const json2 = useRef()
  const wynik = useRef()
  const tableRef = useRef();
  const AddDomains = useRef();
  const Analyse = useRef();

  const [datatableData,setdatatableData] = useState([]);
  const [CrawlSwitch, setCrawlSwitch] = useState(0);
  var text;
  var words;
  var Domains;
  var PornDane = [];
  var PiracyDane = [];
  var Dane2 = [];
  var Scores = [];
  var PiracyResults = [];
  var PornResults = [];
  var Scor = [];
  const PiracyData = "720p HDRip;720p;HDRIP;episode;download;720p HD;1080p;1080p Full HD;HD Free;chapter;start reading;download for free;read online;watch online;download a book;search torrents;browse torrents;torrent;pirate search;season;lektor pl;free manga reading;manga online;Capitulo;free online music;Free MP3 Downloads;repack;Full-Game;RELOADED;repack dlc;plaza;webrip;bluray;stream;streams;streaming;live streams;boruto;naruto;one piece;death note"
  const PornData = "xxx;chicos desnudos;hombres desnudos;send nudes;pornstar;pussy;cumshot;Foursome;Threesome;double penetration;MILF;Seduces;Step son;Mia Khalifa;Creampie;Squirting;Intense Hardcore;POV fuck;Blowjob;ass fucked;hentai;porno;porn"
    function Action()
    {
      setdatatableData([])
      wynik.current.innerHTML = "";
      var domain = json.current.value;
      //var kwds = json2.current.value;
      //var Keywords = kwds.split(";");
      Domains = domain.split("\n");
      //console.log(Domains)
      //console.log(Keywords)
      if(CrawlSwitch == 0)
      {
        var MainUrl = "https://mobileappscrapper.herokuapp.com/piracyporn/";
      }
      else
      {
        var MainUrl = "https://mobileappscrapper.herokuapp.com/piracyporn/";
      }
      
      
      for(let element in Domains)
      {
        fetch(MainUrl +  Domains[element])
        .then(response => response.json())
        .then(data => setdatatableData(datatableData => [...datatableData,[JSON.parse(data).Domain, JSON.parse(data).PiracyScore, JSON.parse(data).PornScore]]))
        .catch((error) => {
          setdatatableData(datatableData => [...datatableData,[Domains[element], "No data", "No data"]])
        });

        
      }

      
      console.log(Scores)
       /*
      for(let element in Scores)
      {
        
        Scor[element] = 
        {
          Result: Scores[element]
        }
        //setdatatableData(datatableData => [...datatableData,[Scor[element].Result.Domain, Scor[element].Result.PiracyScore, Scor[element].Result.PornScore]])
      }
   
     
      for(let element in Domains)
      {
        
        fetch(MainUrl + PornData + "/" + Domains[element])
        .then(response => response.json())
        .then(data => PornDane.push(data))
        .catch((error) => {}
        )

      }


      //AddDomains.current.style.display = "None";
      //json.current.disabled = 'true';
*/
    }
    
    function groupArrayOfObjects(list, key) {
      return list.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };


function check()
{
  console.log(Scores)
  /*
  let Score;
  console.log(PiracyDane);
  var Piracymatching = [];
  for(let element in PiracyDane)
  {
    Piracymatching[element] = 0;
  }
  var Piracydifferent = [];
  for(let element in PiracyDane)
  {
    Piracydifferent[element] = 0;
  }
  var Pornmatching = [];
  for(let element in PornDane)
  {
    Pornmatching[element] = 0;
  }
  var Porndifferent = [];
  for(let element in PornDane)
  {
    Porndifferent[element] = 0;
  }
  var PiracyRiskScore = [];
  var PornRiskScore = [];
  for(let element in PiracyDane)
  {
    let kvds = JSON.parse(PiracyDane[element].WordCount)
    PiracyRiskScore[element] = 0;
    for(let elem in kvds)
    {
      Piracymatching[element] += kvds[elem].count;
      if(kvds[elem].count >= 1)
      {
        Piracydifferent[element] += 1;
      }
    }
    if(Piracydifferent[element] >= 2)
    {
      PiracyRiskScore[element]++;
    }
    if(Piracymatching[element] >= 50)
    {
      PiracyRiskScore[element] += 1;
    }
    PiracyResults[element] = 
    {
      "Domain": PiracyDane[element].Domain,
      "Words": JSON.parse(PiracyDane[element].WordCount),
      "Matching": Piracymatching[element],
      "Different": Piracydifferent[element],
      "RiskScore": PiracyRiskScore[element]
    }
  }
  for(let element in PornDane)
  {
    let kvds = JSON.parse(PornDane[element].WordCount)
    PornRiskScore[element] = 0;
    for(let elem in kvds)
    {
      Pornmatching[element] += kvds[elem].count;
      if(kvds[elem].count >= 1)
      {
        Porndifferent[element] ++;
      }
    }
    if(Porndifferent[element] >= 2)
    {
      PornRiskScore[element]++;
    }
    if(Pornmatching[element] >= 50)
    {
      PornRiskScore[element]++;
    }
    PornResults[element] = 
    {
      "Domain": PornDane[element].Domain,
      "Words": JSON.parse(PornDane[element].WordCount),
      "Matching": Pornmatching[element],
      "Different": Porndifferent[element],
      "RiskScore": PornRiskScore[element]
    }
  }
//var Results = Array.prototype.push.apply(PiracyResults,PornResults); 
console.log(PiracyResults);
console.log(PornResults);

var Results = [];
for(let elements in PiracyDane)
{
  
  Results[elements] = 
  {
    "Domain": PiracyResults[elements].Domain,
    "PiracyRiskScore": PiracyResults[elements].RiskScore,
    "PornRiskScore": 0

  }
  for(let elem in PornDane)
  {
    if(Results[elements].Domain == PornResults[elem].Domain)
    {
      Results[elements].PornRiskScore = PornResults[elem].RiskScore
      
    }
  }
  setdatatableData(datatableData => [...datatableData,[Results[elements].Domain, Results[elements].PiracyRiskScore, Results[elements].PornRiskScore]])
}

console.log(Results);

/*
for(let element in PiracyDane)
{
  

}
Dane2[element] = 
{
  "Domain": Dane[element].Domain,
  "PiracyScore": 0,
  "PornScore": 0,
  "Matching": 0,
  "Words": JSON.parse(Dane[element].WordCount)
}
*/
console.log(Dane2);

}
function ChangeVariant()
{
  if(CrawlSwitch == 0)
  {
    setCrawlSwitch(1);
  }
  else
  {
    setCrawlSwitch(0);
  }
}
const label = { inputProps: { 'aria-label': 'Checkbox' } };
return (
    <div >
{/* <Checkbox {...label} onChange={ChangeVariant} />Use secondary variant of crawl method 
      <TextField variant="outlined" multiline inputRef={json2} rows={10} placeholder="Insert keywords separated by ; " id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
  */     
}
      <br></br>
       <TextField variant="outlined" multiline inputRef={json} rows={10} placeholder="Insert domains separated by line break" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"300px",resize:"vertical"}} className="inline-txtarea"></TextField>
       
 
    <br></br>
    <Button ref={AddDomains} variant="contained" color="primary"  onClick={Action}>Analyse</Button>
    <p> </p>
    <div ref={wynik}>
    

    </div>
    <MUIDataTable
            
            title="Results"
            ref={tableRef}
            columns={["Domain", "PiracyRiskScore", "PornRiskScore"]}
            data={datatableData}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'PornPiracyAnalyser_' + new Date().toISOString().slice(0, 10) + '.csv'
              },
              textLabels: {
                body: {
                  noMatch: "Please scan websites first",
                }},
            }}
            
          />
    </div>
);
}