import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  TextField,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

export default function Sqta() {

  function NewScan() {
    const [inventory, setInventory] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
  
    const handleInputChange = (event) => {
      setInventory(event.target.value);
    };
  
    const handleFormSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      // Split the input by line breaks to create an array
      const inventoryArray = inventory.split("\n").map(domain => domain.trim()).filter(domain => domain);
  
      // Prepare the POST request body
      const requestBody = {
        inventory: inventoryArray
      };
  
      // Authorization credentials
      const username = "PN4QDirqY6mrzjNyvRPy4jJsYrfXXY";
      const password = "VYpGGkC3GrPnFUqv6Nro4nhUdqrzwS";
      const authHeader = `Basic ${btoa(`${username}:${password}`)}`;
  
      try {
        const response = await fetch("http://mobileappscrapper.herokuapp.com/api/endpoint", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader
          },
          body: JSON.stringify(requestBody)
        });
  
        if (response.ok) {
          setMessage("Scan started successfully!");
        } else {
          const errorData = await response.json();
          setMessage(`Error: ${errorData.message || 'Something went wrong'}`);
        }
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
        <Grid item xs={12} md={8} lg={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                Start a New Scan
              </Typography>
              <form onSubmit={handleFormSubmit}>
                <TextField
                  label="Inventory Domains"
                  multiline
                  rows={10}
                  variant="outlined"
                  fullWidth
                  value={inventory}
                  onChange={handleInputChange}
                  placeholder="Enter domains separated by line breaks"
                  margin="normal"
                />
                <CardActions>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    fullWidth
                  >
                    Start Scan
                  </Button>
                </CardActions>
              </form>
              {loading && <CircularProgress style={{ marginTop: "10px" }} />}
              {message && (
                <Typography variant="body1" color="textSecondary" style={{ marginTop: "10px" }}>
                  {message}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batchData, setBatchData] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [batchToDelete, setBatchToDelete] = useState(null);
  const [currentUsage, setCurrentUsage] = useState(0);
  useEffect(() => {
    // Fetch the list of batches on component mount
    fetchBatches();
  }, []);

  const fetchUsage = async () => {
    try {
      const response = await fetch("https://xn--nikola-upb.pl/readusageSQTA.php");
      const data = await response.json();
  
      // Get the current year and month
      const now = new Date();
      const currentMonth = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
  
      // Check if the current month is in the response data
      const currentMonthData = data[currentMonth] || [];
  
      // Calculate the total number of domains processed in the current month
      const totalDomainsThisMonth = currentMonthData.reduce((sum, batch) => sum + parseInt(batch.total_domains, 10), 0);
  
      return totalDomainsThisMonth;
  
    } catch (error) {
      console.error("Error fetching usage:", error);
      return 0; // Return 0 in case of error
    }
  }
  const fetchBatches = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://xn--nikola-upb.pl/readSQTA.php");
      const data = await response.json();

      // Extract unique batch IDs along with their created_at dates and count of domains
      const batchCounts = data.reduce((acc, curr) => {
        acc[curr.batch_id] = (acc[curr.batch_id] || 0) + 1;
        return acc;
      }, {});

const uniqueBatches = [...new Set(data.map(item => item.batch_id))]
  .map(batchId => {
    // Find the first item with this batchId to extract the created_at date
    const batchItem = data.find(item => item.batch_id === batchId);

    // Convert the created_at to a Date object
    const date = new Date(batchItem.created_at);
    
    // Add 2 hours to the date
    const updatedDate = new Date(date.getTime() + 2 * 60 * 60 * 1000);

    return {
      batchId,
      date: updatedDate.toLocaleString(), // Format date as a readable string
      domainCount: batchCounts[batchId]
    };
  })
  .sort((a, b) => new Date(b.date) - new Date(a.date));

      setBatches(uniqueBatches);
    } catch (error) {
      console.error("Error fetching batches:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBatchData = async (batchId) => {
    setLoading(true);
    try {
      const response = await fetch("https://xn--nikola-upb.pl/readSQTA.php");
      const data = await response.json();

      // Filter data based on selected batch ID
      const filteredData = data.filter(item => item.batch_id === batchId);

      // Flatten rv_data and traffic_data to extract only the necessary fields, including traffic volume
      const flattenedData = filteredData.map(item => ({
        id: item.id,
        domain: item.domain,
        approval_status: item.approval_status,
        authority: item.authority,
        autoapproved: item.autoapproved,
        blocked: item.blocked,
        comment: item.comment,
        monetized_by: item.monetized_by,
        score: item.score,
        legacyCategory: item.rv_data?.legacyCategory || 'N/A',
        country: item.rv_data?.country || 'N/A',
        search_percentage: item.traffic_data?.percentages?.Search || 'N/A',
        social_percentage: item.traffic_data?.percentages?.Social || 'N/A',
        mail_percentage: item.traffic_data?.percentages?.Mail || 'N/A',
        display_ads_percentage: item.traffic_data?.percentages?.["Display Ads"] || 'N/A',
        direct_percentage: item.traffic_data?.percentages?.Direct || 'N/A',
        referrals_percentage: item.traffic_data?.percentages?.Referrals || 'N/A',
        traffic_volume: item.traffic_data?.monthly_total_visits ? Object.values(item.traffic_data.monthly_total_visits).reduce((a, b) => a + b, 0) : 'N/A',
        created_at: item.created_at,
        update_date: item.Update_Date
      }));

      setBatchData(flattenedData);
    } catch (error) {
      console.error("Error fetching batch data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBatchSelect = (batchId) => {
    setSelectedBatch(batchId);
    fetchBatchData(batchId);
  };

  const handleBatchDelete = (batchId) => {
    setBatchToDelete(batchId);
    setDeleteDialogOpen(true);
  };

  const confirmBatchDelete = async () => {
    setLoading(true);
    setDeleteDialogOpen(false);
    try {
      const response = await fetch("https://xn--nikola-upb.pl/deleteSQTA.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ batch_id: batchToDelete })
      });

      if (response.ok) {
        // After deletion, refetch batches to update the table
        fetchBatches();
        setSelectedBatch(null);
      } else {
        const errorData = await response.json();
        console.error("Error deleting batch:", errorData.message);
      }
    } catch (error) {
      console.error("Error deleting batch:", error);
    } finally {
      setLoading(false);
      setBatchToDelete(null);
    }
  };

  const cancelBatchDelete = () => {
    setDeleteDialogOpen(false);
    setBatchToDelete(null);
  };

  const batchColumns = [
    { name: "batchId", label: "Batch ID" },
    { name: "date", label: "Date" },
    { name: "domainCount", label: "Domain Count" },
    {
      name: "select",
      label: "Select Batch",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleBatchSelect(batches[dataIndex].batchId)}
          >
            Select
          </Button>
        ),
      },
    },
    {
      name: "delete",
      label: "Delete Batch",
      options: {
        customBodyRenderLite: (dataIndex) => (
          <IconButton
            color="secondary"
            onClick={() => handleBatchDelete(batches[dataIndex].batchId)}
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
    },
  ];

  const columns = [
    { name: "domain", label: "Domain" },
    { name: "approval_status", label: "Approval Status" },
    { name: "authority", label: "Authority" },
    { name: "autoapproved", label: "Auto Approved" },
    { name: "blocked", label: "Blocked" },
    { name: "comment", label: "Comment" },
    { name: "monetized_by", label: "Monetized By" },
    { name: "score", label: "Score" },
    { name: "legacyCategory", label: "Legacy Category" },
    { name: "country", label: "Country" },
    { name: "search_percentage", label: "Search %" },
    { name: "social_percentage", label: "Social %" },
    { name: "mail_percentage", label: "Mail %" },
    { name: "display_ads_percentage", label: "Display Ads %" },
    { name: "direct_percentage", label: "Direct %" },
    { name: "referrals_percentage", label: "Referrals %" },
    { name: "traffic_volume", label: "Traffic Volume" },
    { name: "created_at", label: "Created At" },
    { name: "update_date", label: "Update Date" }
  ];
  useEffect(() => {
    const loadUsage = async () => {
      const usage = await fetchUsage();
      setCurrentUsage(usage); // Update the state with the fetched usage
    };

    loadUsage();
  }, []);
  return (
    <div>
      <NewScan />
      {loading && <CircularProgress />}
      <Grid container justifyContent="center" style={{ marginTop: "20px" }}>
      <Grid item xs={12} md={6} lg={4}>
        <Card>
          <CardContent>
            <Typography style={{textAlign:"center"}} variant="h5" component="h2" gutterBottom>
              Current Month Usage
            </Typography>
            {loading ? (
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            ) : (
              <Typography style={{textAlign:"center"}} variant="h3" component="p" color="primary">
                {currentUsage}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" style={{ marginTop: "10px" , textAlign:"center"}}>
              Total domains processed in {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })}.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid><br></br>
      {!loading && batches.length > 0 && (
        <MUIDataTable
          title={"Available Batches"}
          data={batches}
          columns={batchColumns}
          options={{
            filterType: "checkbox",
            selectableRows: "none",
            onRowClick: (rowData, rowMeta) => handleBatchSelect(batches[rowMeta.dataIndex].batchId),
          }}
        />
      )}

      {!loading && selectedBatch && (
        <MUIDataTable
          title={`Results for ${selectedBatch}`}
          data={batchData}
          columns={columns}
          options={{
            filterType: "checkbox",
            selectableRows: "none",
            responsive: "scrollMaxHeight", // This will help in rendering large tables
            downloadOptions: {
              filename: `SQTA_${selectedBatch}.csv`, // Set the filename to include the batchId
              separator: ",", // CSV separator
            },
          }}
        />
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={cancelBatchDelete}
      >
        <DialogTitle>Confirm Batch Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this batch? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelBatchDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmBatchDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
