import { Button } from '@material-ui/core';
import React, { useRef, useState} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $, { parseJSON } from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControlLabel } from '@material-ui/core';
import { Switch,Radio,RadioGroup } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { styled } from '@material-ui/styles';
import { Input } from '@material-ui/core';
import isbot from 'isbot'
import DeviceDetector from "device-detector-js";

export default function BidRequestAnalyser() {


  const [slower, setslower] = useState(false);
  const tableRef = useRef();
  const json = useRef()
  const json2 = useRef()
  const wynik = useRef()
  const fileref = useRef();
  const linkref = useRef();
  const [datatableData,setdatatableData] = useState([]);
  const [datatableData2,setdatatableData2] = useState([]);
  const [datatableData3,setdatatableData3] = useState([]);
  const [datatableData4,setdatatableData4] = useState([]);
  const [indicator, setindicator] = useState(0);
  var text;
  var names = [];
  var names2 = [];
  var names3 = [];
  var domains = [];
  var countdomains = {};
  var publisher = [];
  var counttypes = {};
  var intermediary = [];
  var idcount = [];
  var countids = {};
  var both = [];
  var count = {};
  var duplicate = [{}];
  var duplicatedomains = [{}];
  var duplicateids = [{}];
  var jsonstrict;
  var fileJson = "";
var BidRequests = [{}];


  async function Check()
  {
    
    setdatatableData3(datatableData3 => [])
    if(fileJson != "")
    {
    var jsonnotformatted = fileJson.replace(/(\r\n|\n|\r)/gm, "")
    var preFormat = jsonnotformatted.replaceAll("}{","} , {");
    var jsonFirstFormat = preFormat.split("\n").join("");
    var jsonFirstFormatExtra = jsonFirstFormat.replaceAll("}{","} , {");
    var jsonThirdFormat = jsonFirstFormatExtra.replaceAll("} {","} , {");
    var outString = jsonThirdFormat.split("}﻿{").join("} , {");
    var jsonSecondFormat = "[" + outString + "]";
    }
    else
    {
      if(json.current.value != "")
      {
        var jsonnotformatted = json.current.value; 
        var jsonFirstFormat = jsonnotformatted.split("\n").join("");
        var jsonFirstFormatExtra = jsonFirstFormat.replaceAll("}{","} , {");
        var jsonThirdFormat = jsonFirstFormatExtra.replaceAll("} {","} , {");
        var outString = jsonThirdFormat.split("}﻿{").join("} , {");
        var jsonSecondFormat = "[" + outString + "]";
      }
      else
      {
        /*
        console.log(linkref.current.value)
        var jsonnotformatted = "";
        fetch("http://mobileappscrapper.herokuapp.com/castleblack/" + linkref.current.value)
        .then(response => response.json())
        .then(data => console.log(data));
        var jsonFirstFormat = jsonnotformatted.split("\n").join("");
        var jsonFirstFormatExtra = jsonFirstFormat.replaceAll("}{","} , {");
        var jsonThirdFormat = jsonFirstFormatExtra.replaceAll("} {","} , {");
        var outString = jsonThirdFormat.split("}﻿{").join("} , {");
        var jsonSecondFormat = "[" + outString + "]";
        */
      }

      
    }

   
    var validjson = JSON.parse(jsonSecondFormat.toString());
    console.log(validjson)

    var allElements = validjson.length;
    var currentElement = 0;
    toast.info('Uploading, please wait untill the progress will end ',{
      autoclose: false,
      toastId: "roku",
      position: "top-center",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      });

      if(slower == true)
      {
        start(currentElement);
      }
      else
      {
        for(let element = 0; element < allElements; element++)
        {
          FillTable(element);
        }
      }
    
      function Frg() {
        return 
        <div>
      <h1>Hello</h1>
        </div>
        
      };
    function start(counter){
      currentElement = 0;
      if(counter < allElements){
        
        setTimeout(function(){
          FillTable(counter)
          FillTable(counter+1)
          FillTable(counter+2)
          FillTable(counter+3)
          FillTable(counter+4)
          FillTable(counter+5)
          FillTable(counter+6)
          FillTable(counter+7)
          FillTable(counter+8)
          FillTable(counter+9)
          counter += 10;
          setindicator(counter/allElements)
          toast.update("roku", {            info:indicator         })
          console.log(counter);
          start(counter);
        }, 100);
      }
    }
   


    function FillTable(element)
    {
      try
      {
        var BundleId = validjson[element].partnerBidRequest.app.bundle;
      }
      catch
      {
        var BundleId = "Not found";
      }
      try
      {
        var AppDomain = validjson[element].partnerBidRequest.app.domain;
      }
      catch
      {
        var AppDomain = "Not found"
      }
      try
      {
        var SiteDomain = validjson[element].partnerBidRequest.site.domain
      }
      catch
      {
        var SiteDomain = "Not found"
      }
      try
      {
        var SitePage = validjson[element].partnerBidRequest.site.page
      }
      catch
      {
        var SitePage = "Not found"
      }
      try
      {
        var SitePublisherName = validjson[element].partnerBidRequest.site.publisher.name
      }
      catch
      {
        var SitePublisherName = "Not found"
      }
      try
      {
        var SitePublisherId = validjson[element].partnerBidRequest.site.publisher.id
      }
      catch
      {
        var SitePublisherId = "Not found"
      }
      try
      {
        var Id = validjson[element].partnerBidRequest.app.id;
      }
      catch
      {
        var Id = "Not found";
      }
      try
      {
        var AppName = validjson[element].partnerBidRequest.app.name;
      }
      catch
      {
        var AppName = "Not found";
      }
      try
      {
        var SiteName = validjson[element].partnerBidRequest.site.name;
      }
      catch
      {
        var SiteName = "Not found";
      }
      try
      {
        var AppPublisherId = validjson[element].partnerBidRequest.app.publisher.id;
      }
      catch
      {
        var AppPublisherId = "Not found";
      }
      try
      {
        var AppPublisherName = validjson[element].partnerBidRequest.app.publisher.name;
      }
      catch
      {
        var AppPublisherName = "Not found";
      }
      try
      {
        var StoreUrl = validjson[element].partnerBidRequest.app.storeurl;
      }
      catch
      {
        var StoreUrl = "Not found";
      }
      try
      {
        var DeviceType = validjson[element].partnerBidRequest.device.devicetype;
        if(DeviceType == 1)
        {
          DeviceType = "1. Mobile/Tablet";
        }
        if(DeviceType == 2)
        {
          DeviceType = "2. Personal Computer";
        }
        if(DeviceType == 3)
        {
          DeviceType = "3. Connected TV";
        }
        if(DeviceType == 4)
        {
          DeviceType = "4. Phone";
        }
        if(DeviceType == 5)
        {
          DeviceType = "5. Tablet";
        }
        if(DeviceType == 6)
        {
          DeviceType = "6. Connected Device";
        }
        if(DeviceType == 7)
        {
          DeviceType = "7. Set Top Box";
        }
      }
      catch
      {
        var DeviceType = "Not found";
      }
      try
      {
        var IsApp = validjson[element].partnerBidRequest.device.ext.is_app;
      }
      catch
      {
        var IsApp = "Not found";
      }
      try
      {
        var Ifa = validjson[element].partnerBidRequest.device.ifa;
      }
      catch
      {
        var Ifa = "Not found";
      }
     
      try
      {
        var connectiontype = validjson[element].partnerBidRequest.device.connectiontype;
        if(connectiontype == 0)
        {
          connectiontype = "0. Unknown"
        }
        if(connectiontype == 1)
        {
          connectiontype = "1. Ethernet"
        }
        if(connectiontype == 2)
        {
          connectiontype = "2. WIFI"
        }
        if(connectiontype == 3)
        {
          connectiontype = "3. Cellular Network – Unknown Generation"
        }
        if(connectiontype == 4)
        {
          connectiontype = "4. Cellular Network – 2G"
        }
        if(connectiontype == 5)
        {
          connectiontype = "5. Cellular Network – 3G"
        }
        if(connectiontype == 6)
        {
          connectiontype = "6. Cellular Network – 4G"
        }
      }
      catch
      {
        var connectiontype = "Not found";
      }
      try
      {
        var Ip = validjson[element].partnerBidRequest.device.ip;
      }
      catch
      {
        var Ip = "Not found";
      }
      try
      {
        var Make = validjson[element].partnerBidRequest.device.make;
      }
      catch
      {
        var Make = "Not found";
      }
      try
      {
        if(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(Ifa) == true)
        {
          if(Ifa == "00000000-0000-0000-0000-000000000000")
          {
            if(Make == "Apple")
            {
              var IfaValid = "1 (But user opted-out)";
            }
            else
            {
              var IfaValid = "1 (But Make field is wrong)";
            }
            
          }
          else
          {
            var IfaValid = 1
          }
          
        }
        else
        {
          if(Ifa.length == 32)
          {
            let ifka = Ifa.split("")
            ifka.splice(8, 0, "-");
            ifka.splice(13,0, "-");
            ifka.splice(18,0, "-");
            ifka.splice(23,0, "-");
            let iffka = "";
            for(let element in ifka)
            {
              iffka += ifka[element]
            }
            
            if(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(iffka) == true)
            {
              var IfaValid = "1 (Converted)";
            }
            else
            {
              var IfaValid = 0;
            }
          }
          else
          {
            var IfaValid = 0;
          }
          
        }
      }
      catch
      {
        var IfaValid = "Not found";
      }
      try
      {
        var wopv = validjson[element].partnerBidRequest.imp[0].ext.wopv
      }
      catch
      {
        var wopv = "Not found";
      }
      try
      {
        var Model = validjson[element].partnerBidRequest.device.model;
      }
      catch
      {
        var Model = "Not found";
      }
      try
      {
        var Os = validjson[element].partnerBidRequest.device.os;
      }
      catch
      {
        var Os = "Not found";
      }
      try
      {
        var UserAgent = validjson[element].partnerBidRequest.device.ua;
        var isBot = isbot(UserAgent).toString();
        const deviceDetector = new DeviceDetector();
        const device = deviceDetector.parse(UserAgent);
        var DeviceData = ""
        
      }
      catch
      {
        var UserAgent = "Not found";
        var DeviceData = "";
      }
      try
      {

        //// UA details to be implemented in future
        /*
        function getdata(data)
        {
          UAdata = data;
          UAdata = "Brand: " + UAdata.brand + " Engine: "
          console.log(UAdata.type);
        }
        var UAdata;
        fetch('http://api.userstack.com/detect?access_key=d5e08867c44a7dc62a7e0dccac987656&ua=' + UserAgent + '&format=1')
        .then(response => response.json())
       .then(data => getdata(data));
       */
      }
      catch
      {
////Catch for UADetails
      }
      try
      {
        var SchainComplete = validjson[element].partnerBidRequest.source.ext.schain.complete
      }
      catch
      {
        try
        {
          var SchainComplete = validjson[element].partnerBidRequest.ext.schain.complete
        }
        catch
        {
          var SchainComplete = "Not found";
        }
        
      }
      try
      {
        var Node0Asi = validjson[element].partnerBidRequest.source.ext.schain.nodes[0].asi;
        var Node0Hp = validjson[element].partnerBidRequest.source.ext.schain.nodes[0].hp;
        var Node0Sid = validjson[element].partnerBidRequest.source.ext.schain.nodes[0].sid;
      }
      catch
      {
        try
        {
          var Node0Asi = validjson[element].partnerBidRequest.ext.schain.nodes[0].asi;
          var Node0Hp = validjson[element].partnerBidRequest.ext.schain.nodes[0].hp;
          var Node0Sid = validjson[element].partnerBidRequest.ext.schain.nodes[0].sid;
        }
        catch
        {
          var Node0Asi = "";
          var Node0Hp = "";
          var Node0Sid = "";
        }

      }
      try
      {
        var Node1Asi = validjson[element].partnerBidRequest.source.ext.schain.nodes[1].asi;
        var Node1Hp = validjson[element].partnerBidRequest.source.ext.schain.nodes[1].hp;
        var Node1Sid = validjson[element].partnerBidRequest.source.ext.schain.nodes[1].sid;
      }
      catch
      {
        try
        {
          var Node1Asi = validjson[element].partnerBidRequest.ext.schain.nodes[1].asi;
        var Node1Hp = validjson[element].partnerBidRequest.ext.schain.nodes[1].hp;
        var Node1Sid = validjson[element].partnerBidRequest.ext.schain.nodes[1].sid;
        }
        catch
        {
          var Node1Asi = "";
          var Node1Hp = "";
          var Node1Sid = "";
        }

      }
      try
      {
        var Node2Asi = validjson[element].partnerBidRequest.source.ext.schain.nodes[2].asi;
        var Node2Hp = validjson[element].partnerBidRequest.source.ext.schain.nodes[2].hp;
        var Node2Sid = validjson[element].partnerBidRequest.source.ext.schain.nodes[2].sid;
      }
      catch
      {
        try
        {
        var Node2Asi = validjson[element].partnerBidRequest.ext.schain.nodes[2].asi;
        var Node2Hp = validjson[element].partnerBidRequest.ext.schain.nodes[2].hp;
        var Node2Sid = validjson[element].partnerBidRequest.ext.schain.nodes[2].sid;
        }
        catch
        {
          var Node2Asi = "";
          var Node2Hp = "";
          var Node2Sid = "";
        }

      }
      try
      {
        var SDK = validjson[element].partnerBidRequest.source.ext.omidpn
        try
        {
          var SdkVer = validjson[element].partnerBidRequest.source.ext.omidpv
        }
        catch
        {
          var SdkVer ="";
        }
      }
      catch
      {
        var SDK = ""
      }
      try
      {
        var Node3Asi = validjson[element].partnerBidRequest.source.ext.schain.nodes[3].asi;
        var Node3Hp = validjson[element].partnerBidRequest.source.ext.schain.nodes[3].hp;
        var Node3Sid = validjson[element].partnerBidRequest.source.ext.schain.nodes[3].sid;
      }
      catch
      {
        try
        {
          var Node3Asi = validjson[element].partnerBidRequest.ext.schain.nodes[3].asi;
        var Node3Hp = validjson[element].partnerBidRequest.ext.schain.nodes[3].hp;
        var Node3Sid = validjson[element].partnerBidRequest.ext.schain.nodes[3].sid;
        }
        catch
        {
          var Node3Asi = "";
          var Node3Hp = "";
          var Node3Sid = "";
        }

      }
      try
      {
        var country = validjson[element].partnerBidRequest.device.geo.country;
        var geotype = validjson[element].partnerBidRequest.device.geo.type;
        if(geotype == 1)
        {
          geotype = "1 - GPS/Location Services";
        }
        if(geotype == 2)
        {
          geotype = "2 - IP Address";
        }
        if(geotype == 3)
        {
          geotype = "3 - User provided (e.g. registration data)";
        }
        var city = validjson[element].partnerBidRequest.device.geo.city;
        var lat = validjson[element].partnerBidRequest.device.geo.lat;
        var lon = validjson[element].partnerBidRequest.device.geo.lon;
      }
      catch
      {
        var country = "";
        var city = "";
        var lat = "";
        var lon = "";
        var geotype = "";
      }
      try
      {
        //mediatype check
        try
        {
          var mediatype = validjson[element].partnerBidRequest.imp[0].video;
          var placement = validjson[element].partnerBidRequest.imp[0].video.placement;
          if(placement == 1)
          {
            placement = "1. In-Stream"
          }
          if(placement == 2)
          {
            placement = "2. In-Banner"
          }
          if(placement == 3)
          {
            placement = "3. In-Article"
          }
          if(placement == 4)
          {
            placement = "4. In-Feed"
          }
          if(placement == 5)
          {
            placement = "5. Interstitial/Slider/Floating"
          }
          var w = "";
          var h = "";
          try
          {
            w = validjson[element].partnerBidRequest.imp[0].video.w
            h = validjson[element].partnerBidRequest.imp[0].video.h
            
          }
          catch
          {
            w = "Na";
            h = "Na";
          }
          if(w != "Na" || h != "Na")
          {

              mediatype = "Video | Size: " + w + "x" + h + " | Placement: " + placement;
            
            
          }
          else
          {
            mediatype = "Video | " + "Placement: " + placement;
          }
          
          
        }
        catch
        {
          try
          {
            var mediatype = validjson[element].partnerBidRequest.imp[0].banner;
            try
            {
              mediatype = "Banner | Size: " + validjson[element].partnerBidRequest.imp[0].banner.w + "x" + validjson[element].partnerBidRequest.imp[0].banner.h;
            }
            catch
            {
              mediatype = "Banner";
            }
           
            
          }
          catch
          {
            try
            {
              var mediatype = validjson[element].partnerBidRequest.imp[0].audio;
              mediatype = "Audio";
              
            }
            catch
            {
              try
              {
                
                var mediatype = validjson[element].partnerBidRequest.imp[0].native;
                mediatype = "Native";
                
              }
              catch
              {
                var mediatype = "";
                
              }
            }
          }
        }
      }
      catch
      {

      }
      try
      {
        var deals ='';
        for(let elements in validjson[element].partnerEligibleDeals)
        {
          deals += validjson[element].partnerEligibleDeals[elements] + "|";
        }
        var PartnerEligibleDeals = deals;
      }
      catch
      {
        var PartnerEligibleDeals = "Not Found";
      }

      try
      {
        var Osv = validjson[element].partnerBidRequest.device.osv;
      }
      catch
      {
        var Osv = '';
      }
      try
      {
        let bcats = '';
        for(let elements in validjson[element].partnerBidRequest.bcat)
        {
          bcats += validjson[element].partnerBidRequest.bcat[elements] + "|"
        }
        var Bcat = bcats;
      }
      catch
      {
        var Bcat = '';
      }
      try
      {
        var Adomain = validjson[element].partnerBidResponse.seatbid[0].bid[0].adomain[0].toString();
      }
      catch
      {
        var Adomain = "Not found"
      }
      try
      {
        var CreativeId = validjson[element].partnerBidResponse.seatbid[0].bid[0].crid.toString();
      }
      catch
      {
        var CreativeId = "Not found"
      }
      try
      {
        var SeatId = validjson[element].partnerBidResponse.seatbid[0].seat.toString();
      }
      catch
      {
        var SeatId = "Not found"
      }
      try
      {
        var Size = validjson[element].partnerBidResponse.seatbid[0].bid[0].w + "X" + validjson[element].partnerBidResponse.seatbid[0].bid[0].h
      }
      catch
      {
        var Size = "Not found";
      }
      try
      {
        var Price = parseFloat(validjson[element].partnerBidResponse.seatbid[0].bid[0].price).toFixed(2) + " " + validjson[element].partnerBidResponse.cur;
      }
      catch
      {
        var Price = "";
      }

      const HighlightedJSON = ({ json }) => {
        const highlightedJSON = jsonObj =>
          Object.keys(jsonObj).map(key => {
            const value = jsonObj[key];
            let valueType = typeof value;
            const isSimpleValue =
              ["string", "number", "boolean"].includes(valueType) || !value;
            if (isSimpleValue && valueType === "object") {
              valueType = "null";
            }
            return (
              <div key={key} className="line">
                <span className="key">{key}:</span>
                {isSimpleValue ? (
                  <span className={valueType}>{`${value}`}</span>
                ) : (
                  highlightedJSON(value)
                )}
              </div>
            );
          });
        return <div className="json">{highlightedJSON(json)}</div>;
      };
        ///inconsistences container
      var Inconsistencies = "";
      function InconsistenciesCheck()
      {
        if(UserAgent.toLowerCase().includes("iphone os") && Make.toLowerCase() != "apple" && Make != "")
        {
          Inconsistencies += " User Agent and Make mismatch detected(Iphone OS and not a Apple Make)";
          //console.log(Inconsistencies)
        }
        //if(UserAgent.includes("iPhone OS"))
      }
      InconsistenciesCheck();
        ///////////////////////////
      
      
        BidRequests[element] = 
      {
        Placement: mediatype,
        BundleId: BundleId,
        SiteName:SiteName,
        SiteDomain: SiteDomain,
        SitePage: SitePage,
        SitePublisherName: SitePublisherName,
        SitePublisherId: SitePublisherId,
        AppDomain: AppDomain,
        Id: Id,
        AppName: AppName,
        AppPublisherId: AppPublisherId,
        AppPublisherName: AppPublisherName,
        StoreUrl: StoreUrl,
        DeviceType: DeviceType,
        Location: country + " ," + city + " lat: " + lat + " lon: " + lon + " Location Type: " + geotype,
        ConnectionType: connectiontype,
        IsApp: IsApp,
        Ifa: Ifa,
        IfaValid: IfaValid,
        Wopv: wopv,
        Ip: Ip,
        Make: Make,
        Model: Model,
        Os: Os,
        Osv: Osv,
        SDK: SDK,
        SdkVersion: SdkVer,
        UserAgent: UserAgent,
        BotUserAgent: isBot,
        BotOrDeviceInformation: DeviceData,
        SchainComplete: SchainComplete,
        SchainNode0: "Asi: " + Node0Asi + " Hp: " + Node0Hp + " Sid: " + Node0Sid,
        SchainNode1: "Asi: " + Node1Asi + " Hp: " + Node1Hp + " Sid: " + Node1Sid,
        SchainNode2: "Asi: " + Node2Asi + " Hp: " + Node2Hp + " Sid: " + Node2Sid,
        SchainNode3: "Asi: " + Node3Asi + " Hp: " + Node3Hp + " Sid: " + Node3Sid,
        PartnerEligibleDeals: PartnerEligibleDeals,
        Bcat: Bcat,
        Adomain: Adomain,
        CreativeId: CreativeId,
        SeatId: SeatId,
        Size: Size,
        Price: Price
      }
      
     
      

        setdatatableData3(datatableData3 => [...datatableData3,[BidRequests[element].Placement, BidRequests[element].BundleId, BidRequests[element].SiteName, BidRequests[element].SiteDomain, BidRequests[element].SitePage, BidRequests[element].AppDomain, BidRequests[element].Id, BidRequests[element].AppName, BidRequests[element].SitePublisherId, BidRequests[element].SitePublisherName, BidRequests[element].AppPublisherId, BidRequests[element].AppPublisherName, BidRequests[element].StoreUrl, BidRequests[element].DeviceType, " " + BidRequests[element].Location + " ", BidRequests[element].ConnectionType, BidRequests[element].IsApp, BidRequests[element].Ifa, BidRequests[element].IfaValid, BidRequests[element].Wopv, BidRequests[element].Ip, BidRequests[element].Make, BidRequests[element].Model, BidRequests[element].Os, BidRequests[element].Osv, BidRequests[element].SDK, BidRequests[element].SdkVersion, BidRequests[element].UserAgent, BidRequests[element].BotUserAgent, BidRequests[element].BotOrDeviceInformation, BidRequests[element].SchainComplete, BidRequests[element].SchainNode0,BidRequests[element].SchainNode1,BidRequests[element].SchainNode2,BidRequests[element].SchainNode3, BidRequests[element].PartnerEligibleDeals, BidRequests[element].Bcat, "------" , BidRequests[element].Adomain, BidRequests[element].CreativeId, BidRequests[element].SeatId, BidRequests[element].Size, BidRequests[element].Price]])
      
     
    }
    
  }


    
    function ExportSellers()
    {
      wynik.current.innerHTML = "";
      var jsonstrict = json.current.value;
      try
      {
      var json2 = JSON.parse(jsonstrict)
      text = objectsToCSV(json2.sellers);
      
      //wynik.current.innerText = text;
       download_txt();
      }
      catch
      {
        let error = "It is not valid Json file!";
        wynik.current.innerHTML = "<b style='color:red''>" + error + "</b>";
      }
    
    }
    function objectsToCSV(arr) {
      const array = [Object.keys(arr[0])].concat(arr)
      return array.map(row => {
          return Object.values(row).map(value => {
              return typeof value === 'string' ? JSON.stringify(value) : value
          }).toString()
      }).join('\n')
  }
  function download_txt() {
    var textToSave = text;
  var hiddenElement = document.createElement('a');

  hiddenElement.href = 'data:attachment/text,' + encodeURI(textToSave);
  hiddenElement.target = '_blank';
  hiddenElement.download = 'Json.csv';
  hiddenElement.click();
}
function toNormalText(data)
{
  console.log(data);
  let replaced = data.replace("data:text/plain;base64,", "");
  fileJson = Buffer.from(replaced, 'base64').toString();
  Check();
}
function clear()
{
  fileJson = "";
  setdatatableData3([{}]);
}
function CheckboxCheck()
{
  if(slower == false)
  {
    setslower(true)
  }
  else
  {
    setslower(false)
  }
}
var UAs = [];
function CheckBots()
{
  setdatatableData4(datatableData4 => [])
  var jsonnotformatted2 = json2.current.value; 
        var jsonFirstFormat2 = jsonnotformatted2.split("\n");
        console.log(jsonFirstFormat2);
        for (let elements in jsonFirstFormat2)
        {
          var UserAgent2 = jsonFirstFormat2[elements];
        var isBot2 = isbot(UserAgent2).toString();
        const deviceDetector2 = new DeviceDetector();
        const device2 = deviceDetector2.parse(UserAgent2);
        var IfsBot;
        console.log(device2);
        if(device2.bot === null)
        {
          IfsBot = 'false';
        }
        else
        {
          IfsBot = 'true';
        }
        UAs[elements] = 
        {
          "UserAgent": UserAgent2,
          "isBot":isBot2,
          "isBot2":IfsBot,
          "Details":device2
        }
        setdatatableData4(datatableData4 => [...datatableData4,[UAs[elements].UserAgent, UAs[elements].isBot, UAs[elements].isBot2, JSON.stringify(UAs[elements].Details)]])
      
        }
        for(let element in UAs)
        {
          
        }
        
}
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    fileref.current.innerText = "File uploaded!"
  });

}

return (
    <div >
      <br></br>
       <TextField variant="outlined" multiline inputRef={json} rows={10} placeholder="Insert BidRequest/BidResponse ex. from Castle black. Can be in bulk" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"250px",resize:"vertical"}} className="inline-txtarea"></TextField>
     
<br></br>
    <Checkbox
    onClick={CheckboxCheck}
      id="Slower"
    >
      
    </Checkbox>
    <label htmlFor="Slower">Use slower method (Avoid browser crash for large files)</label>
    <br></br>
    <Button variant="contained" color="primary" onClick={Check}>Analyse Bid Requests</Button>
    <label htmlFor="upload-file">
  <input
    style={{ display: 'none' }}
    id="upload-file"
    name="upload-file"
    type="file"
    onChange={(event)=>{getBase64(event.target.files[0]).then(
      data => toNormalText(data)
    );}}
    
  />

  <Button ref={fileref} color="secondary" variant="contained" component="span" onClick={clear}>
    Upload File
  </Button>
</label><br></br>
    <p> </p>
    <div ref={wynik}>

    </div>

    <MUIDataTable
            
            title="Bid Request Analysis"
            ref={tableRef}
            columns={["Placement","BundleId","SiteName","SiteDomain", "SitePage", "AppDomain","AppId", "AppName", "SitePublisherId", "SitePublisherName", "AppPublisherId", "AppPublisherName", "StoreUrl", "DeviceType", "Location", "ConnectionType", "IsApp", "Ifa", "IfaValid", "Wopv / PrescanId", "Ip", "Make", "Model", "Os", "Osv" , "SDK", "SdkVersion", "UserAgent", "BotUserAgent", "BotOrDeviceData", "SchainComplete", "SchainNode0","SchainNode1", "SchainNode2", "SchainNode3", "PartnerEgibleDeals", "Bcat", "Bid Response", "Adomain", "CreativeId", "SeatId", "Size", "Price"]}
            data={datatableData3}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'BidRequests_' + new Date().toISOString().slice(0, 10) + '.csv',
                filterOptions: {
                  useDisplayedColumnsOnly:true
                }
              },
              sort: true,
              sortDirection: 'desc',
              textLabels: {
                body: {
                  noMatch: "Please analyse Bid Requests first",
                }},
            }}
            
          />
<div>
<br></br><br></br><br></br><br></br>
<h2 className='MuiTypography-root makeStyles-typo-74 MuiTypography-h2'>Bot Checker</h2>
<TextField variant="outlined" multiline inputRef={json2} rows={10} placeholder="Insert User-Agent strings to determine if these are bot UAs in separate lines" id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"250px",resize:"vertical"}} className="inline-txtarea"></TextField>
<Button variant="contained" color="primary" onClick={CheckBots}>Check Bots</Button>
<MUIDataTable
            
            title="Bot checker"
            ref={tableRef}
            columns={["User-Agent", "isBot", "isBot2", "Details"]}
            data={datatableData4}
            options={{
              filterType: "checkbox",
              downloadOptions: {
                filename: 'BotCheck_' + new Date().toISOString().slice(0, 10) + '.csv',
                filterOptions: {
                  useDisplayedColumnsOnly:true
                }
              },
              sort: true,
              sortDirection: 'desc',
              textLabels: {
                body: {
                  noMatch: "",
                }},
            }}
            
          />
</div>
    </div>
    
);
}