import { Button } from '@material-ui/core';
import React, { useRef, useState, useEffect} from 'react';
import { useTheme } from "@material-ui/styles";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from "./styles";
import PageTitle from '../PageTitle/PageTitle';
import parseVAST from 'iab-vast-parser'
import { Card, CardHeader,CardContent,CardActions,CardMedia } from '@material-ui/core';
import { BatteryFullRounded } from '@material-ui/icons';
import XmlDom from 'xmldom'
export default function AdRenderers() {

 const classes = useStyles();
const ad = useRef();
const adm = useRef();
const adm2 = useRef();
const adm3 = useRef();
const titlehref = useRef();
const titlehref2 = useRef();
const linkhref = useRef();
const linkhref2 = useRef();
const image = useRef();
const content = useRef();
var date = new Date();
const [timeMinutes, settimeMinutes] = useState()
const [timeHours, settimeHours] = useState()
const [vastadm,setvastadm] = useState();
var Ad;
var vast;

React.useEffect(() => {

  setTime()//first execution
 
  setInterval(()=>setTime(), 1000);
},[]);



function setTime()
{
  date = new Date();
  var h = date.getHours(); 
  var m = date.getMinutes(); 
  h = (h < 10) ? "0" + h : h;
  m = (m < 10) ? "0" + m : m;
  var time = h + ":" + m
  settimeMinutes(time)

  setTimeout(setTime, 1000);
}

 function Render()
  {
    var render = adm.current.value;
    var iframka = ad.current;
    iframka.srcdoc = render;
    


  }
  function Render3()
  {
    setvastadm("");
    let conten = adm3.current.value;
    
    const xmlString = conten;
    vast =parseVAST(xmlString);
    
    

      try
      {
        
        vast = vast._ads._contents[0]._creatives._contents[0]._linear._mediaFiles[0]._uri;
        console.log(vast);
      }
      catch
      {
        console.log("Second check done")
    fetch(vast._ads._contents[0]._vastAdTagURI)
    .then( response => response.text() )
    .then( data => { 
      vast =parseVAST(data);

      try
      {
        vast = vast._ads._contents[0]._creatives._contents[0]._linear._mediaFiles[0]._uri;
        console.log(vast);

      }
      catch
      {

      }
      setvastadm(vast);
    })
      }
    
    
  
    //console.log(vast.contents[0]._creatives._contents[0]._linear._mediaFiles[0]._uri);
    
    /*
    
    console.log(vast)
    if(vast.ad[0].inLine != undefined)
    {
        setvastadm(vast.ad[0].inLine.creatives[0].linear.mediaFiles.mediaFile[0].uri)
    }
    
    else
    {
        
fetch(vast.ad[0].wrapper.VASTAdTagURI)
.then( response => response.text() )
.then( data => { 

vast = VASTParser.parseFromXML(data)

setvastadm(vast.ad[0].inLine.creatives[0].linear.mediaFiles.mediaFile[0].uri)
})
    }
*/
  }
    function Render2()
    {
        var json = adm2.current.value;
        console.log(json)
        let jsoncompiled = JSON.parse(json);
        
        var link = "";
        var title = "";
        var imageurl = "";
        var imagewidth = "";
        var imageheight = "";
        var asset = "";
        try
        {
          asset = findSomething(jsoncompiled,'assetsurl')
          try
          {
            fetch(asset)
        .then(response => response.json())
        .then(data => dataparse(data));
          }
          catch
          {

          }
          
        }
        catch
        {
          asset = "";
        }


        
          try{
            link = findSomething(jsoncompiled,'link').url;
          }
          catch
          {
            link = "";
          }
        

        try
        {
          title = findSomething(jsoncompiled,'title').text;
        }
      catch
      {
        title = "Couldnt find title!"
      }
      

        try{
          imageurl = findSomething(jsoncompiled,'url');
        }
        catch
        {
          imageurl = "";
        }
      

        try
        {
          imagewidth = findSomething(jsoncompiled,'w');
        }
        catch
        {
          imagewidth = 0;
        }
      

          try
          {
            imageheight = findSomething(jsoncompiled,'h');
          }
          catch
          {
            imageheight = 0;
          }
        
        
        Ad = {
          "Image":imageurl,
          "ImageWidth":imagewidth,
          "ImageHeight":imageheight,
          "Title":title,
          "LinkUrl":link
    
        }
       
        image.current.src = Ad.Image;
        image.current.style.width += Ad.ImageWidth;
        image.current.style.height += Ad.ImageHeight;
        
        linkhref.current.href = Ad.LinkUrl;
        
        titlehref.current.innerText = Ad.Title;

    }
    function dataparse(data)
    {
      console.log(data)
     let title = data.assets[0].title.text;
     let imageurl = data.assets[1].img.url;
     let imagewidth = data.assets[1].img.w;
     let imageheight = data.assets[1].img.h;
     let subtitle = data.assets[4].data.value
     let link = data.link.url;

      Ad = {
        "Image":imageurl,
        "ImageWidth":imagewidth,
        "ImageHeight":imageheight,
        "Title":title,
        "LinkUrl":link,
        "Subtitle":subtitle
      }
     
      image.current.src = Ad.Image;
      image.current.style.width += Ad.ImageWidth;
      image.current.style.height += Ad.ImageHeight;
      
      linkhref.current.href = Ad.LinkUrl;
      
      titlehref.current.innerText = Ad.Title + "\n" + Ad.Subtitle;
      
    }
    function findSomething(object, name) {
        if (name in object) return object[name];
        for (let key in object) {
          if ((typeof (object[key])) == 'object') {
            var t = findSomething(object[key], name);
            if (t) return t;
          }
          
        }
        

       
        return null;
      }
return (
    <div >
        <TextField variant="outlined" placeholder="Insert simple HTML ad markup" inputRef={adm} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField><br></br>
        <br></br><br></br><Button variant="outlined" color="primary" onClick={Render}>Render</Button>
        <br></br><br></br>
        <div>
        
         <div className={classes.smartphone}>
         <div className={classes.nav}>{timeMinutes}
         <BatteryFullRounded className={classes.battery}></BatteryFullRounded>
         <br></br>
         </div>
          

            <iframe ref={ad} sandbox="allow-forms 
 allow-pointer-lock
 allow-popups
 allow-same-origin
 allow-scripts
 allow-top-navigation" style={{width:"100%",border:"none",height:"100%",background:"black", marginTop:"30px"}}   ></iframe>
 
 </div>

          </div>
          <PageTitle title="Native Ad Renderer"></PageTitle>
          <TextField variant="outlined" placeholder="Insert native ad markup" inputRef={adm2} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField><br></br>
          <br></br><br></br>
          <Button variant="outlined" color="primary" onClick={Render2}>Render</Button>
          <br></br>
          <br></br>
          <div className={classes.content2}>
          <a ref={linkhref} target="_blank"><img ref={image}/>
          <h3 ref={titlehref}></h3></a>

          </div>
          <PageTitle title="VAST Renderer"></PageTitle>
          <TextField variant="outlined" placeholder="Insert VAST markup" inputRef={adm3} rows={10} fullwidth multiline id="tocheck" style={{borderRadius:"10px",textAlign:"center",width:"100%",height:"200px",resize:"vertical"}} className="inline-txtarea"></TextField><br></br>
          <br></br><br></br>
          <Button variant="outlined" color="primary" onClick={Render3}>Render</Button>
          <br></br>
          <br></br>
          <div className={classes.content2}>
          <Card>

          <CardContent>
          <CardMedia
          style={{height:500,width:500}}
          component="video"
          image={vastadm}
          controls
          autoPlay
          ></CardMedia>
        
        </CardContent>
        <p ref={titlehref2}></p>
          </Card>
          
          </div>
    </div>
);
}