import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Apps as AppsIcon,
  YoutubeSearchedFor as WebsiteAnalyser,
  ImageSearch as AdRenderers,
  TextFields as TextFields,
  LiveTvOutlined as LiveTV,
  Add as Add,
  Check as Check
} from "@material-ui/icons";
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import TuneIcon from '@material-ui/icons/Tune';
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { Typography } from "../Wrappers/Wrappers";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  { id: 0, label: "App Analyser", link: "/app/appanalyser", icon: <AppsIcon /> },
 
  {
    id: 1,
    label: "Website Analyser",
    link: "/app/websiteanalyser",
    icon: <WebsiteAnalyser />,
  },
  
  { id: 2, label: "Ad Renderers", link: "/app/adrenderers", icon: <AdRenderers /> },
  {
    id: 3,
    label: "Keyword Checker",
    link: "/app/keywordchecker",
    icon: <TextFields />,

   
  },
 
  {
    id: 4,
    label: "Miscellaneous",
    
    icon: <TuneIcon />,
    children: [
      { label: "Url opener", link: "/app/urlopener" },
      { label: "Text merger", link: "/app/textmerger" },
      { label: "Sellers.json", link: "/app/sellersjsonexporter" },
      { label: 'Bid requests', link: "/app/bidrequestsanalyser" },
      { label: "Others", link: "/app/miscellaneous" },
    ],
    link: "/app/miscellaneous"
  },
  { id: 5, label: "PXL CTV Analyser", link:'/app/ctvanalyser', icon:<LiveTV></LiveTV>},
  
   
  
  { id: 6, type: "divider" },
  
  { id: 7, label: "Sellers.json Validator", link: "/app/sellerjsonvalidator", icon: <FAQIcon /> },
  { id: 8, label: "Approved Domains", link: "/app/approved", icon: <Add color="secondary"></Add>},
  { id: 9, label: "Approved Bundles", link: "/app/approvedbundles", icon: <Add color="primary"></Add>},
  { id: 10, label: "Experiments", link: "/app/experiments", icon: <WarningOutlined /> },
  { id: 11, label: "SQTA", link: "/app/sqta", icon: <Check /> }
  
  /*
  
  { id: 10, type: "divider" },
  { id: 11, type: "title", label: "PROJECTS" },
  {
    id: 12,
    label: "My recent",
    link: "",
    icon: <Dot size="small" color="warning" />,
  },
  {
    id: 13,
    label: "Starred",
    link: "",
    icon: <Dot size="small" color="primary" />,
  },
  {
    id: 14,
    label: "Background",
    link: "",
    icon: <Dot size="small" color="secondary" />,
  },
  */
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
