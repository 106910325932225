import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import {Box, IconButton, Link} from '@material-ui/core'
import Icon from '@mdi/react'

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from '@mdi/js'

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Experiments from "../../pages/experiments/Experiments";
import Experiments2 from "../../pages/experiments2/Experiments2";
import Textmergerpage from "../../pages/Textmergerpage/Textmergerpage";
import urlopenerpage from "../../pages/urlopenerpage/urlopenerpage";
import sellersjsonpage from "../../pages/sellersjsonpage/sellersjsonpage";
import bidrequestpage from "../../pages/bidrequestpage/bidrequestpage";
import SellersJsonValidator from "../SellersJsonValidator/SellersJsonValidator";
import ApprovedBundlesTable from "../../pages/approvedbundles/approvedbundles";
import sqta from "../../pages/sqta/sqta";
// context
import { useLayoutState } from "../../context/LayoutContext";
import CTVAnalyser from "../CTVAnalyser/CTVAnalyser";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/app/appanalyser" component={Dashboard} />
              <Route path="/app/websiteanalyser" component={Typography} />
              <Route path="/app/adrenderers" component={Tables} />
              <Route path="/app/miscellaneous" component={Notifications} />
              <Route path="/app/keywordchecker" component={Icons} />
              <Route path="/app/ctvanalyser" component={CTVAnalyser} />
              <Route path="/app/textmerger" component={Textmergerpage} />
              <Route path="/app/urlopener" component={urlopenerpage} />
              <Route path="/app/sellersjsonexporter" component={sellersjsonpage} />
              <Route path="/app/bidrequestsanalyser" component={bidrequestpage} />
              <Route path="/app/experiments" component={Experiments} />
              <Route path="/app/sellerjsonvalidator" component={SellersJsonValidator}></Route>
              <Route path="/app/approved" component={Maps}></Route>
              <Route path="/app/approvedbundles" component={ApprovedBundlesTable}></Route>
              <Route path="/app/sqta" component={sqta}></Route>
            </Switch>
            <Box
              mt={5}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent="space-between"
            >
              <div>
<h6>Copyright Damian Chymkowski 2023-2024</h6>
              </div>
            </Box>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
