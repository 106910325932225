import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  avatar: {
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
  },
  text: {
    color: "white",
  },
  smartphone: {
    position: "relative",
  width: "300px",
  height: "600px",
  margin: "auto",
  border: "10px black solid",
  borderTopWidth: "49px",
  borderBottomWidth: "60px",
  borderRadius: "36px"
  },
  nav: {
    position:"absolute",
    width:"102%",
    height:"20px",
    color:"white",
    background:"black",
    paddingBottom:"30px"
  },
  battery: 
  {
    marginLeft: "75%",
   
  }

}));
